// NOTE: Step0 = intro page (no form elements)

import { MAIL_TO_PROV_REQUESTS_SUBJECT_LINK, W9_LINK } from 'constants/links';

function Step0() {
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Join our network
      </bc-typography>
      <bc-typography>
        Thank you for your interest in becoming a Blue Cross and Blue Shield of North Carolina (Blue Cross NC) network
        provider. As a Blue Cross NC provider partner, you will be part of a growing network of physicians, health care
        professionals and facilities who share our commitment to improving the health and well-being of our members and
        communities. The first step is to let us know that you would like to join our network!
      </bc-typography>
      <bc-typography>
        To join, please have the following information available for each practitioner to be enrolled.
      </bc-typography>
      <bc-list type="ul">
        <li>Practitioner License</li>
        <li>
          <a className="link" href={W9_LINK} target="_blank" rel="noreferrer">
            W-9
          </a>{' '}
          Form (only for solo practitioners not part of a group)
        </li>
        <li>
          Email the completed W-9 form to{' '}
          <a className="link" href={MAIL_TO_PROV_REQUESTS_SUBJECT_LINK} target="_blank" rel="noreferrer">
            ProvRequests@bcbsnc.com
          </a>
        </li>
        <li>Council for Affordable Quality Healthcare (CAQH) ID number</li>
      </bc-list>
      <div className="blue-card">
        <bc-typography weight="bold">Important Tips:</bc-typography>
        <bc-list type="ol">
          <li>
            If you are part of a provider group, complete enrollment for the provider group first. If you have not
            already enrolled your provider group, please enroll here.
          </li>
          <li>
            Complete the entire enrollment form and all required data fields noted with an asterisk (*). Missing or
            inaccurate information delays practitioner’s participation in Blue Cross NC’s network.
          </li>
          <li>Expedite practitioner enrollment by providing accurate and current information.</li>
        </bc-list>
      </div>
    </>
  );
}

export default Step0;
