import { Formik } from 'formik';
import { ReactElement, useEffect } from 'react';
import FormWrapper from 'components/FormWrapper';
import 'styles/button.scss';
import { handleFormSubmission } from 'utilities/formHelpers';
import { referenceCodeSchema, setLoader } from 'utilities/miscHelpers';
import { sendEmail } from 'utilities/apis';
import { useStore } from 'store';
import { Action } from 'constants/store';
import FailAlert from 'components/validation/FailAlert';
import PageTitle from 'components/PageTitle';
import { FormTitles } from 'types/FormTypes';
import { EmailRecipients, EmailSubjects } from 'types/EmailTypes';
import { BCBSNC_NO_REPLY_EMAIL } from 'constants/EmailConstants';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { nanoid } from 'nanoid';
import SuccessPage from 'components/SuccessPage';
import { emailTemplateRender, formSchemasToEmailTemplates } from 'utilities/emailTemplateHelpers';
import { formSchema, initialValues, validationSchema } from './schema';
import FormContent from './formContent';

function NurseAdvocateContactForm(): ReactElement {
  const {
    dispatch,
    state: { formSubmittedSuccessfully, isLoading },
  } = useStore();

  useEffect(() => {
    // update form title
    dispatch({
      type: Action.UPDATE_FORM_TITLE,
      formTitle: FormTitles.NursingAdvocate,
    });

    // set previous url
    dispatch({ type: Action.UPDATE_PREVIOUS_URL, previousUrl: document.referrer });
  }, [dispatch]);

  const handleSubmit = async (values: any): Promise<void> => {
    setLoader(dispatch, true);

    // get random unique string for referenceCode
    const referenceCode = nanoid();

    const recipient = EmailRecipients.NursingAdvocate;
    const sender = BCBSNC_NO_REPLY_EMAIL;
    const subject = EmailSubjects.NursingAdvocate;
    const emailTemplates = formSchemasToEmailTemplates([formSchema, referenceCodeSchema(referenceCode)]);
    const tempValues = values;
    tempValues.referenceCode = referenceCode;
    const emailData = emailTemplateRender(emailTemplates.html, emailTemplates.text, tempValues);
    const response = await sendEmail(emailData, recipient, sender, subject);

    // handles form submission whether its success or fail
    handleFormSubmission(BcncAnalyticsEventFormNames.NurseAdvocateContact, response, dispatch, referenceCode);

    setLoader(dispatch, false);
  };

  const renderForm = () => {
    if (!formSubmittedSuccessfully && !isLoading) {
      return (
        <div className="form-content-width">
          <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
            Nurse Advocate Contact Form
          </bc-typography>
          <FailAlert />
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ values, errors, touched }: any) => <FormContent values={values} errors={errors} touched={touched} />}
          </Formik>
        </div>
      );
    }
  };

  return (
    <FormWrapper>
      <PageTitle title="Nurse Advocate Contact Form" />
      {renderForm()}
      <SuccessPage formName={FormTitles.NursingAdvocate}>
        <bc-typography>
          A Nurse Support Program Representative will contact you on the day and time you selected.
        </bc-typography>
      </SuccessPage>
    </FormWrapper>
  );
}

export default NurseAdvocateContactForm;
