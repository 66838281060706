import { ReactElement } from 'react';

const InputError = ({ errorMessage }: any) => {
  const renderInputError = (): ReactElement => {
    if (errorMessage) {
      return (
        <bc-typography color="error-600" size="text-sm">
          {errorMessage}
        </bc-typography>
      );
    }
    return <div />;
  };
  return renderInputError();
};

export default InputError;
