import Fieldset from 'components/Fieldset';
import BCCheckboxGroup from 'components/custom-fields/BCCheckboxGroup';
import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import {
  degreeOptions,
  languagesSpokenOptions,
  medicareMembersRadios,
  primarySpecialtyOptions,
  providerTypeOptions,
  raceEthnicityOptions,
  secondarySpecialtyOptions,
  servicesLocationOptions,
  telehealthRadios,
} from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/individualPractitionerForm/schema';
import Legend from 'components/Legend';

function Step3({ values, errors, touched }: any) {
  const renderOtherDegreeInput = () => {
    if (values.degree?.includes('other')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherDegree.label}
          name={formSchema.otherDegree.name}
          value={values.otherDegree}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherDegree.name)}
        />
      );
    }
  };

  const renderOtherProviderTypeInput = () => {
    if (values.providerType?.includes('other')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherProviderType.label}
          name={formSchema.otherProviderType.name}
          value={values.otherProviderType}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherProviderType.name)}
        />
      );
    }
  };

  const renderOtherPrimarySpecialtyInput = () => {
    if (values.primarySpecialty?.includes('other')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherPrimarySpecialty.label}
          name={formSchema.otherPrimarySpecialty.name}
          value={values.otherPrimarySpecialty}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherPrimarySpecialty.name)}
        />
      );
    }
  };

  const renderOtherSecondarySpecialtyInput = () => {
    if (values.secondarySpecialty?.includes('other')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherSecondarySpecialty.label}
          name={formSchema.otherSecondarySpecialty.name}
          value={values.otherSecondarySpecialty}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherSecondarySpecialty.name)}
        />
      );
    }
  };

  const renderOtherNonEnglishLanguagesSpokenInput = () => {
    if (values.nonEnglishLanguagesSpoken?.includes('otherLanguage')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherNonEnglishLanguagesSpoken.label}
          name={formSchema.otherNonEnglishLanguagesSpoken.name}
          value={values.otherNonEnglishLanguagesSpoken}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherNonEnglishLanguagesSpoken.name)}
        />
      );
    }
  };

  const renderOtherRaceEthnicity = () => {
    if (values.raceEthnicity?.includes('otherRaceEthnicity')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherRaceEthnicity.label}
          name={formSchema.otherRaceEthnicity.name}
          value={values.otherRaceEthnicity}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherRaceEthnicity.name)}
        />
      );
    }
  };

  const renderOtherServicesLocation = () => {
    if (values.servicesLocation?.includes('otherServicesLocation')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherServicesLocation.label}
          name={formSchema.otherServicesLocation.name}
          value={values.otherServicesLocation}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherServicesLocation.name)}
        />
      );
    }
  };

  const renderMedicareProviderAndGroupInputs = () => {
    if (values.providingServicesToMedicareMembers === 'yes') {
      return (
        <>
          <BCInput
            width="75"
            label={formSchema.medicareProviderNumber.label}
            name={formSchema.medicareProviderNumber.name}
            value={values.medicareProviderNumber}
            errorMessage={generateErrorMessage(errors, touched, formSchema.medicareProviderNumber.name)}
          />
          <BCInput
            width="75"
            label={formSchema.medicareGroupNumber.label}
            name={formSchema.medicareGroupNumber.name}
            value={values.medicareGroupNumber}
            errorMessage={generateErrorMessage(errors, touched, formSchema.medicareGroupNumber.name)}
          />
        </>
      );
    }
  };

  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-800">
        Practitioner Information
      </bc-typography>
      <bc-typography>
        Blue Cross NC will enroll practitioners with the name exactly as it appears on their professional license.
      </bc-typography>
      <Fieldset>
        <Legend required> Enter your current information</Legend>
        <BCInput
          width="75"
          label={formSchema.lastName.label}
          name={formSchema.lastName.name}
          value={values.lastName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.lastName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.firstName.label}
          name={formSchema.firstName.name}
          value={values.firstName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.firstName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.middleName.label}
          name={formSchema.middleName.name}
          value={values.middleName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.middleName.name)}
        />

        <Fieldset noMargin>
          <Legend required>Degree</Legend>
          <BCSelect
            width="75"
            values={values}
            options={degreeOptions}
            placeholder={formSchema.degree.label}
            name={formSchema.degree.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.degree.name)}
          />
        </Fieldset>
        {renderOtherDegreeInput()}

        <Fieldset noMargin>
          <Legend required>Provider Type</Legend>
          <BCSelect
            width="75"
            values={values}
            options={providerTypeOptions}
            placeholder={formSchema.providerType.label}
            name={formSchema.providerType.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.providerType.name)}
          />
        </Fieldset>
        {renderOtherProviderTypeInput()}

        <Fieldset noMargin>
          <Legend required>Primary Specialty</Legend>
          <BCSelect
            width="75"
            values={values}
            options={primarySpecialtyOptions}
            placeholder={formSchema.primarySpecialty.label}
            name={formSchema.primarySpecialty.name}
            errors={errors}
            touched={touched}
            errorMessage={generateErrorMessage(errors, touched, formSchema.primarySpecialty.name)}
          />
        </Fieldset>
        {renderOtherPrimarySpecialtyInput()}

        <Fieldset noMargin>
          <Legend>Secondary Specialty</Legend>
          <BCSelect
            width="75"
            values={values}
            options={secondarySpecialtyOptions}
            placeholder={formSchema.secondarySpecialty.label}
            name={formSchema.secondarySpecialty.name}
            errors={errors}
            touched={touched}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondarySpecialty.name)}
          />
        </Fieldset>
        {renderOtherSecondarySpecialtyInput()}
      </Fieldset>

      <BCCheckboxGroup
        label={formSchema.nonEnglishLanguagesSpoken.label}
        checkboxes={languagesSpokenOptions}
        values={values}
        name={formSchema.nonEnglishLanguagesSpoken.name}
        limit="4"
        errorMessage={generateErrorMessage(errors, touched, formSchema.nonEnglishLanguagesSpoken.name)}
        validation={formSchema.nonEnglishLanguagesSpoken.validation}
      />
      {renderOtherNonEnglishLanguagesSpokenInput()}

      <BCCheckboxGroup
        label={formSchema.raceEthnicity.label}
        checkboxes={raceEthnicityOptions}
        values={values}
        name={formSchema.raceEthnicity.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.raceEthnicity.name)}
        validation={formSchema.raceEthnicity.validation}
      />
      {renderOtherRaceEthnicity()}

      <BCRadioGroup
        label={formSchema.offerTelehealthServices.label}
        values={values}
        radios={telehealthRadios}
        name={formSchema.offerTelehealthServices.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.offerTelehealthServices.name)}
        validation={formSchema.offerTelehealthServices.validation}
      />
      <BCCheckboxGroup
        label={formSchema.servicesLocation.label}
        checkboxes={servicesLocationOptions}
        values={values}
        name={formSchema.servicesLocation.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.servicesLocation.name)}
        validation={formSchema.servicesLocation.validation}
      />
      {renderOtherServicesLocation()}

      <div className="mt-32">
        <BCInput
          width="75"
          label={formSchema.licenseNumber.label}
          name={formSchema.licenseNumber.name}
          value={values.licenseNumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.licenseNumber.name)}
        />
        <BCInput
          width="75"
          maxlength="10"
          label={formSchema.CLIANumber.label}
          name={formSchema.CLIANumber.name}
          value={values.CLIANumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.CLIANumber.name)}
        />
        <BCInput
          width="75"
          label={formSchema.medicaidProvider.label}
          name={formSchema.medicaidProvider.name}
          value={values.medicaidProvider}
          errorMessage={generateErrorMessage(errors, touched, formSchema.medicaidProvider.name)}
        />
      </div>

      <BCRadioGroup
        label={formSchema.providingServicesToMedicareMembers.label}
        values={values}
        radios={medicareMembersRadios}
        name={formSchema.providingServicesToMedicareMembers.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.providingServicesToMedicareMembers.name)}
        validation={formSchema.providingServicesToMedicareMembers.validation}
      />
      {renderMedicareProviderAndGroupInputs()}
    </>
  );
}

export default Step3;
