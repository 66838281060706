import { useStore } from 'store';

// TODO - Ask Design how should submission failure page look?

const FailAlert = () => {
  const {
    state: { formSubmittedFailure },
  } = useStore();

  const renderFailAlert = () => {
    if (formSubmittedFailure) {
      return (
        <bc-global-alert variant="danger" open rounded>
          <bc-icon slot="icon" name="Warning" color="white" size="text-x-lg" />
          There was a problem submitting your form. Please wait and try again.
        </bc-global-alert>
      );
    }
    return <div />;
  };

  return renderFailAlert();
};

export default FailAlert;
