import { useField } from 'formik';
import { forwardRef } from 'react';

const BCInput = forwardRef(
  (
    {
      label,
      type,
      minlength,
      maxlength,
      requiredErrorMessage,
      errorMessage,
      name,
      width,
      textCase,
      value,
      hoverTitle,
      tabIndex,
      min,
      max,
    }: any,
    ref,
  ) => {
    const [field] = useField(name);

    const handleBlur = (e: any) => {
      field.onBlur(e);
    };

    const handleChange = (e: any) => {
      field.onChange(e);
    };

    return (
      <div className="mt-14">
        <bc-input
          type={type || 'text'}
          textCase={textCase}
          minlength={minlength}
          maxlength={maxlength}
          requiredErrorMessage={requiredErrorMessage}
          errorMessage={errorMessage}
          name={name}
          label={label}
          width={width}
          title={hoverTitle}
          tabIndex={tabIndex}
          onInput={handleChange}
          onBlur={handleBlur}
          value={value}
          ref={ref}
          min={min}
          max={max}
        />
      </div>
    );
  },
);

export default BCInput;
