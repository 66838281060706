import { Dispatch } from 'react';
import * as Yup from 'yup';
import { Action } from 'constants/store';

export const generateErrorMessage = (errors: any, touched: any, inputName: string): string =>
  touched[inputName] && errors[inputName] ? errors[inputName] : '';

// extracts initialValue property from our formSchema and creates new initialValues object to plunk into Formik
export const getInitialValues = (schema: any) => {
  // Create the new object to hold the transformed data
  const updatedSchema = {};

  Object.keys(schema).forEach((key) => {
    if (!Object.hasOwn(schema[key], 'type') && schema[key]?.type !== 'description') {
      // @ts-ignore
      updatedSchema[key] = schema[key].initialValue;
    }
  });

  return updatedSchema;
};

// extracts validation from our formSchema and creates new Yup validation object to plunk into Formik
export const getYupValidationSchema = (schema: any) => {
  const updatedSchema = {};

  Object.keys(schema).forEach((key) => {
    // @ts-ignore
    updatedSchema[key] = schema[key].validation;
  });

  return Yup.object({ ...updatedSchema });
};

export const setLoader = (dispatch: Dispatch<any>, isLoading: boolean) => {
  dispatch({
    type: Action.UPDATE_IS_LOADING,
    isLoading,
  });
};

export const referenceCodeSchema = (referenceCode: string): object => {
  return {
    referenceCode: {
      label: 'Reference Code',
      emailLabel: 'Reference Code',
      initialValue: referenceCode,
      name: 'referenceCode',
    },
  };
};
