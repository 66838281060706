import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import { agreeToSubmitDocumentationRadios, practiceAgreeToTakeOwnershipRadios } from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerAcquisitionForm/schema';

function Step4({ values, errors, touched }: any) {
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Required Documentation
      </bc-typography>
      <bc-typography>
        Please have the following documents ready to email to us in the next step after completing your application.
      </bc-typography>
      <bc-typography>
        Note: Failure to provide the required documentation will result in your request being returned as incomplete.
      </bc-typography>
      <bc-list variant="ul">
        <li>Provider W-9 - accepted formats: jpg jpeg png pdf</li>
        <li>Official Documentation of Acquisition - accepted formats: jpg jpeg png pdf</li>
        <li>Provider Roster (if you answered "yes" to the question above) - accepted formats: excel</li>
      </bc-list>

      <BCRadioGroup
        label={formSchema.agreeToSubmitDocumentation.label}
        values={values}
        radios={agreeToSubmitDocumentationRadios}
        name={formSchema.agreeToSubmitDocumentation.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.agreeToSubmitDocumentation.name)}
        validation={formSchema.agreeToSubmitDocumentation.validation}
      />
      <BCRadioGroup
        label={formSchema.practiceAgreeToTakeOwnership.label}
        values={values}
        radios={practiceAgreeToTakeOwnershipRadios}
        name={formSchema.practiceAgreeToTakeOwnership.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.practiceAgreeToTakeOwnership.name)}
        validation={formSchema.practiceAgreeToTakeOwnership.validation}
      />
    </>
  );
}

export default Step4;
