import { MAIL_TO_PROV_ACQUISITION_LINK } from 'constants/links';

function SuccessPageExtraInfo() {
  return (
    <>
      {' '}
      <bc-typography>Thank you for completing the first portion of your application.</bc-typography>
      <bc-typography>The following documents are required to complete your application:</bc-typography>
      <bc-list type="ol">
        <li>
          Provider W-9 - accepted formats: <strong>jpg jpeg png pdf</strong>
        </li>
        <li>
          Official Documentation of Acquisition - accepted formats: <strong>jpg jpeg png pdf</strong>
        </li>
        <li>
          Provider Roster (if you answered "yes" to the question above) - accepted formats: <strong>excel</strong>
        </li>
      </bc-list>
      <bc-typography>Requirements:</bc-typography>
      <bc-list type="ol">
        <li>
          Please include your <strong>practice name and NPI number in the subject line of the email</strong>
        </li>
        <li>
          All files should be sent within <strong>10 minutes of submitting your application</strong>
        </li>
        <li>
          Use the correct file formats listed above to ensure your application can be processed accurately and
          efficiently
        </li>
      </bc-list>
      <bc-typography>
        Note: Failure to follow the steps above when sending the required documentation will result in your request
        being returned as incomplete - usually within 10 days.
      </bc-typography>
      <bc-typography>
        Click the button below to open an email window or email us at:
        <a href={MAIL_TO_PROV_ACQUISITION_LINK} target="_blank" rel="noreferrer">
          ProviderAcquisition@bcbsnc.com
        </a>
      </bc-typography>
      <button
        type="button"
        className="button button--secondary button--regular"
        onClick={() => window.open(MAIL_TO_PROV_ACQUISITION_LINK, '_blank')}
      >
        Email Required Documents
      </button>
    </>
  );
}

export default SuccessPageExtraInfo;
