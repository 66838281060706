export const credentialingRadios = [
  {
    label: 'Non-participating enrollment request status',
    value: 'nonParticipating',
    id: 'nonParticipating',
  },
  {
    label: 'Participating contract request',
    value: 'participating',
    id: 'participating',
  },
];

export const completedCredentialingRadios = [
  { label: 'Yes', value: 'yes', id: 'yes' },
  { label: 'No / Not sure', value: 'noNotSure', id: 'noNotSure' },
];

export const taxInfoRadios = [
  {
    label: 'Solo practitioner not part of a group',
    value: 'solo',
    id: 'soloPractitioner',
  },
  {
    label: 'Practitioner enrolling with a group',
    value: 'withGroup',
    id: 'withGroup',
  },
];

export const degreeOptions = [
  { value: 'ACNP', label: 'ACNP' },
  { value: 'ANP', label: 'ANP' },
  { value: 'AUD', label: 'AUD' },
  { value: 'CNM', label: 'CNM' },
  { value: 'CRNA', label: 'CRNA' },
  { value: 'CSAC', label: 'CSAC' },
  { value: 'DC', label: 'DC' },
  { value: 'DDS', label: 'DDS' },
  { value: 'DMD', label: 'DMD' },
  { value: 'DO', label: 'DO' },
  { value: 'DPM', label: 'DPM' },
  { value: 'EdD', label: 'EdD' },
  { value: 'FND', label: 'FND' },
  { value: 'FNP', label: 'FNP' },
  { value: 'LCAS', label: 'LCAS' },
  { value: 'LCSW', label: 'LCSW' },
  { value: 'LDN', label: 'LDN' },
  { value: 'LHAD', label: 'LHAD' },
  { value: 'LPA', label: 'LPA' },
  { value: 'LPCA', label: 'LPCA' },
  { value: 'LPN', label: 'LPN' },
  { value: 'LPT', label: 'LPT' },
  { value: 'MD', label: 'MD' },
  { value: 'MHN', label: 'MHN' },
  { value: 'NP', label: 'NP' },
  { value: 'OD', label: 'OD' },
  { value: 'OTRL', label: 'OTRL' },
  { value: 'PAC', label: 'PAC (Physician Assistant Certified)' },
  { value: 'PhD', label: 'PhD' },
  { value: 'PsyD', label: 'PsyD' },
  { value: 'RNA', label: 'RNA' },
  { value: 'SLP', label: 'SLP' },
  { value: 'other', label: 'Other...' },
];

export const providerTypeOptions = [
  { value: 'hospitalist', label: 'Hospitalist' },
  { value: 'primaryCare', label: 'Primary Care' },
  { value: 'specialist', label: 'Specialist' },
  { value: 'other', label: 'Other...' },
];

export const primarySpecialtyOptions = [
  { value: 'Adolescent_Medicine', label: 'Adolescent Medicine' },
  { value: 'Alcoholic_Rehab_Hospital', label: 'Alcoholic Rehab Hospital' },
  { value: 'Allergy', label: 'Allergy' },
  { value: 'Allergy_Immunology', label: 'Allergy & Immunology' },
  { value: 'Ambulance', label: 'Ambulance' },
  { value: 'Ambulance_Service', label: 'Ambulance Service' },
  { value: 'Ambulatory_Infusion', label: 'Ambulatory Infusion' },
  { value: 'Ambulatory_Surgery_Center', label: 'Ambulatory Surgery Center' },
  { value: 'Ambulatory_Surgical_Center', label: 'Ambulatory Surgical Center' },
  { value: 'Anesthesiologist_Assistant', label: 'Anesthesiologist Assistant' },
  { value: 'Anesthesiology', label: 'Anesthesiology' },
  { value: 'Audiology', label: 'Audiology' },
  { value: 'Behavioral_Health_Urgent_Care', label: 'Behavioral Health Urgent Care' },
  { value: 'Birthing_Centers', label: 'Birthing Centers' },
  { value: 'Board_Certified_Behavioral_Analyst', label: 'Board Certified Behavioral Analyst' },
  {
    value: 'Bone_Density_Imaging_Center',
    label: 'Bone Density Imaging Center',
  },
  { value: 'Cancer_Center', label: 'Cancer Center' },
  {
    value: 'Cardiac_Catheterization_Services',
    label: 'Cardiac Catheterization Services',
  },
  { value: 'Cardiac_Electrophysiology', label: 'Cardiac Electrophysiology' },
  { value: 'Cardiac_Event_Monitoring', label: 'Cardiac Event Monitoring' },
  { value: 'Cardiac_Rehab_Hospital', label: 'Cardiac Rehab Hospital' },
  {
    value: 'Cardiac_Rehabilitation_Facility',
    label: 'Cardiac Rehabilitation Facility',
  },
  { value: 'Cardiac_Surgery', label: 'Cardiac Surgery' },
  { value: 'Cardiac_Surgery_Program', label: 'Cardiac Surgery Program' },
  { value: 'Cardiology', label: 'Cardiology' },
  {
    value: 'Cardiovascular_Thoracic_Surgery',
    label: 'Cardiovascular & Thoracic Surgery',
  },
  { value: 'Cardiovascular_Disease', label: 'Cardiovascular Disease' },
  { value: 'Cardiovascular_Surgery', label: 'Cardiovascular Surgery' },
  {
    value: 'Certified_Alcohol_and_Drug_Counselor',
    label: 'Certified Alcohol and Drug Counselor',
  },
  {
    value: 'Certified_Clinical_Social_Worker',
    label: 'Certified Clinical Social Worker',
  },
  {
    value: 'Certified_Nurse-midwife_as_Mental_Health_Specialist',
    label: 'Certified Nurse-midwife as Mental Health Specialist',
  },
  {
    value: 'Certified_Registered_Nurse_Anesthetist',
    label: 'Certified Registered Nurse Anesthetist',
  },
  { value: 'Chemical_Dependency', label: 'Chemical Dependency' },
  { value: 'Chemical_Dependency_Hospital', label: 'Chemical Dependency Hospital' },
  {
    value: 'Child_and_Adolescent_Psychiatry',
    label: 'Child and Adolescent Psychiatry',
  },
  { value: 'Child_Neurology', label: 'Child Neurology' },
  { value: 'Chiropractic', label: 'Chiropractic' },
  {
    value: 'Clinical_Cardiac_Electrophysiology',
    label: 'Clinical Cardiac Electrophysiology',
  },
  {
    value: 'Clinical_Nurse_Specialist_as_Mental_Health_Specialist',
    label: 'Clinical Nurse Specialist as Mental Health Specialist',
  },
  {
    value: 'Clinical_Social_Worker_as_Mental_Health_Specialist',
    label: 'Clinical Social Worker as Mental Health Specialist',
  },
  { value: 'Colon_Rectal_Surgery', label: 'Colon & Rectal Surgery' },
  {
    value: 'Comprehensive_Op_Rehab_Facility',
    label: 'Comprehensive Op Rehab Facility',
  },
  { value: 'Convenience_Care', label: 'Convenience Care' },
  { value: 'Convenience_Care_Center', label: 'Convenience Care Center' },
  { value: 'Critical_Care_Medicine', label: 'Critical Care Medicine' },
  {
    value: 'Critical_Care_Services_ICU',
    label: 'Critical Care Services / ICU',
  },
  { value: 'Dentistry', label: 'Dentistry' },
  { value: 'Dermatology', label: 'Dermatology' },
  { value: 'Dermatopathology', label: 'Dermatopathology' },
  {
    value: 'Developmental_Evaluation_Center',
    label: 'Developmental Evaluation Center',
  },
  {
    value: 'Developmental-behavioral_Pediatrics',
    label: 'Developmental-behavioral Pediatrics',
  },
  {
    value: 'Diabetic_Education_Teaching_Facility',
    label: 'Diabetic Education Teaching Facility',
  },
  { value: 'Diagnostic_Radiology', label: 'Diagnostic Radiology' },
  { value: 'Dialysis', label: 'Dialysis' },
  { value: 'Doula_Services', label: 'Doula Services' },
  { value: 'Durable_Medical_Equipment', label: 'Durable Medical Equipment' },
  { value: 'Emergency_Facility', label: 'Emergency Facility' },
  { value: 'Emergency_Medicine', label: 'Emergency Medicine' },
  {
    value: 'mployer_Based_Onsite_Clinic',
    label: 'Employer Based Onsite Clinic',
  },
  { value: 'Endocrinology', label: 'Endocrinology' },
  { value: 'Endodontics', label: 'Endodontics' },
  { value: 'Endoscopy_Facility', label: 'Endoscopy Facility' },
  { value: 'ENT_Otolaryngology', label: 'ENT / Otolaryngology' },
  { value: 'Facility_Based_Crisis_Services', label: 'Facility Based Crisis Services' },
  { value: 'Family_Medicine', label: 'Family Medicine' },
  { value: 'Family_Practice', label: 'Family Practice' },
  {
    value: 'Freestanding_Radiology_Technical',
    label: 'Freestanding Radiology Technical',
  },
  { value: 'Gastroenterology', label: 'Gastroenterology' },
  {
    value: 'General_Acute_Care_Hospital',
    label: 'General Acute Care Hospital',
  },
  { value: 'General_Dentistry', label: 'General Dentistry' },
  { value: 'General_Practice', label: 'General Practice' },
  { value: 'General_Surgery', label: 'General Surgery' },
  { value: 'Genetics', label: 'Genetics' },
  { value: 'Geriatric_Medicine', label: 'Geriatric Medicine' },
  { value: 'Gerontology', label: 'Gerontology' },
  { value: 'Gynecologic_Oncology', label: 'Gynecologic Oncology' },
  { value: 'Gynecological_Center', label: 'Gynecological Center' },
  { value: 'Gynecology', label: 'Gynecology' },
  { value: 'Hand_Surgery', label: 'Hand_Surgery' },
  { value: 'Hematologists', label: 'Hematologists' },
  { value: 'Hematology', label: 'Hematology' },
  { value: 'Hematology_Oncology', label: 'Hematology Oncology' },
  { value: 'Home_Health', label: 'Home Health' },
  { value: 'Home_Infusion', label: 'Home Infusion' },
  { value: 'Hospice', label: 'Hospice' },
  {
    value: 'Hospice_and_Palliative_Medicine',
    label: 'Hospice and Palliative Medicine',
  },
  { value: 'Hospital_Based_Providers', label: 'Hospital Based Providers' },
  {
    value: 'Hospital_Outpatient_Department',
    label: 'Hospital Outpatient Department',
  },
  { value: 'Hospitalist', label: 'Hospitalist' },
  { value: 'Immunizing_Pharmacist', label: 'Immunizing Pharmacist' },
  { value: 'Immunologists', label: 'Immunologists' },
  { value: 'Implant_Surgery', label: 'Implant Surgery' },
  {
    value: 'Independent_Diagnostic_Testing_Facility',
    label: 'Independent Diagnostic Testing Facility',
  },
  { value: 'Independent_Laboratory', label: 'Independent Laboratory' },
  { value: 'Infectious_Disease', label: 'Infectious Disease' },
  {
    value: 'Inpatient_Psychiatric_Facility_Services',
    label: 'Inpatient Psychiatric Facility Services',
  },
  { value: 'Inpatient_Rehab', label: 'Inpatient Rehab' },
  { value: 'Inpatient_Substance_Abuse', label: 'Inpatient Substance Abuse' },
  { value: 'Inpatient_Swing_Beds', label: 'Inpatient Swing Beds' },
  {
    value: 'Intensive_Outpatient_Program',
    label: 'Intensive Outpatient Program',
  },
  { value: 'Internal_Medicine', label: 'Internal Medicine' },
  {
    value: 'Internal_Medicine-pediatrics',
    label: 'Internal Medicine-pediatrics',
  },
  { value: 'Interventional_Cardiology', label: 'Interventional Cardiology' },
  { value: 'Interventional_Radiology', label: 'Interventional Radiology' },
  {
    value: 'ITS_Miscellaneous_Specialty',
    label: 'ITS - Miscellaneous Specialty',
  },
  { value: 'Laboratory_Nonpar', label: 'Laboratory / Nonpar' },
  {
    value: 'Licensed_Clinical_Addictions_Specialist',
    label: 'Licensed Clinical Addictions Specialist',
  },
  {
    value: 'Licensed_Clinical_Addictions_Specialist_Associate',
    label: 'Licensed Clinical Addictions Specialist Associate',
  },
  {
    value: 'Licensed_Clinical_Mental_Health_Counselor',
    label: 'Licensed Clinical Mental Health Counselor (former Professional Counselor)',
  },
  {
    value: 'Licensed_Clinical_Mental_Health_Counselor_Associate',
    label:
      'AmbulaLicensed Clinical Mental Health Counselor Associate (former Licensed Professional Counselor Associate)nce',
  },
  {
    value: 'Licensed_Clinical_Social_Worker',
    label: 'Licensed Clinical Social Worker',
  },
  {
    value: 'Licensed_Clinical_Social_Worker_Associate',
    label: 'Licensed Clinical Social Worker Associate',
  },
  {
    value: 'Licensed_Dietician_Nutritionist_LDN',
    label: 'Licensed Dietician Nutritionist (LDN)',
  },
  {
    value: 'Licensed_Dietitian_Nutrition',
    label: 'Licensed Dietitian / Nutrition',
  },
  {
    value: 'Licensed_Hearing_Aid_Dealer_Fitter',
    label: 'Licensed Hearing Aid Dealer / Fitter',
  },
  {
    value: 'Licensed_Marriage_and_Family_Therapist',
    label: 'Licensed Marriage and Family Therapist',
  },
  {
    value: 'Licensed_Marriage_and_Family_Therapists',
    label: 'Licensed Marriage and Family Therapists',
  },
  {
    value: 'Licensed_Marriage_and_Family_Therapy_Associate',
    label: 'Licensed Marriage and Family Therapy Associate',
  },
  { value: 'Licensed_Practical_Nurse', label: 'Licensed Practical Nurse' },
  {
    value: 'Licensed_Professional_Counselor',
    label: 'Licensed Professional Counselor',
  },
  {
    value: 'Licensed_Psychological_Associate',
    label: 'Licensed Psychological Associate',
  },
  { value: 'Lithotripsy_Center', label: 'Lithotripsy Center' },
  { value: 'Long_Term_Care_Hospital', label: 'Long Term Care Hospital' },
  {
    value: 'Long-term_Acute_Care_Hospital',
    label: 'Long-term Acute Care Hospital',
  },
  { value: 'Mammography', label: 'Mammography' },
  { value: 'Maternal_Fetal_Medicine', label: 'Maternal & Fetal Medicine' },
  { value: 'MDPP_Provider', label: 'MDPP Provider' },
  {
    value: 'Med_Supp_Miscellaneous_Specialty',
    label: 'Med Supp Miscellaneous Specialty',
  },
  { value: 'Medical_Oncology', label: 'Medical Oncology' },
  { value: 'AmMental_Health_Centerbulance', label: 'Mental Health Center' },
  {
    value: 'Mental_Health_Treatment_Center_opioid_Treatment_Facility',
    label: 'Mental Health Treatment Center (Opioid Treatment Facility)',
  },
  {
    value: 'Mental_Health_Treatment_Center_residential_Treatment_Facility',
    label: 'Mental Health Treatment Center (residential Treatment Facility)',
  },
  { value: 'Mental_Health_Nurse', label: 'Mental Health Nurse' },
  { value: 'Midwife', label: 'Midwife' },
  { value: 'Midwife_Certified_Nurse', label: 'Midwife, Certified Nurse' },
  { value: 'Mobile_Diagnostic', label: 'Mobile Diagnostic' },
  { value: 'Mobile_Lithotripsy', label: 'Mobile Lithotripsy' },
  { value: 'Modular_Lithotripsy', label: 'Modular Lithotripsy' },
  { value: 'Multispecialty', label: 'Multispecialty' },
  {
    value: 'Neonatal-perinatal_Medicine',
    label: 'Neonatal-perinatal Medicine',
  },
  { value: 'Neonatal_Perinatal', label: 'Neonatal / Perinatal' },
  { value: 'Nephrology', label: 'Nephrology' },
  { value: 'Neurology', label: 'Neurology' },
  {
    value: 'Neuromusculosketal_Medicine',
    label: 'Neuromusculosketal Medicine',
  },
  { value: 'Neuroradiology', label: 'Neuroradiology' },
  { value: 'Neurosurgery', label: 'Neurosurgery' },
  { value: 'NonHospital_Medical_Detox', label: 'NonHospital Medical Detox' },
  { value: 'Non-participating_Hospital', label: 'Non-participating Hospital' },
  { value: 'Nuclear_Medicine', label: 'Nuclear Medicine' },
  { value: 'Nurse_Anesthetist', label: 'Nurse Anesthetist' },
  { value: 'Nurse_Practitioner', label: 'Nurse Practitioner' },
  {
    value: 'Nurse_Practitioner_Acute_Care',
    label: 'Nurse Practitioner Acute Care',
  },
  {
    value: 'Nurse_Practitioner_Adult_Health',
    label: 'Nurse Practitioner Adult Health',
  },
  {
    value: 'Nurse_Practitioner_as_Mental_Health_Specialist',
    label: 'Nurse Practitioner as Mental Health Specialist',
  },
  {
    value: 'Nurse_Practitioner_as_Specialist',
    label: 'Nurse Practitioner as Specialist',
  },
  {
    value: 'Nurse_Practitioner_Community_Health',
    label: 'Nurse Practitioner Community Health',
  },
  {
    value: 'Nurse_Practitioner_Critical_Care_Medicine',
    label: 'Nurse Practitioner Critical Care Medicine',
  },
  { value: 'Nurse_Practitioner_Family', label: 'Nurse Practitioner Family' },
  {
    value: 'Nurse_Practitioner_Gerontology',
    label: 'Nurse Practitioner Gerontology',
  },
  {
    value: 'Nurse_Practitioner_Neonatal',
    label: 'Nurse Practitioner Neonatal',
  },
  {
    value: 'Nurse_Practitioner_Neonatal_Critical_Care',
    label: 'Nurse Practitioner Neonatal, Critical Care',
  },
  {
    value: 'Nurse_Practitioner_Obstetrics_Gynecology',
    label: 'Nurse Practitioner Obstetrics & Gynecology',
  },
  {
    value: 'Nurse_Practitioner_Occupational_Health',
    label: 'Nurse Practitioner Occupational Health',
  },
  {
    value: 'Nurse_Practitioner_Pediatrics',
    label: 'Nurse Practitioner Pediatrics',
  },
  {
    value: 'Nurse_Practitioner_Pediatrics_Critical_Care',
    label: 'Nurse Practitioner Pediatrics, Critical Care',
  },
  {
    value: 'Nurse_Practitioner_Perinatal',
    label: 'Nurse Practitioner Perinatal',
  },
  {
    value: 'Nurse_Practitioner_Primary_Care',
    label: 'Nurse Practitioner Primary Care',
  },
  {
    value: 'Nurse_Practitioner_Psychiatric_Mental_Health',
    label: 'Nurse Practitioner Psychiatric / Mental Health',
  },
  { value: 'Nurse_Practitioner_School', label: 'Nurse Practitioner School' },
  {
    value: 'Nurse_Practitioner_Womens_Health',
    label: "Nurse Practitioner Women's Health",
  },
  { value: 'Nutrition_Center', label: 'Nutrition Center' },
  { value: 'Obstetrics', label: 'Obstetrics' },
  { value: 'Obstetrics_Gynecology', label: 'Obstetrics & Gynecology' },
  { value: 'Occupational_Health', label: 'Occupational Health' },
  { value: 'Occupational_Medicine', label: 'Occupational Medicine' },
  { value: 'Occupational_Therapy', label: 'Occupational Therapy' },
  { value: 'Oncology_Surgery', label: 'Oncology Surgery' },
  { value: 'Ophthalmology', label: 'Ophthalmology' },
  { value: 'Opticians', label: 'Opticians' },
  { value: 'Optometry', label: 'Optometry' },
  { value: 'Oral_Maxillofacial_Surgery', label: 'Oral Maxillofacial Surgery' },
  { value: 'Oral_Surgery', label: 'Oral Surgery' },
  { value: 'Orthodontia', label: 'Orthodontia' },
  { value: 'Orthopedic_Surgery', label: 'Orthopedic Surgery' },
  { value: 'Orthopedics', label: 'Orthopedics' },
  { value: 'Orthotics_Prosthetic', label: 'Orthotics & Prosthetic' },
  { value: 'Otolaryngology', label: 'Otolaryngology' },
  { value: 'Otology', label: 'Otology' },
  { value: 'Outpatient_Dialysis', label: 'Outpatient Dialysis' },
  {
    value: 'Outpatient_Infusion_Chemotherapy',
    label: 'Outpatient Infusion / Chemotherapy',
  },
  { value: 'Outpatient_Substance_Abuse', label: 'Outpatient Substance Abuse' },
  { value: 'Pain_Management', label: 'Pain Management' },
  { value: 'Pain_Management_Center', label: 'Pain Management Center' },
  { value: 'Pain_Medicine', label: 'Pain Medicine' },
  {
    value: 'Palliative_Care_Nurse_Practitioner',
    label: 'Palliative Care - Nurse Practitioner',
  },
  {
    value: 'Palliative_Care_Physican_Assistant',
    label: 'Palliative Care - Physican Assistant',
  },
  { value: 'Palliative_Care_Physician', label: 'Palliative Care - Physician' },
  { value: 'Participating_Hospital', label: 'Participating Hospital' },
  { value: 'Participating_Laboratory', label: 'Participating Laboratory' },
  { value: 'Pastoral_Counselors', label: 'Pastoral Counselors' },
  { value: 'Pathology', label: 'Pathology' },
  {
    value: 'AmbulaPediatric_Allergy_Immunologynce',
    label: 'Pediatric Allergy & Immunology',
  },
  { value: 'Pediatric_Anesthesiology', label: 'Pediatric Anesthesiology' },
  { value: 'AmbulPediatric_Cardiologyance', label: 'Pediatric Cardiology' },
  { value: 'Pediatric_Critical_Care', label: 'Pediatric Critical Care' },
  {
    value: 'Pediatric_Critical_Care_Medicine',
    label: 'Pediatric Critical Care Medicine',
  },
  { value: 'Pediatric_Dentistry', label: 'Pediatric Dentistry' },
  {
    value: 'Pediatric_Emergency_Medicine',
    label: 'Pediatric Emergency Medicine',
  },
  { value: 'Pediatric_Endocrinology', label: 'Pediatric Endocrinology' },
  { value: 'Pediatric_Gastroenterology', label: 'Pediatric Gastroenterology' },
  {
    value: 'Pediatric_Hematology-oncology',
    label: 'Pediatric Hematology-oncology',
  },
  {
    value: 'Pediatric_Hematology_Oncology',
    label: 'Pediatric Hematology / Oncology',
  },
  {
    value: 'Pediatric_Infectious_Disease',
    label: 'Pediatric Infectious Disease',
  },
  { value: 'Pediatric_Nephrology', label: 'Pediatric Nephrology' },
  { value: 'Pediatric_Neurology', label: 'Pediatric Neurology' },
  {
    value: 'Pediatric_Orthopedic_Surgery',
    label: 'Pediatric Orthopedic Surgery',
  },
  { value: 'Pediatric_Otolaryngology', label: 'Pediatric Otolaryngology' },
  { value: 'Pediatric_Pulmonology', label: 'Pediatric Pulmonology' },
  { value: 'Pediatric_Radiology', label: 'Pediatric Radiology' },
  { value: 'Pediatric_Rheumatology', label: 'Pediatric Rheumatology' },
  { value: 'Pediatric_Surgery', label: 'Pediatric Surgery' },
  { value: 'Pediatrics', label: 'Pediatrics' },
  { value: 'Pediatrics_and_Urology', label: 'Pediatrics and Urology' },
  { value: 'Periodontology', label: 'Periodontology' },
  { value: 'Pharmacy', label: 'Pharmacy' },
  {
    value: 'Physiatry_Rehabilitative_Medicine',
    label: 'Physiatry / Rehabilitative Medicine',
  },
  { value: 'Physical_Medicine_Rehab', label: 'Physical Medicine Rehab' },
  { value: 'Physical_Therapy', label: 'Physical Therapy' },
  { value: 'Physician_Assistant', label: 'Physician Assistant' },
  {
    value: 'Physician_Assistant_as_Mental_Health_Specialist',
    label: 'Physician Assistant as Mental Health Specialist',
  },
  {
    value: 'Physician_Assistant_as_Spec',
    label: 'Physician Assistant as Spec',
  },
  {
    value: 'Physician_Osteopathic_Manipulative_Medicine',
    label: 'Physician / Osteopathic Manipulative Medicine',
  },
  { value: 'Plastic_Surgery', label: 'Plastic Surgery' },
  { value: 'Podiatry', label: 'Podiatry' },
  { value: 'Preventive_Medicine', label: 'Preventive Medicine' },
  {
    value: 'Primary_Care-nurse_Practitioners',
    label: 'Primary Care-nurse Practitioners',
  },
  {
    value: 'Primary_Care-physician_Assistants',
    label: 'Primary Care-physician Assistants',
  },
  { value: 'Private_Duty_Nursing', label: 'Private Duty Nursing' },
  { value: 'Proctology', label: 'Proctology' },
  { value: 'Prosthodontics', label: 'Prosthodontics' },
  { value: 'Psychiatric_Hospital', label: 'Psychiatric Hospital' },
  { value: 'Psychiatry', label: 'Psychiatry' },
  { value: 'Psychiatry_Neurology', label: 'Psychiatry, Neurology' },
  { value: 'Psychology', label: 'Psychology' },
  { value: 'Public_Health', label: 'Public Health' },
  { value: 'Pulmonary_Disease', label: 'Pulmonary_Disease' },
  {
    value: 'Pulmonary_Rehabilitation_Facility',
    label: 'Pulmonary Rehabilitation Facility',
  },
  { value: 'Pulmonology', label: 'Pulmonology' },
  { value: 'Radiation_Oncology', label: 'Radiation Oncology' },
  {
    value: 'Radiation_Therapy_Facility_Services',
    label: 'Radiation Therapy Facility Services',
  },
  { value: 'Radiology', label: 'Radiology' },
  { value: 'Radiology_Facility', label: 'Radiology Facility' },
  { value: 'Radiology_Freestanding', label: 'Radiology Freestanding' },
  { value: 'Registered_Nurse', label: 'Registered Nurse' },
  { value: 'Rehab_Hospital', label: 'Rehab Hospital' },
  {
    value: 'Rehabilitation_Hospital_Facility',
    label: 'Rehabilitation Hospital / Facility',
  },
  { value: 'Reproductive_Endocrinology', label: 'Reproductive Endocrinology' },
  {
    value: 'Res_Nervous_Mental_Treatment_Center',
    label: 'Res Nervous / Mental Treatment Center',
  },
  {
    value: 'Residential_Chemical_Dependency_Treatment_Facility',
    label: 'Residential Chemical Dependency Treatment Facility',
  },
  { value: 'Respiratory_Therapy', label: 'Respiratory Therapy' },
  { value: 'Retinal_Ophthalmology', label: 'Retinal Ophthalmology' },
  { value: 'Rheumatology', label: 'Rheumatology' },
  { value: 'Rural_Health_Clinic', label: 'Rural Health Clinic' },
  {
    value: 'School_Based_Health_Centers',
    label: 'School Based Health Centers',
  },
  { value: 'Skilled_Nursing_Facilities', label: 'Skilled Nursing Facilities' },
  { value: 'Skilled_Nursing_Facility', label: 'Skilled Nursing Facility' },
  { value: 'Sleep_Medicine', label: 'Sleep Medicine' },
  { value: 'Sleep_Medicine_Facility', label: 'Sleep Medicine Facility' },
  {
    value: 'Specialty_Facility_Hospital',
    label: 'Specialty Facility / Hospital',
  },
  { value: 'Specialty_Pharmacy', label: 'Specialty Pharmacy' },
  {
    value: 'Specialty_Pharmacy_Services',
    label: 'Specialty Pharmacy Services',
  },
  {
    value: 'Specialty_Short_Term_Hospital',
    label: 'Specialty Short Term Hospital',
  },
  { value: 'Speech_Language_Pathology', label: 'Speech Language Pathology' },
  { value: 'Speech_Therapy', label: 'Speech Therapy' },
  {
    value: 'Speech_Occupational_Physical_Therapy',
    label: 'Speech, Occupational & Physical Therapy',
  },
  { value: 'Sports_Medicine', label: 'Sports Medicine' },
  { value: 'Thoracic_Surgery', label: 'Thoracic Surgery' },
  {
    value: 'University_College_Infirmary',
    label: 'University / College Infirmary',
  },
  {
    value: 'Urgent_Primary_Care_Physician',
    label: 'Urgent & Primary Care Physician',
  },
  { value: 'Urgent_Care', label: 'Urgent Care' },
  {
    value: 'Urgent_Care_Nurse_Practitioner',
    label: 'Urgent Care Nurse Practitioner',
  },
  {
    value: 'Urgent_Care_Physician_Assistant',
    label: 'Urgent Care Physician Assistant',
  },
  {
    value: 'Urgent_Primary_Care_Nurse_Practitioner',
    label: 'Urgent / Primary Care Nurse Practitioner',
  },
  {
    value: 'Urgent_Primary_Care_Physician_Assistant',
    label: 'Urgent / Primary Care Physician Assistant',
  },
  { value: 'Urology', label: 'Urology' },
  { value: 'Vascular_Surgery', label: 'Vascular Surgery' },
  {
    value: 'Voluntary_Interruption_Of_Pregnancy_Center',
    label: 'Voluntary Interruption Of Pregnancy Center',
  },
  { value: 'Wound_Care_Center', label: 'Wound Care Center' },
  { value: 'other', label: 'Other...' },
];

export const secondarySpecialtyOptions = [
  { value: 'notApplicable', label: 'NOT APPLICABLE' },
  ...primarySpecialtyOptions,
];

export const telehealthRadios = [
  {
    label: 'Telehealth with Practice Setting(s)',
    value: 'telehealthPractice',
    id: 'telehealthPractice',
  },
  { label: 'Not Applicable', value: 'notApplicable', id: 'telehealthNotApplicable' },
];

export const medicareMembersRadios = [
  { label: 'Commercial Only', value: 'commercialOnly', id: 'commercialOnly' },
  { label: 'Blue Medicare Only', value: 'blueMedicareOnly', id: 'blueMedicareOnly' },
  { label: 'Commercial & Blue Medicare', value: 'commercialAndBlueMedicare', id: 'commercialAndBlueMedicare' },
];

export const stateOptions = [
  { label: 'Alabama', value: 'alabama' },
  { label: 'Alaska', value: 'alaska' },
  { label: 'American Samoa', value: 'americanSamoa' },
  { label: 'Arizona', value: 'arizona' },
  { label: 'Arkansas', value: 'arkansas' },
  { label: 'California', value: 'california' },
  { label: 'Colorado', value: 'colorado' },
  { label: 'Connecticut', value: 'connecticut' },
  { label: 'Delaware', value: 'delaware' },
  { label: 'District of Columbia', value: 'districtOfColumbia' },
  { label: 'Florida', value: 'florida' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Guam', value: 'guam' },
  { label: 'Hawaii', value: 'hawaii' },
  { label: 'Idaho', value: 'idaho' },
  { label: 'Illinois', value: 'illinois' },
  { label: 'Indiana', value: 'indiana' },
  { label: 'Iowa', value: 'iowa' },
  { label: 'Kansas', value: 'kansas' },
  { label: 'Kentucky', value: 'kentucky' },
  { label: 'Louisiana', value: 'louisiana' },
  { label: 'Maine', value: 'maine' },
  { label: 'Marshall Islands', value: 'marshallIslands' },
  { label: 'Maryland', value: 'maryland' },
  { label: 'Massachussetts', value: 'massachussetts' },
  { label: 'Michigan', value: 'michigan' },
  { label: 'Minnesota', value: 'minnesota' },
  { label: 'Mississippi', value: 'mississippi' },
  { label: 'Missouri', value: 'missouri' },
  { label: 'Montana', value: 'montana' },
  { label: 'Nebraska', value: 'nebraska' },
  { label: 'Nevada', value: 'nevada' },
  { label: 'New Hampshire', value: 'newHampshire' },
  { label: 'New Jersey', value: 'newJersey' },
  { label: 'New Mexico', value: 'newMexico' },
  { label: 'New York', value: 'newYork' },
  { label: 'North Carolina', value: 'northCarolina' },
  { label: 'North Dakota', value: 'northDakota' },
  { label: 'Northern Marianas Islands', value: 'northernMarianasIslands' },
  { label: 'Ohio', value: 'ohio' },
  { label: 'Oklahoma', value: 'oklahoma' },
  { label: 'Oregon', value: 'oregon' },
  { label: 'Palau', value: 'palau' },
  { label: 'Pennsylvania', value: 'pennsylvania' },
  { label: 'Puerto Rico', value: 'puertoRico' },
  { label: 'Rhode Island', value: 'rhodeIsland' },
  { label: 'South Carolina', value: 'southCarolina' },
  { label: 'South Dakota', value: 'southDakota' },
  { label: 'Tennessee', value: 'tennessee' },
  { label: 'Texas', value: 'texas' },
  { label: 'Utah', value: 'utah' },
  { label: 'Vermont', value: 'vermont' },
  { label: 'Virgin Islands', value: 'virginIslands' },
  { label: 'Virginia', value: 'virginia' },
  { label: 'Washington', value: 'washington' },
  { label: 'West Virginia', value: 'westVirginia' },
  { label: 'Wisconsin', value: 'wisconsin' },
  { label: 'Wyoming', value: 'wyoming' },
];

export const highTechRadios = [
  { label: 'Yes', value: 'yes', id: 'yesHighTech' },
  { label: 'No', value: 'no', id: 'noHighTech' },
];

export const secondaryLocationRadios = [
  { label: 'Yes', value: 'yes', id: 'yesSecondaryLocation' },
  { label: 'No', value: 'no', id: 'noSecondaryLocation' },
];

export const billingAddressRadios = [
  { label: 'Yes', value: 'yes', id: 'yesSameBillingAddress' },
  { label: 'No', value: 'no', id: 'noSameBillingAddress' },
];

export const languagesSpokenOptions = [
  { label: 'Arabic', value: 'arabic' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'French', value: 'french' },
  { label: 'German', value: 'german' },
  { label: 'Gujarati', value: 'gujarati' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Italian', value: 'italian' },
  { label: 'Russian', value: 'russian' },
  { label: 'Sign Language', value: 'signLanguage' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'I choose not to say', value: 'chooseNotToSay' },
  { label: 'Other...', value: 'otherLanguage' },
];

export const raceEthnicityOptions = [
  {
    label: 'American Indian or Alaska Native',
    value: 'americanIndianAlaskaNative',
  },
  { label: 'Asian (Asian Indian, Bangladeshi, Bhutanese...)', value: 'asian' },
  {
    label: 'Black or African American (Black, African American, African...)',
    value: 'blackAfricanAmerican',
  },
  {
    label: 'Hispanic or Latino (Spaniard, Mexican, Central American...)',
    value: 'hispanicLatino',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander (Polynesian, Micronesian, Melanesian)',
    value: 'nativeHawaiianPacificIslander',
  },
  {
    label: 'White (European, Middle Eastern or North African, Arab)',
    value: 'white',
  },
  { label: 'Prefer Not to Say', value: 'preferNotToSay' },
  { label: 'Other...', value: 'otherRaceEthnicity' },
];

export const servicesLocationOptions = [
  { label: 'Inpatient Hospital', value: 'inpatientHospital' },
  { label: 'Outpatient Hospital', value: 'outpatientHospital' },
  { label: 'Office', value: 'office' },
  { label: 'Home Health', value: 'homeHealth' },
  { label: 'Skilled Nursing Facility', value: 'skilledNursingFacility' },
  { label: 'Other...', value: 'otherServicesLocation' },
];

export const NPIRadios = [
  {
    value: 'solo',
    label: 'Solo NPI',
    id: 'soloNPI',
  },
  {
    value: 'group',
    label: 'Group NPI',
    id: 'groupNPI',
  },
];

export const haveAdditionalGroupNPIRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesAdditionalGroupNPIs',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noAdditionalGroupNPIs',
  },
];

export const chooseDayOptions = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
];

export const chooseTimeOptions = [
  { label: '8am - 12pm', value: '8am-12pm' },
  { label: '1pm - 4pm', value: '1pm-4pm' },
  { label: '5pm - 7pm', value: '5pm-7pm' },
];

export const haveRosterToUploadRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesHaveRoster',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noHaveRoster',
  },
];

export const agreeToSubmitDocumentationRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesAgreeToSubmitDocumentation',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noAgreeToSubmitDocumentation',
  },
];

export const practiceAgreeToTakeOwnershipRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesPracticeAgreeToTakeOwnership',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noPracticeAgreeToTakeOwnership',
  },
];

export const currentContractUnderNewLegalNameRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesCurrentContractUnderNewLegalName',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noCurrentContractUnderNewLegalName',
  },
];

export const firstProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesFirstProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noFirstProviderShouldShowInDirectory',
  },
];

export const firstProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesFirstProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noFirstProviderAcceptingNewPatients',
  },
];

export const secondProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesSecondProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noSecondProviderShouldShowInDirectory',
  },
];

export const secondProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesSecondProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noSecondProviderAcceptingNewPatients',
  },
];

export const thirdProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesThirdProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noThirdProviderShouldShowInDirectory',
  },
];

export const thirdProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesThirdProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noThirdProviderAcceptingNewPatients',
  },
];

export const fourthProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesFourthProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noFourthProviderShouldShowInDirectory',
  },
];

export const fourthProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesFourthProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noFourthProviderAcceptingNewPatients',
  },
];

export const fifthProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesFifthProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noFifthProviderShouldShowInDirectory',
  },
];

export const fifthProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesFifthProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noFifthProviderAcceptingNewPatients',
  },
];

export const sixthProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesSixthProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noSixthProviderShouldShowInDirectory',
  },
];

export const sixthProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesSixthProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noSixthProviderAcceptingNewPatients',
  },
];

export const seventhProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesSeventhProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noSeventhProviderShouldShowInDirectory',
  },
];

export const seventhProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesSeventhProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noSeventhProviderAcceptingNewPatients',
  },
];

export const eighthProviderShouldShowInDirectoryRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesEighthProviderShouldShowInDirectory',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noEighthProviderShouldShowInDirectory',
  },
];

export const eighthProviderAcceptingNewPatientsRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesEighthProviderAcceptingNewPatients',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noEighthProviderAcceptingNewPatients',
  },
];

export const newPracticeTakingOverPriorPracticeRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesNewPracticeTakingOverPriorPractice',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noNewPracticeTakingOverPriorPractice',
  },
];

export const newPracticeStartingNewContractRadios = [
  {
    value: 'yes',
    label: 'Yes',
    id: 'yesNewPracticeStartingNewContract',
  },
  {
    value: 'no',
    label: 'No',
    id: 'noNewPracticeStartingNewContract',
  },
];

export const haveCompletedW9Options = [
  {
    value: 'haveCompletedW9',
    label: 'I have completed and emailed the W-9 Form to ProvRequests@bcbsnc.com',
  },
];

export const providerGroupContractStatusRadios = [
  {
    value: 'activeContract',
    label: 'I have an active contract to be in-network',
    id: 'activeContract',
  },
  {
    value: 'noActiveContractYet',
    label: 'I do not have an active contract but I want to have an active contract to be in-network',
    id: 'noActiveContractYet',
  },
  {
    value: 'remainOutOfNetwork',
    label: 'I want to remain out-of-network',
    id: 'remainOutOfNetwork',
  },
];

export const groupTypeRadios = [
  {
    value: 'providerGroup',
    label: 'Provider Group',
    id: 'providerGroup',
  },
  {
    value: 'facilityOrAncillary',
    label: 'Facility or Ancillary',
    id: 'facilityOrAncillary',
  },
];

export const servicesOfferedRadios = [
  {
    value: 'autism',
    label: 'Autism',
    id: 'autism',
  },
  {
    value: 'lactation',
    label: 'Lactation',
    id: 'lactation',
  },
  {
    value: 'notApplicable',
    label: 'Not Applicable',
    id: 'autismNotApplicable',
  },
];

export const willPatientsBeSeenRadios = [
  {
    value: 'yesPatientsWillBeSeen',
    label: 'Yes',
    id: 'yesPatientsWillBeSeen',
  },
  {
    value: 'noPatientsWillBeSeen',
    label: 'No',
    id: 'noPatientsWillBeSeen',
  },
];

export const numberOfPractitionersRadios = [
  {
    value: '1or2Practitioners',
    label: '1 or 2 Practitioners',
    id: '1or2Practitioners',
  },
  {
    value: 'noPatientsWillBeSeen',
    label: 'More than 2 - We will use the Demographic and Contact Information Form',
    id: 'moreThan2Practitioners',
  },
];

export const facilityTypeOptions = [
  { label: 'Ambulance', value: 'ambulance' },
  { label: 'Ambulatory Infusion Centers', value: 'ambulatoryInfusionCenters' },
  { label: 'Ambulatory Surgical Centers', value: 'ambulatorySurgicalCenters' },
  { label: 'Birthing Center (Blue Cross NC Only)', value: 'birthingCenter' },
  { label: 'Dialysis Facility', value: 'dialysisFacility' },
  { label: 'Durable Medical Equipment (Diabetic Supplies Only)', value: 'durableMedicalEquipment' },
  { label: 'Federally Qualified Health Centers FQHC', value: 'FQHC' },
  { label: 'Free Standing Radiology', value: 'freeStandingRadiology' },
  { label: 'Home Durable Medical Equipment', value: 'homeDurableMedicalEquipment' },
  { label: 'Home Health Agency', value: 'homeHealthAgency' },
  { label: 'Home Infusion Therapy', value: 'homeInfusionTherapy' },
  { label: 'Hospice Agency (Blue Cross NC Only)', value: 'hospiceAgency' },
  { label: 'Independent Diagnostic Testing Facility', value: 'independentDiagnosticTestingFacility' },
  { label: 'Intensive Outpatient Facility (Blue Cross NC Only)', value: 'intensiveOutpatientFacility' },
  { label: 'Mobile X-ray (Blue Medicare HMO and Blue Medicare PPO networks only)', value: 'mobileXRay' },
  { label: 'Opioid Treatment Facilities', value: 'opiodTreatmentFacilities' },
  { label: 'Orthotics and Prosthetics', value: 'orthoticsAndProsthetics' },
  { label: 'Partial Hospitalization (Blue Cross NC Only)', value: 'partialHospitalization' },
  { label: 'Private Duty Nursing (Blue Cross NC Only)', value: 'privateDutyNursing' },
  { label: 'Reference Laboratories', value: 'referenceLaboratories' },
  { label: 'Residential Treatment Facilities (Blue Cross NC Only)', value: 'residentialTreatmentFacilities' },
  { label: 'Skilled Nursing Facility', value: 'skilledNursingFacility' },
  { label: 'Sleep Center (Blue Medicare HMO and Blue Medicare PPO networks only)', value: 'sleepCenter' },
  { label: 'Specialty Pharmacy', value: 'specialtyPharmacy' },
];
