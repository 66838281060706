import { createContext, Dispatch, Reducer, useContext } from 'react';
import { Action } from './constants/store';
import { InitialStateType, ReducerType } from './types/storeTypes';

export const initialState: InitialStateType = {
  formSubmittedSuccessfully: false,
  submissionDate: '',
  referenceCode: '',
  isLoading: false,
  formTitle: '',
  formSubmittedFailure: false,
  previousUrl: '',
};

export const reducer: Reducer<InitialStateType, ReducerType> = (state, action): InitialStateType => {
  const {
    type,
    formSubmittedSuccessfully,
    submissionDate,
    referenceCode,
    isLoading,
    formTitle,
    formSubmittedFailure,
    previousUrl,
  } = action;

  switch (type) {
    case Action.UPDATE_FORM_SUBMITTED_SUCCESSFULLY:
      return { ...state, formSubmittedSuccessfully };

    case Action.UPDATE_SUBMISSION_DATE:
      return { ...state, submissionDate };

    case Action.UPDATE_REFERENCE_CODE:
      return { ...state, referenceCode };

    case Action.UPDATE_IS_LOADING:
      return { ...state, isLoading };

    case Action.UPDATE_FORM_TITLE:
      return { ...state, formTitle };

    case Action.UPDATE_FORM_SUBMITTED_FAILURE:
      return { ...state, formSubmittedFailure };

    case Action.UPDATE_PREVIOUS_URL:
      return { ...state, previousUrl };

    default: {
      return state;
    }
  }
};

export const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const useStore = (): {
  state: InitialStateType;
  dispatch: Dispatch<any>;
} => useContext(AppContext);
