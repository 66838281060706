export enum EmailRecipients {
  CreditableCoverage = 'AdminRequest@bcbsnc.com',
  FraudAndAbuse = 'SIU@bcbsnc.com',
  NursingAdvocate = 'NursingSupport@bcbsnc.com',
  IndividualPractitionerEnrollment = 'ProvRequests@bcbsnc.com',
  ProviderAcquisition = 'ProviderAcquisition@bcbsnc.com',
  ProviderGroupEnrollment = 'ProvRequests@bcbsnc.com',
}

export enum EmailSubjects {
  CreditableCoverage = 'Form submission from: Certificate of Creditable Coverage',
  FraudAndAbuse = 'Fraud and Abuse Report Submission',
  NursingAdvocate = 'Form submission from: Nurse Advocate Contact Form',
  IndividualPractitionerEnrollment = 'Individual Practitioner Enrollment Application',
  ProviderAcquisition = 'Form submission from: Provider Acquisition Form',
  ProviderGroupEnrollment = 'Provider Group Enrollment Application',
}

export interface EmailDataType extends EmailTemplateType {
  values: object;
}

export interface EmailTemplateType {
  html: string;
  text: string;
}
