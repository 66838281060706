import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import {
  billingAddressRadios,
  secondaryLocationRadios,
  stateOptions,
  willPatientsBeSeenRadios,
} from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerGroupEnrollmentForm/schema';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';
import { phoneFormatHoverTitle } from 'constants/hoverTitles';

function Step3({ values, errors, touched }: any) {
  const renderSecondaryLocationInputs = () => {
    if (values.secondaryLocation === 'yes') {
      return (
        <Fieldset>
          <Legend required> Enter your current information:</Legend>
          <bc-typography>
            Please note: Only locations where the practitioner works 2 or more days a week will be displayed in the Blue
            Cross NC directory. Please indicate for each location listed below.
          </bc-typography>
          <BCInput
            width="75"
            label={formSchema.secondaryLocationAddress.label}
            name={formSchema.secondaryLocationAddress.name}
            value={values.secondaryLocationAddress}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationAddress.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationSuiteAptUnitFloor.label}
            name={formSchema.secondaryLocationSuiteAptUnitFloor.name}
            value={values.secondaryLocationSuiteAptUnitFloor}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationSuiteAptUnitFloor.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationCity.label}
            name={formSchema.secondaryLocationCity.name}
            value={values.secondaryLocationCity}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationCity.name)}
          />
          <Fieldset noMargin>
            <Legend required>Secondary Location State</Legend>
            <BCSelect
              width="75"
              values={values}
              options={stateOptions}
              placeholder={formSchema.secondaryLocationState.label}
              name={formSchema.secondaryLocationState.name}
              errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationState.name)}
            />
          </Fieldset>

          <BCInput
            width="75"
            label={formSchema.secondaryLocationZip.label}
            name={formSchema.secondaryLocationZip.name}
            value={values.secondaryLocationZip}
            maxlength={5}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationZip.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationCounty.label}
            name={formSchema.secondaryLocationCounty.name}
            value={values.secondaryLocationCounty}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationCounty.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationPhone.label}
            name={formSchema.secondaryLocationPhone.name}
            value={values.secondaryLocationPhone}
            hoverTitle={phoneFormatHoverTitle}
            type="tel"
            maxlength={12}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationPhone.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationBillingNPI.label}
            name={formSchema.secondaryLocationBillingNPI.name}
            value={values.secondaryLocationBillingNPI}
            maxlength={10}
            type="tel"
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationBillingNPI.name)}
          />
          <BCRadioGroup
            label={formSchema.willPatientsBeSeen.label}
            values={values}
            radios={willPatientsBeSeenRadios}
            name={formSchema.willPatientsBeSeen.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.willPatientsBeSeen.name)}
            validation={formSchema.willPatientsBeSeen.validation}
          />
        </Fieldset>
      );
    }
  };

  const renderDifferentBillingAddressInputs = () => {
    if (values.billingAddressSameAsPrimaryAddress === 'no') {
      return (
        <Fieldset>
          <Legend required>Enter your current information:</Legend>
          <BCInput
            width="75"
            label={formSchema.differentBillingAddress.label}
            name={formSchema.differentBillingAddress.name}
            value={values.differentBillingAddress}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingAddress.name)}
          />
          <BCInput
            width="75"
            label={formSchema.differentBillingSuiteAptUnitFloor.label}
            name={formSchema.differentBillingSuiteAptUnitFloor.name}
            value={values.differentBillingSuiteAptUnitFloor}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingSuiteAptUnitFloor.name)}
          />
          <BCInput
            width="75"
            label={formSchema.differentBillingCity.label}
            name={formSchema.differentBillingCity.name}
            value={values.differentBillingCity}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingCity.name)}
          />
          <Fieldset noMargin>
            <Legend required>Billing State</Legend>
            <BCSelect
              width="75"
              values={values}
              options={stateOptions}
              placeholder={formSchema.differentBillingState.label}
              name={formSchema.differentBillingState.name}
              errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingState.name)}
            />
          </Fieldset>

          <BCInput
            width="75"
            label={formSchema.differentBillingZip.label}
            name={formSchema.differentBillingZip.name}
            maxlength={5}
            value={values.differentBillingZip}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingZip.name)}
          />
          <BCInput
            width="75"
            label={formSchema.differentBillingCounty.label}
            name={formSchema.differentBillingCounty.name}
            value={values.differentBillingCounty}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingCounty.name)}
          />
          <BCInput
            width="75"
            label={formSchema.differentBillingPhone.label}
            name={formSchema.differentBillingPhone.name}
            hoverTitle={phoneFormatHoverTitle}
            value={values.differentBillingPhone}
            maxlength={12}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingPhone.name)}
          />
        </Fieldset>
      );
    }
  };
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Primary Office Location Information (Service Location)
      </bc-typography>
      <bc-global-alert variant="danger" open rounded style={{ '--radius-sm': '20px' }}>
        An issue was found impacting the PPN and PTAN Fields of our Forms. Until resolved, enter all 11111 in PPN Field
        and include your PPN and PTAN when emailing your W-9 to ProviderUpdates@bcbsnc.com.
      </bc-global-alert>
      <Fieldset>
        <Legend required>Enter your information:</Legend>
        <BCInput
          width="75"
          label={formSchema.streetAddress.label}
          name={formSchema.streetAddress.name}
          value={values.streetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.streetAddress.name)}
        />
        <BCInput
          width="75"
          label={formSchema.suiteAptUnitFloor.label}
          name={formSchema.suiteAptUnitFloor.name}
          value={values.suiteAptUnitFloor}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suiteAptUnitFloor.name)}
        />
        <BCInput
          width="75"
          label={formSchema.city.label}
          name={formSchema.city.name}
          value={values.city}
          errorMessage={generateErrorMessage(errors, touched, formSchema.city.name)}
        />
        <Fieldset noMargin>
          <Legend required>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.state.label}
            name={formSchema.state.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.state.name)}
          />
        </Fieldset>

        <BCInput
          width="75"
          label={formSchema.zip.label}
          name={formSchema.zip.name}
          maxlength={5}
          value={values.zip}
          errorMessage={generateErrorMessage(errors, touched, formSchema.zip.name)}
        />
        <BCInput
          width="75"
          label={formSchema.county.label}
          name={formSchema.county.name}
          value={values.county}
          errorMessage={generateErrorMessage(errors, touched, formSchema.county.name)}
        />
        <BCInput
          width="75"
          label={formSchema.phone.label}
          name={formSchema.phone.name}
          hoverTitle={phoneFormatHoverTitle}
          value={values.phone}
          maxlength={12}
          errorMessage={generateErrorMessage(errors, touched, formSchema.phone.name)}
        />
        <BCInput
          width="75"
          label={formSchema.practiceEmail.label}
          name={formSchema.practiceEmail.name}
          value={values.practiceEmail}
          errorMessage={generateErrorMessage(errors, touched, formSchema.practiceEmail.name)}
        />
      </Fieldset>
      <BCRadioGroup
        label={formSchema.billingAddressSameAsPrimaryAddress.label}
        values={values}
        radios={billingAddressRadios}
        name={formSchema.billingAddressSameAsPrimaryAddress.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.billingAddressSameAsPrimaryAddress.name)}
        validation={formSchema.billingAddressSameAsPrimaryAddress.validation}
      />
      {renderDifferentBillingAddressInputs()}
      <BCRadioGroup
        label={formSchema.secondaryLocation.label}
        values={values}
        radios={secondaryLocationRadios}
        name={formSchema.secondaryLocation.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocation.name)}
        validation={formSchema.secondaryLocation.validation}
      />
      {renderSecondaryLocationInputs()}
    </>
  );
}

export default Step3;
