import { useField } from 'formik';
import 'styles/textarea.scss';
import InputError from 'components/validation/InputError';

function BCTextArea({ label, errorMessage, name, value, rows, width }: any) {
  const [field] = useField(name);

  const handleBlur = (e: any) => {
    field.onBlur(e);
  };

  const handleChange = (e: any) => {
    field.onChange(e);
  };

  return (
    <div className="mt-14">
      <label htmlFor={name} className="hidden">
        {label}
      </label>
      <textarea
        className={`textarea width--${width} ${errorMessage && 'error'}`}
        name={name}
        rows={rows}
        placeholder={label}
        onInput={handleChange}
        onBlur={handleBlur}
        defaultValue={value}
        id={name}
      />
      <InputError errorMessage={errorMessage} />
    </div>
  );
}

export default BCTextArea;
