import * as Yup from 'yup';
import { getInitialValues, getYupValidationSchema } from 'utilities/miscHelpers';
import {
  dateValidationWithOffset,
  hasCompletedW9Validation,
  NPIValidation,
  optionalCLIANumberValidation,
  optionalTextNumericValidation,
  phoneValidation,
  providerGroupPreferredPPNValidation,
  servicesLocationValidation,
  taxIDValidation,
  zipValidation,
} from 'utilities/customValidators';
import { getDateFormattedForSchema, HoneypotFieldSchema } from 'utilities/formHelpers';

// TODO - should we add the radio & checkbox array inputs to the schema as well?

export const step1Schema = {
  HoneypotFieldSchema,
  step1Description: {
    label: 'Tax Information',
    emailLabel: 'Tax Information',
    initialValue: 'Tax Information',
    name: '',
    type: 'description',
  },
  haveCompletedW9: {
    emailLabel: 'Has completed and emailed W-9',
    name: 'haveCompletedW9',
    label:
      'You cannot complete your application until your W-9 form has been emailed to us. Please download, complete and email your W-9 form before continuing.',
    initialValue: [],
    validation: hasCompletedW9Validation,
  },
  providerGroupContractStatus: {
    emailLabel: "Provider Group's contract status with Blue Cross NC",
    name: 'providerGroupContractStatus',
    label: "What is your Provider Group's contract status with Blue Cross NC?",
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
};

export const step2Schema = {
  step2Description: {
    label: 'Group Information',
    emailLabel: 'Group Information',
    initialValue: 'Group Information',
    name: '',
    type: 'description',
  },
  groupType: {
    emailLabel: 'Group Type',
    name: 'groupType',
    label: 'Choose Group Type',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  facilityType: {
    emailLabel: 'Facility Type',
    name: 'facilityType',
    label: 'Select Facility Type',
    initialValue: '',
    validation: Yup.string().when('groupType', {
      is: 'facilityOrAncillary',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  groupName: {
    emailLabel: 'Group Name',
    name: 'groupName',
    label: 'Group Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  doingBusinessAs: {
    emailLabel: 'Doing Business As',
    name: 'doingBusinessAs',
    label: 'Doing Business As',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  specialty: {
    emailLabel: 'Specialty',
    name: 'specialty',
    label: 'Specialty',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  otherSpecialty: {
    emailLabel: 'Other Specialty',
    name: 'otherSpecialty',
    label: 'Other Specialty',
    initialValue: '',
    validation: Yup.string().when('specialty', {
      is: 'other',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  servicesOffered: {
    emailLabel: 'Services Offered',
    name: 'servicesOffered',
    label: 'Services Offered',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  offerTelehealthServices: {
    emailLabel: 'Offers Telehealth Services',
    name: 'offerTelehealthServices',
    label: 'Do you offer Telehealth services?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  servicesLocation: {
    emailLabel: 'Location of rendered services',
    name: 'servicesLocation',
    label: 'Where will services be rendered?',
    initialValue: [],
    validation: servicesLocationValidation,
  },
  otherServicesLocation: {
    emailLabel: 'Other location of rendered services',
    name: 'otherServicesLocation',
    label: 'Other Services Location',
    initialValue: '',
    validation: Yup.string().when('servicesLocation', {
      is: (servicesLocation: string[]) => servicesLocation.includes('otherServicesLocation'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  taxIDNumber: {
    emailLabel: 'Tax ID Number (IRS)',
    name: 'taxIDNumber',
    label: 'Tax ID Number (IRS)',
    initialValue: '',
    validation: taxIDValidation,
  },
  NPITypeII: {
    emailLabel: 'National Provider Identifier (NPI) - Type II',
    name: 'NPITypeII',
    label: 'National Provider Identifier (NPI) - Type II',
    initialValue: '',
    validation: NPIValidation,
  },
  providerGroupPreferredPPN: {
    emailLabel: "Provider Group's Blue Cross Preferred PPN",
    name: 'providerGroupPreferredPPN',
    label: "Provider Group's Blue Cross Preferred PPN",
    initialValue: '',
    validation: providerGroupPreferredPPNValidation,
  },
  groupMedicareProviderNumber: {
    emailLabel: 'Group Medicare Provider Number',
    name: 'groupMedicareProviderNumber',
    label: 'Group Medicare Provider Number',
    initialValue: '',
    validation: optionalTextNumericValidation(10),
  },
  CLIANumber: {
    emailLabel: 'CLIA Number',
    name: 'CLIANumber',
    label: 'Clinical Laboratory Improvements Act (CLIA) Number',
    initialValue: '',
    validation: optionalCLIANumberValidation,
  },
  providingServicesToMedicareMembers: {
    emailLabel: 'Will you be providing services to Commercial Members, Blue Medicare Members, or both?',
    name: 'providingServicesToMedicareMembers',
    label: 'Will you be providing services to Commercial Members, Blue Medicare Members, or both?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
};

export const step3Schema = {
  step3Description: {
    label: 'Enter your current information',
    emailLabel: 'Enter your current information',
    initialValue: 'Enter your current information',
    name: '',
    type: 'description',
  },
  streetAddress: {
    emailLabel: 'Street Address',
    name: 'streetAddress',
    label: 'Street Address',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  suiteAptUnitFloor: {
    emailLabel: 'Suite, Apt., Unit, Floor, etc.',
    name: 'suiteAptUnitFloor',
    label: 'Suite, Apt., Unit, Floor, etc.',
    initialValue: '',
    validation: null,
  },
  city: {
    emailLabel: 'City',
    name: 'city',
    label: 'City',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  state: {
    emailLabel: 'State',
    name: 'state',
    label: 'State',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  zip: {
    emailLabel: 'Zip Code',
    name: 'zip',
    label: 'ZIP Code',
    initialValue: '',
    validation: zipValidation,
  },
  county: {
    emailLabel: 'County',
    name: 'county',
    label: 'County',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  phone: {
    emailLabel: 'Phone (Appointment/Patient Phone Number)',
    name: 'phone',
    label: 'Phone (Appointment/Patient Phone Number)',
    initialValue: '',
    validation: phoneValidation,
  },
  practiceEmail: {
    emailLabel: 'Practice Email',
    name: 'practiceEmail',
    label: 'Practice Email Address',
    initialValue: '',
    validation: Yup.string().required('This field is required').email('Please enter a valid email address'),
  },
  secondaryLocation: {
    emailLabel: 'Has secondary location',
    name: 'secondaryLocation',
    label: 'Do you have a secondary location where you take patient appointments at least 2 days a week?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  secondaryLocationAddress: {
    emailLabel: 'Secondary Location Address',
    name: 'secondaryLocationAddress',
    label: 'Secondary Office Location Address',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationSuiteAptUnitFloor: {
    emailLabel: 'Secondary Location Suite, Apt., Unit, Floor, etc',
    name: 'secondaryLocationSuiteAptUnitFloor',
    label: 'Secondary Office Location Suite, Apt., Unit, Floor, etc.',
    initialValue: '',
    validation: null,
  },
  secondaryLocationCity: {
    emailLabel: 'Secondary Location City',
    name: 'secondaryLocationCity',
    label: 'Secondary Office Location City',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationState: {
    emailLabel: 'Secondary Location State',
    name: 'secondaryLocationState',
    label: 'Secondary Office Location State',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationZip: {
    emailLabel: 'Secondary Location Zip Code',
    name: 'secondaryLocationZip',
    label: 'Secondary Office Location Zip Code',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => zipValidation,
    }),
  },
  secondaryLocationCounty: {
    emailLabel: 'Secondary Location County',
    name: 'secondaryLocationCounty',
    label: 'Secondary Office Location County',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationPhone: {
    emailLabel: 'Secondary Location Phone',
    name: 'secondaryLocationPhone',
    label: 'Secondary Office Location Phone Number',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => phoneValidation,
    }),
  },
  secondaryLocationBillingNPI: {
    emailLabel: 'Secondary Location Billing NPI',
    name: 'secondaryLocationBillingNPI',
    label: 'Secondary Office Location Billing NPI',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => NPIValidation,
    }),
  },
  willPatientsBeSeen: {
    emailLabel: 'Patients will be seen 2 or more days a week',
    name: 'willPatientsBeSeen',
    label: 'Will patients be seen at this location for appointments 2 or more days a week?',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  billingAddressSameAsPrimaryAddress: {
    emailLabel: 'Billing address is same as primary address above',
    name: 'billingAddressSameAsPrimaryAddress',
    label: 'Is your billing address the same as your primary address above?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  differentBillingAddress: {
    emailLabel: 'Different Billing Address',
    name: 'differentBillingAddress',
    label: 'Billing Street Address',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  differentBillingSuiteAptUnitFloor: {
    emailLabel: 'Different Billing Suite, Apt., Unit, Floor, etc.',
    name: 'differentBillingSuiteAptUnitFloor',
    label: 'Billing Suite, Apt., Unit, Floor, etc.',
    initialValue: '',
    validation: null,
  },
  differentBillingCity: {
    emailLabel: 'Different Billing City',
    name: 'differentBillingCity',
    label: 'Billing City',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  differentBillingState: {
    emailLabel: 'Different Billing State',
    name: 'differentBillingState',
    label: 'Billing State',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  differentBillingZip: {
    emailLabel: 'Different Billing Zip Code',
    name: 'differentBillingZip',
    label: 'Billing Zip Code',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => zipValidation,
    }),
  },
  differentBillingCounty: {
    emailLabel: 'Different Billing County',
    name: 'differentBillingCounty',
    label: 'Billing County',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  differentBillingPhone: {
    emailLabel: 'Different Billing Phone',
    name: 'differentBillingPhone',
    label: 'Billing Contact Phone Number',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => phoneValidation,
    }),
  },
};

export const step4Schema = {
  step4Description: {
    label: 'Practitioner Information',
    emailLabel: 'Practitioner Information',
    initialValue: 'Practitioner Information',
    name: '',
    type: 'description',
  },
  numberOfPractitioners: {
    emailLabel: 'Number of practitioners',
    name: 'numberOfPractitioners',
    label: 'How many practitioners are within your group?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  firstPractitionerLastName: {
    emailLabel: 'First Practitioner Last Name',
    name: 'firstPractitionerLastName',
    label: 'Last Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  firstPractitionerFirstName: {
    emailLabel: 'First Practitioner First Name',
    name: 'firstPractitionerFirstName',
    label: 'First Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  firstPractitionerMiddleName: {
    emailLabel: 'First Practitioner Middle Name',
    name: 'firstPractitionerMiddleName',
    label: 'Middle Name',
    initialValue: '',
    validation: null,
  },
  firstPractitionerLicenseNumber: {
    emailLabel: 'First Practitioner License Number',
    name: 'firstPractitionerLicenseNumber',
    label: 'License Number',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  firstPractitionerProviderPPN: {
    emailLabel: 'First Practitioner Provider PPN',
    name: 'firstPractitionerProviderPPN',
    label: 'Provider PPN',
    initialValue: '',
    validation: providerGroupPreferredPPNValidation,
  },
  firstPractitionerYearOfLicensure: {
    emailLabel: 'First Practitioner Year of Licensure',
    name: 'firstPractitionerYearOfLicensure',
    label: 'Year of Licensure',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  firstPractitionerSpecialty: {
    emailLabel: 'First Practitioner Specialty',
    name: 'firstPractitionerSpecialty',
    label: 'Specialty',
    initialValue: '',
    validation: null,
  },
  firstPractitionerOtherSpecialty: {
    emailLabel: 'First Practitioner Other Specialty',
    name: 'firstPractitionerOtherSpecialty',
    label: 'Other Specialty',
    initialValue: '',
    validation: Yup.string().when('firstPractitionerSpecialty', {
      is: 'other',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  firstPractitionerNPI: {
    emailLabel: 'First Practitioner NPI',
    name: 'firstPractitionerNPI',
    label: 'National Provider Identifier (NPI)',
    initialValue: '',
    validation: NPIValidation,
  },
  addSecondPractitioner: {
    emailLabel: 'Add second practitioner',
    name: 'addSecondPractitioner',
    label: 'Add a second practitioner',
    initialValue: [],
    validation: null,
  },
  secondPractitionerLastName: {
    emailLabel: 'Second Practitioner Last Name',
    name: 'secondPractitionerLastName',
    label: 'Last Name)',
    initialValue: '',
    validation: Yup.string().when('addSecondPractitioner', {
      is: (addSecondPractitioner: string[]) => addSecondPractitioner.includes('addSecondPractitioner'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondPractitionerFirstName: {
    emailLabel: 'Second Practitioner First Name',
    name: 'secondPractitionerFirstName',
    label: 'First Name',
    initialValue: '',
    validation: Yup.string().when('addSecondPractitioner', {
      is: (addSecondPractitioner: string[]) => addSecondPractitioner.includes('addSecondPractitioner'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondPractitionerMiddleName: {
    emailLabel: 'Second Practitioner Middle Name',
    name: 'secondPractitionerMiddleName',
    label: 'Middle Name',
    initialValue: '',
    validation: null,
  },
  secondPractitionerLicenseNumber: {
    emailLabel: 'Second Practitioner License Number',
    name: 'secondPractitionerLicenseNumber',
    label: 'License Number',
    initialValue: '',
    validation: Yup.string().when('addSecondPractitioner', {
      is: (addSecondPractitioner: string[]) => addSecondPractitioner.includes('addSecondPractitioner'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondPractitionerProviderPPN: {
    emailLabel: 'Second Practitioner Provider PPN',
    name: 'secondPractitionerProviderPPN',
    label: 'Provider PPN',
    initialValue: '',
    validation: Yup.string().when('addSecondPractitioner', {
      is: (addSecondPractitioner: string[]) => addSecondPractitioner.includes('addSecondPractitioner'),
      then: () => providerGroupPreferredPPNValidation,
    }),
  },
  secondPractitionerYearOfLicensure: {
    emailLabel: 'Second Practitioner Year of Licensure',
    name: 'secondPractitionerYearOfLicensure',
    label: 'Year of Licensure',
    initialValue: '',
    validation: Yup.string().when('addSecondPractitioner', {
      is: (addSecondPractitioner: string[]) => addSecondPractitioner.includes('addSecondPractitioner'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondPractitionerSpecialty: {
    emailLabel: 'Second Practitioner Specialty',
    name: 'secondPractitionerSpecialty',
    label: 'Specialty',
    initialValue: '',
    validation: null,
  },
  secondPractitionerOtherSpecialty: {
    emailLabel: 'Second Practitioner Other Specialty',
    name: 'secondPractitionerOtherSpecialty',
    label: 'Other Specialty',
    initialValue: '',
    validation: Yup.string().when(['addSecondPractitioner', 'secondPractitionerSpecialty'], {
      is: (addSecondPractitioner: string[], secondPractitionerSpecialty: string) =>
        addSecondPractitioner.includes('addSecondPractitioner') && secondPractitionerSpecialty === 'other',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondPractitionerNPI: {
    emailLabel: 'Second Practitioner NPI',
    name: 'secondPractitionerNPI',
    label: 'National Provider Identifier (NPI)',
    initialValue: '',
    validation: Yup.string().when('addSecondPractitioner', {
      is: (addSecondPractitioner: string[]) => addSecondPractitioner.includes('addSecondPractitioner'),
      then: () => NPIValidation,
    }),
  },
};

export const step5Schema = {
  step5Description: {
    label: 'Enroll - Authorized Signature',
    emailLabel: 'Enroll - Authorized Signature',
    initialValue: 'Enroll - Authorized Signature',
    name: '',
    type: 'description',
  },
  digitalSignature: {
    emailLabel: 'Digital Signature',
    name: 'digitalSignature',
    label: 'Digital Signature of Authorized Practice Representative',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  date: {
    emailLabel: 'Date',
    name: 'date',
    label: 'Date',
    initialValue: '',
    validation: dateValidationWithOffset(60),
    min: getDateFormattedForSchema(),
    max: getDateFormattedForSchema(60),
  },
  contactPhone: {
    emailLabel: 'Contact Phone',
    name: 'contactPhone',
    label: 'Contact Phone Number',
    initialValue: '',
    validation: phoneValidation,
  },
  additionalNotes: {
    emailLabel: 'Additional Notes',
    name: 'additionalNotes',
    label: 'Additional Notes',
    initialValue: '',
    validation: null,
  },
};

const step1Values = getInitialValues(step1Schema);

const step2Values = getInitialValues(step2Schema);

const step3Values = getInitialValues(step3Schema);

const step4Values = getInitialValues(step4Schema);

const step5Values = getInitialValues(step5Schema);

export const providerGroupEnrollmentSchema = {
  ...step1Values,
  ...step2Values,
  ...step3Values,
  ...step4Values,
  ...step5Values,
};

export const formSchema = {
  ...step1Schema,
  ...step2Schema,
  ...step3Schema,
  ...step4Schema,
  ...step5Schema,
};

export const ProviderGroupStep1Validation = getYupValidationSchema(step1Schema);

export const ProviderGroupStep2Validation = getYupValidationSchema(step2Schema);

export const ProviderGroupStep3Validation = getYupValidationSchema(step3Schema);

export const ProviderGroupStep4Validation = getYupValidationSchema(step4Schema);

export const ProviderGroupStep5Validation = getYupValidationSchema(step5Schema);
