import { Form } from 'formik';
import BCInput from 'components/custom-fields/BCInput';
import { generateErrorMessage } from 'utilities/miscHelpers';
import SubmitContainer from 'components/SubmitContainer';
import BCTextArea from 'components/custom-fields/BCTextArea';
import BCSelect from 'components/custom-fields/BCSelect';
import { chooseDayOptions, chooseTimeOptions } from 'constants/formData';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';
import { phoneFormatHoverTitle } from 'constants/hoverTitles';
import { useEffect, useRef } from 'react';
import { registerBcAnalyticsEventListenerStartForm } from 'utilities/eventHelpers';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { formSchema, validationSchema } from './schema';

function FormContent({ values, errors, touched }: any) {
  const ref = useRef();

  // NOTE - attaching eventListener, rather than using onFocus prop, because we need to ensure it is only fired once
  useEffect(() => {
    registerBcAnalyticsEventListenerStartForm(ref, BcncAnalyticsEventFormNames.NurseAdvocateContact);
  }, []);
  return (
    <Form>
      <Fieldset>
        <Legend required>Enter your information</Legend>
        <BCInput
          label={formSchema.HoneypotFieldSchema.label}
          name={formSchema.HoneypotFieldSchema.name}
          width="75"
          tabIndex="-500"
          value={values.HoneypotFieldSchema}
        />
        <BCInput
          label={formSchema.firstName.label}
          width="75"
          name={formSchema.firstName.name}
          value={values.firstName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.firstName.name)}
          // passing ref in order to attach Bcanalytics event listener to FIRST input on page
          ref={ref}
        />
        <BCInput
          label={formSchema.lastName.label}
          width="75"
          name={formSchema.lastName.name}
          value={values.lastName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.lastName.name)}
        />
        <BCInput
          label={formSchema.memberID.label}
          width="75"
          name={formSchema.memberID.name}
          value={values.memberID}
          errorMessage={generateErrorMessage(errors, touched, formSchema.memberID.name)}
        />
        <BCInput
          label={formSchema.email.label}
          width="75"
          type="email"
          name={formSchema.email.name}
          value={values.email}
          errorMessage={generateErrorMessage(errors, touched, formSchema.email.name)}
        />
        <BCInput
          label={formSchema.daytimePhone.label}
          width="75"
          maxlength={12}
          name={formSchema.daytimePhone.name}
          hoverTitle={phoneFormatHoverTitle}
          value={values.daytimePhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.daytimePhone.name)}
        />
        <BCTextArea
          value={values.specificDetails}
          label={formSchema.specificDetails.label}
          name={formSchema.specificDetails.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.specificDetails.name)}
          rows="4"
          width="75"
        />
      </Fieldset>
      <Fieldset>
        <Legend required>Best day to call</Legend>
        <BCSelect
          width="75"
          values={values}
          options={chooseDayOptions}
          placeholder={formSchema.chooseDay.label}
          name={formSchema.chooseDay.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.chooseDay.name)}
        />
      </Fieldset>
      <Fieldset>
        <Legend required>Best time to call</Legend>
        <BCSelect
          width="75"
          values={values}
          options={chooseTimeOptions}
          placeholder={formSchema.chooseTime.label}
          name={formSchema.chooseTime.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.chooseTime.name)}
        />
      </Fieldset>
      <SubmitContainer
        values={values}
        validationSchema={validationSchema}
        formName={BcncAnalyticsEventFormNames.NurseAdvocateContact}
      />
    </Form>
  );
}

export default FormContent;
