import { useField } from 'formik';
import 'styles/radio.scss';
import InputError from 'components/validation/InputError';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';

function BCRadioGroup({ values, label, radios, name, errorMessage, validation }: any) {
  const [field] = useField(name);

  const updateField = (e: any) => {
    field.onChange(e);
  };

  const getRadios = () => {
    return radios.map((radio: any) => (
      <div className="container" key={radio.id}>
        <div className="radio">
          <input
            type="radio"
            className="radio__input"
            id={radio.id}
            value={radio.value}
            name={name}
            onChange={updateField}
            checked={values[name] === radio.value}
          />
          <div className="radio__input--filled" />
        </div>
        <label className="radio__label" htmlFor={radio.id}>
          {radio.label}
        </label>
      </div>
    ));
  };

  return (
    <Fieldset>
      <Legend required={validation} gutterBottom>
        {label}
      </Legend>
      <InputError errorMessage={errorMessage} />
      {getRadios()}
    </Fieldset>
  );
}

export default BCRadioGroup;
