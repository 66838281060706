import { Dispatch } from 'react';
import { Action } from 'constants/store';
import { BcncAnalyticsEventActions } from 'types/BcncAnalyticsTypes';
import { fireBcncAnalyticsEvent } from 'utilities/eventHelpers';
import { FieldStructureForEmailType } from 'types/FormTypes';

const getCurrentDate = (): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const newDate = new Date();

  return `${months[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`;
};

export const getDateFormattedForSchema = (daysOffset = 0): string => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + daysOffset);
  return date.toISOString();
};

// set solo or group NPI to whichever user selected from radio and entered in input
const getSoloAndGroupNPI = (values: any): { soloNPI: string; groupNPI: string } => {
  let soloNPI = '';
  let groupNPI = '';

  if (values.NPI === 'group') {
    groupNPI = values.NPINumber;
  } else {
    soloNPI = values.NPINumber;
  }

  return { soloNPI, groupNPI };
};

// convert values to URLSearchParams (to match x-www-form-urlencoded data)
const getUrlEncodedValues = (values: any): any => {
  const urlEncodedValues = new URLSearchParams();

  Object.keys(values).forEach((key) => urlEncodedValues.append(key, values[key]));

  return urlEncodedValues;
};

// transform the form data to match whats expected in Salesforce
export const getModelCareAttestationData = (values: any, referenceCode: string): any => {
  const { soloNPI, groupNPI } = getSoloAndGroupNPI(values);

  // rewrite some field names to match field names in Salesforce
  const updatedValues = {
    oid: values.oid,
    recordType: values.recordtype,
    lead_source: values.lead_source,
    source_system: values.source_system,
    '00N6g00000TMeUL': referenceCode,
    first_name: values.your_first_name,
    last_name: values.your_last_name,
    company: values.company,
    '00N6g00000TMdYv': values.tax_id,
    email: values.provider_email_address,
    phone: values.phone,
    '00N6g00000TMidI': soloNPI,
    '00N6g00000U9evN': groupNPI,
    '00N6g00000U9evL': values.additional_group_npi,
    additional_group_npi_yn: values.additional_group_npi_yn,
  };

  return getUrlEncodedValues(updatedValues);
};

export const getEmailData = (values: any, formSchema: any, referenceCode: string): FieldStructureForEmailType[] => {
  const emailData = Object.keys(values).map((key) => ({
    // @ts-ignore
    fieldName: key,
    label: formSchema[key].emailLabel,
    value: values[key],
  }));

  // return email data with referenceCode
  return [...emailData, { label: 'Reference Code', value: referenceCode, fieldName: 'ReferenceCode' }];
};

// dispatches to global store to set up values for displaying Success page
const handleFormSubmissionSuccess = (dispatch: Dispatch<any>, referenceCode: string | undefined) => {
  dispatch({
    type: Action.UPDATE_FORM_SUBMITTED_SUCCESSFULLY,
    formSubmittedSuccessfully: true,
  });
  dispatch({
    type: Action.UPDATE_FORM_SUBMITTED_FAILURE,
    formSubmittedSuccessfully: false,
  });
  // TODO - remove currentDate and referenceCode if not needed?
  dispatch({
    type: Action.UPDATE_SUBMISSION_DATE,
    submissionDate: getCurrentDate(),
  });
  dispatch({
    type: Action.UPDATE_REFERENCE_CODE,
    referenceCode,
  });
};

// dispatches to global store to set up values for displaying Fail page
const handleFormSubmissionFailure = (dispatch: Dispatch<any>): void => {
  dispatch({
    type: Action.UPDATE_FORM_SUBMITTED_FAILURE,
    formSubmittedFailure: true,
  });
  dispatch({
    type: Action.UPDATE_FORM_SUBMITTED_SUCCESSFULLY,
    formSubmittedSuccessfully: false,
  });
};

// calls appropriate handlers and analytics event based on success or fail
export const handleFormSubmission = (
  formName: string,
  response: { success: boolean; errorMessage: string | null },
  dispatch: Dispatch<any>,
  referenceCode?: string,
): void => {
  if (response.success) {
    handleFormSubmissionSuccess(dispatch as Dispatch<any>, referenceCode);

    fireBcncAnalyticsEvent(BcncAnalyticsEventActions.FormSubmitSuccess, formName);
  } else {
    handleFormSubmissionFailure(dispatch as Dispatch<any>);

    fireBcncAnalyticsEvent(BcncAnalyticsEventActions.FormSubmitError, formName, response.errorMessage as string);
  }
};

export const HoneypotFieldSchema = {
  label: 'Nickname',
  emailLabel: 'Nickname',
  initialValue: '',
  name: 'nickname',
  type: 'honeypot',
};
