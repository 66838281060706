import BCCheckboxGroup from 'components/custom-fields/BCCheckboxGroup';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import { haveCompletedW9Options, providerGroupContractStatusRadios } from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import {
  CAQH_LINK,
  CREDENTIALED_BCBSNC_FACILITY_LINK,
  MAIL_TO_PROV_REQUESTS_SUBJECT_LINK,
  W9_LINK,
} from 'constants/links';
import { formSchema } from 'forms/providerGroupEnrollmentForm/schema';
import BCInput from 'components/custom-fields/BCInput';

function Step1({ values, errors, touched }: any) {
  const renderNoActiveContractInfo = () => {
    if (values.providerGroupContractStatus === 'noActiveContractYet') {
      return (
        <>
          <bc-typography weight="bold">
            Your enrollment application will not be processed if you have not completed your credentialing with Blue
            Cross NC.
          </bc-typography>
          <bc-typography>
            Provider Credentialing:{' '}
            <a href={CAQH_LINK} target="_blank" rel="noreferrer">
              Start the CAQH ProView Application Process
            </a>
          </bc-typography>
          <bc-typography>
            Facility Credentialing:{' '}
            <a href={CREDENTIALED_BCBSNC_FACILITY_LINK} target="_blank" rel="noreferrer">
              Become a Credentialed Blue Cross NC Facility
            </a>
          </bc-typography>
        </>
      );
    }
  };
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        {formSchema.step1Description.label}
      </bc-typography>
      <bc-global-alert variant="danger" open rounded style={{ '--radius-sm': '20px' }}>
        An issue was found impacting the PPN and PTAN Fields of our Forms. Until resolved, enter all 11111 in PPN Field
        and include your PPN and PTAN when emailing your W-9 to ProviderUpdates@bcbsnc.com.
      </bc-global-alert>
      <bc-typography>
        To ensure compliance with the Internal Revenue Service (IRS) regulations, we must have your tax identification
        information to process your application. When submitting this enrollment application, please be sure to include
        a completed W-9 containing the billing entity information. Enrollment applications without a completed W-9 Form
        will not be processed.
      </bc-typography>
      <div className="blue-card">
        <bc-typography weight="bold">Steps to send your W-9 Form:</bc-typography>
        <bc-list type="ol">
          <li>
            Download the{' '}
            <a href={W9_LINK} target="_blank" rel="noreferrer">
              {' '}
              W-9 Form
            </a>{' '}
            and complete it in its entirety.
          </li>
          <li>
            Email the completed W-9 form to{' '}
            <a className="link" href={MAIL_TO_PROV_REQUESTS_SUBJECT_LINK} target="_blank" rel="noreferrer">
              ProvRequests@bcbsnc.com
            </a>
          </li>
          <li>
            Include the following information in the email subject line:
            <bc-list type="ul">
              <li>Provider Group W-9 Form</li>
              <li>Provider Group Name</li>
              <li>National Provider Identifier (NPI) Type II Number</li>
            </bc-list>
          </li>
        </bc-list>
        <bc-typography>
          <i>Example: Provider Group W9 | Provider Group Name | NPI Type II</i>
        </bc-typography>
      </div>
      <BCInput
        label={formSchema.HoneypotFieldSchema.label}
        name={formSchema.HoneypotFieldSchema.name}
        width="75"
        tabIndex="-500"
        value={values.HoneypotFieldSchema}
      />
      <BCCheckboxGroup
        label={formSchema.haveCompletedW9.label}
        checkboxes={haveCompletedW9Options}
        values={values}
        name={formSchema.haveCompletedW9.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.haveCompletedW9.name)}
        validation={formSchema.haveCompletedW9.validation}
      />
      <BCRadioGroup
        label={formSchema.providerGroupContractStatus.label}
        values={values}
        radios={providerGroupContractStatusRadios}
        name={formSchema.providerGroupContractStatus.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.providerGroupContractStatus.name)}
        validation={formSchema.providerGroupContractStatus.validation}
      />
      {renderNoActiveContractInfo()}
    </>
  );
}

export default Step1;
