// TODO - type this
import { EmailDataType } from '../types/EmailTypes';

const getEmailRequestOptions = (data: any, recipient: string, sender: string, subject: string): any => ({
  method: 'POST',
  headers: {
    // TODO - not needed until we use API Gateway
    // 'x-api-key': process.env.REACT_APP_X_API_KEY,
    'Content-Type': 'application/json',
  },
  mode: 'no-cors',
  redirect: 'follow',
  body: JSON.stringify({
    to: recipient,
    from: sender,
    subject,
    data,
  }),
});

const getSalesforceRequestOptions = (data: any): any => ({
  method: 'POST',
  mode: 'no-cors',
  body: data,
  redirect: 'follow',
});

const makeAPIcall = async (endpoint: string, requestOptions: any) => {
  try {
    const response = await fetch(endpoint, requestOptions);

    // NOTE: getting back opaque response when using no-cors, which means response status = 0 for success; if we can get this to work with cors, will change 0 to 200
    if (response.status === 0) {
      return { success: true, errorMessage: null };
    }

    return {
      success: false,
      errorMessage: `${response.status} Error - Something went wrong...`,
    };
  } catch (error) {
    console.log(error);

    return {
      success: false,
      errorMessage: `500 Error - Something went wrong...`,
    };
  }
};

export const sendEmail = async (
  data: EmailDataType,
  recipient: string,
  sender: string,
  subject: string,
): Promise<{ success: boolean; errorMessage: string | null }> => {
  const endpoint = process.env.REACT_APP_EMAILER_LAMBDA_ENDPOINT;
  const requestOptions = getEmailRequestOptions(data, recipient, sender, subject);

  // HACK: handle scenario where data is not being passed to emailer lambda, skip sending an email completely
  // check that at least 5 fields have been filled out
  // all of the "empty" submissions have only 4 fields filled out, ie. they somehow submit between step 1 and 2
  // all of the forms we have here, have at least 5 required fields, so this should be a safe check
  // the emailer lambda does not do any backend validation of the form fields, so it doesn't know what's required
  // still need to reproduce empty submissions
  // we don't know if it is a bot or a user
  // TODO: AEM-156 - validate final form submission against the schema
  if (Object.values(data.values).filter((value) => ![[], ''].includes(value)).length < 5)
    return {
      success: false,
      errorMessage: `500 Error - Something went wrong...`,
    };

  // @ts-ignore
  return makeAPIcall(endpoint, requestOptions);
};

export const sendDataToSalesforce = async (data: any): Promise<{ success: boolean; errorMessage: string | null }> => {
  const endpoint = process.env.REACT_APP_SALESFORCE_ENDPOINT;
  const requestOptions = getSalesforceRequestOptions(data);

  // @ts-ignore
  return makeAPIcall(endpoint, requestOptions);
};
