import * as Yup from 'yup';
import { getInitialValues, getYupValidationSchema } from 'utilities/miscHelpers';
import { phoneValidation } from 'utilities/customValidators';
import { HoneypotFieldSchema } from 'utilities/formHelpers';

export const formSchema = {
  HoneypotFieldSchema,
  step1Description: {
    label: 'Nurse Advocate Contact Form',
    emailLabel: 'Nurse Advocate Contact Form',
    initialValue: 'Nurse Advocate Contact Form',
    name: '',
    type: 'description',
  },
  firstName: {
    emailLabel: 'First Name',
    name: 'firstName',
    label: 'First name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  lastName: {
    emailLabel: 'Last Name',
    name: 'lastName',
    label: 'First name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  memberID: {
    emailLabel: 'Member ID',
    name: 'memberID',
    label: 'Member ID',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  email: {
    emailLabel: 'Email',
    name: 'email',
    label: 'Email',
    initialValue: '',
    validation: Yup.string().required('This field is required').email('Please enter a valid email address'),
  },
  daytimePhone: {
    emailLabel: 'Daytime Phone',
    name: 'daytimePhone',
    label: 'Daytime phone number',
    initialValue: '',
    validation: phoneValidation,
  },
  specificDetails: {
    emailLabel: 'Specific details we should know',
    name: 'specificDetails',
    label: "Anything specific you'd like us to know?",
    initialValue: '',
    validation: null,
  },
  chooseDay: {
    emailLabel: 'Best day to call',
    name: 'chooseDay',
    label: 'Choose a day',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  chooseTime: {
    emailLabel: 'Best time to call',
    name: 'chooseTime',
    label: 'Choose a time',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
};

export const initialValues = getInitialValues(formSchema);

export const validationSchema = getYupValidationSchema(formSchema);
