import { Form } from 'formik';
import BCInput from 'components/custom-fields/BCInput';
import { generateErrorMessage } from 'utilities/miscHelpers';
import SubmitContainer from 'components/SubmitContainer';
import Fieldset from 'components/Fieldset';
import BCTextArea from 'components/custom-fields/BCTextArea';
import BCSelect from 'components/custom-fields/BCSelect';
import { stateOptions } from 'constants/formData';
import Legend from 'components/Legend';
import { phoneFormatHoverTitle } from 'constants/hoverTitles';
import { useEffect, useRef } from 'react';
import { registerBcAnalyticsEventListenerStartForm } from 'utilities/eventHelpers';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { formSchema, validationSchema } from './schema';

function FormContent({ values, errors, touched }: any) {
  const ref = useRef();

  // NOTE - attaching eventListener, rather than using onFocus prop, because we need to ensure it is only fired once
  useEffect(() => {
    registerBcAnalyticsEventListenerStartForm(ref, BcncAnalyticsEventFormNames.FraudAndAbuse);
  }, []);
  return (
    <Form>
      <Fieldset>
        <Legend>Your Information. This section is optional — you may choose to remain anonymous.</Legend>
        <BCInput
          label={formSchema.HoneypotFieldSchema.label}
          name={formSchema.HoneypotFieldSchema.name}
          width="75"
          tabIndex="-500"
          value={values.HoneypotFieldSchema}
        />
        <BCInput
          label={formSchema.reporterFirstName.label}
          width="75"
          name={formSchema.reporterFirstName.name}
          value={values.reporterFirstName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterFirstName.name)}
        />
        <BCInput
          label={formSchema.reporterLastName.label}
          width="75"
          name={formSchema.reporterLastName.name}
          value={values.reporterLastName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterLastName.name)}
        />
        <BCInput
          label={formSchema.reporterStreetAddress.label}
          width="75"
          name={formSchema.reporterStreetAddress.name}
          value={values.reporterStreetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterStreetAddress.name)}
        />
        <BCInput
          label={formSchema.reporterCity.label}
          width="75"
          name={formSchema.reporterCity.name}
          value={values.reporterCity}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterCity.name)}
        />
        <Fieldset noMargin>
          <Legend>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.reporterState.label}
            name={formSchema.reporterState.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.reporterState.name)}
          />
        </Fieldset>
        <BCInput
          label={formSchema.reporterZIP.label}
          width="75"
          name={formSchema.reporterZIP.name}
          maxlength={5}
          value={values.reporterZIP}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterZIP.name)}
        />
        <BCInput
          label={formSchema.reporterEmail.label}
          width="75"
          type="email"
          name={formSchema.reporterEmail.name}
          value={values.reporterEmail}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterEmail.name)}
        />
        <BCInput
          label={formSchema.reporterPhone.label}
          width="75"
          maxlength={12}
          name={formSchema.reporterPhone.name}
          hoverTitle={phoneFormatHoverTitle}
          value={values.reporterPhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterPhone.name)}
        />
        <BCInput
          label={formSchema.reporterEveningPhone.label}
          width="75"
          maxlength={12}
          name={formSchema.reporterEveningPhone.name}
          hoverTitle={phoneFormatHoverTitle}
          value={values.reporterEveningPhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.reporterEveningPhone.name)}
        />
      </Fieldset>
      <Fieldset>
        <Legend>Insured's Information: (Person who carries the insurance)</Legend>
        <BCInput
          label={formSchema.insuredFirstName.label}
          width="75"
          name={formSchema.insuredFirstName.name}
          value={values.insuredFirstName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.insuredFirstName.name)}
        />
        <BCInput
          label={formSchema.insuredLastName.label}
          width="75"
          name={formSchema.insuredLastName.name}
          value={values.insuredLastName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.insuredLastName.name)}
        />
        <BCInput
          label={formSchema.insuredMemberIDNumber.label}
          width="75"
          name={formSchema.insuredMemberIDNumber.name}
          value={values.insuredMemberIDNumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.insuredMemberIDNumber.name)}
        />
        <BCInput
          label={formSchema.insuredStreetAddress.label}
          width="75"
          name={formSchema.insuredStreetAddress.name}
          value={values.insuredStreetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.insuredStreetAddress.name)}
        />
        <BCInput
          label={formSchema.insuredCity.label}
          width="75"
          name={formSchema.insuredCity.name}
          value={values.insuredCity}
          errorMessage={generateErrorMessage(errors, touched, formSchema.insuredCity.name)}
        />
        <Fieldset noMargin>
          <Legend>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.insuredState.label}
            name={formSchema.insuredState.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.insuredState.name)}
          />
        </Fieldset>
        <BCInput
          label={formSchema.insuredZIP.label}
          width="75"
          name={formSchema.insuredZIP.name}
          maxlength={5}
          value={values.insuredZIP}
          errorMessage={generateErrorMessage(errors, touched, formSchema.insuredZIP.name)}
        />
        <BCInput
          label={formSchema.insuredPhone.label}
          width="75"
          maxlength={12}
          name={formSchema.insuredPhone.name}
          hoverTitle={phoneFormatHoverTitle}
          value={values.insuredPhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.insuredPhone.name)}
        />
      </Fieldset>
      <Fieldset>
        <Legend required>Person or Company Suspected of Fraud or Abuse</Legend>
        <BCInput
          label={formSchema.suspectName.label}
          width="75"
          name={formSchema.suspectName.name}
          value={values.suspectName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suspectName.name)}
          // passing ref in order to attach Bcanalytics event listener to FIRST input on page
          ref={ref}
        />
        <BCInput
          label={formSchema.suspectStreetAddress.label}
          width="75"
          name={formSchema.suspectStreetAddress.name}
          value={values.suspectStreetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suspectStreetAddress.name)}
        />
        <BCInput
          label={formSchema.suspectCity.label}
          width="75"
          name={formSchema.suspectCity.name}
          value={values.suspectCity}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suspectCity.name)}
        />
        <Fieldset noMargin>
          <Legend required>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.suspectState.label}
            name={formSchema.suspectState.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.suspectState.name)}
          />
        </Fieldset>
        <BCInput
          label={formSchema.suspectZIP.label}
          width="75"
          name={formSchema.suspectZIP.name}
          maxlength={5}
          value={values.suspectZIP}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suspectZIP.name)}
        />
        <BCInput
          label={formSchema.suspectPhone.label}
          width="75"
          name={formSchema.suspectPhone.name}
          value={values.suspectPhone}
          maxlength={12}
          hoverTitle={phoneFormatHoverTitle}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suspectPhone.name)}
        />
        <BCInput
          label={formSchema.dateOfIncident.label}
          width="75"
          type="date"
          name={formSchema.dateOfIncident.name}
          value={values.dateOfIncident}
          errorMessage={generateErrorMessage(errors, touched, formSchema.dateOfIncident.name)}
        />
        <BCTextArea
          value={values.description}
          label={formSchema.description.label}
          name={formSchema.description.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.description.name)}
          rows="8"
          width="75"
        />
        <input type="hidden" name={formSchema.external.name} value={formSchema.external.initialValue} />
      </Fieldset>
      <SubmitContainer
        formName={BcncAnalyticsEventFormNames.FraudAndAbuse}
        values={values}
        validationSchema={validationSchema}
      />
    </Form>
  );
}

export default FormContent;
