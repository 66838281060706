// NOTE: Step0 = intro page (no form elements)

import { INDIVIDUAL_PRACTITIONER_APPLICATION_LINK } from 'constants/links';

function Step0() {
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Join our network
      </bc-typography>
      <bc-typography>
        Thank you for your interest in joining Blue Cross and Blue Shield of North Carolina (Blue Cross NC)’s growing
        network of physicians, health care professionals and facilities who share our commitment to improving the health
        and well-being of our members and communities.
      </bc-typography>
      <bc-typography>The first step is to enroll your provider group!</bc-typography>
      <div className="blue-card">
        <bc-typography weight="bold">Important Tips:</bc-typography>
        <bc-list type="ol">
          <li>All individual practitioners added to the group must already be enrolled with Blue Cross NC.</li>
          <li>
            If the practitioner is not already enrolled with Blue Cross NC, complete the{' '}
            <a href={INDIVIDUAL_PRACTITIONER_APPLICATION_LINK} target="_blank" rel="noreferrer">
              Individual Practitioner Enrollment Application.
            </a>
          </li>
        </bc-list>
      </div>
    </>
  );
}

export default Step0;
