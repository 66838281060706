import BCCheckboxGroup from 'components/custom-fields/BCCheckboxGroup';
import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import { numberOfPractitionersRadios, primarySpecialtyOptions } from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerGroupEnrollmentForm/schema';
import { INDIVIDUAL_PRACTITIONER_APPLICATION_LINK, PROVIDER_FORMS_DOCS_LINK } from 'constants/links';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';
import { ReactElement } from 'react';

function Step4({ values, errors, touched }: any) {
  const renderFirstPractitionerOtherSpecialtyInput = () => {
    if (values.firstPractitionerSpecialty?.includes('other')) {
      return (
        <BCInput
          width="75"
          label={formSchema.firstPractitionerOtherSpecialty.label}
          name={formSchema.firstPractitionerOtherSpecialty.name}
          value={values.firstPractitionerOtherSpecialty}
          errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerOtherSpecialty.name)}
        />
      );
    }
  };

  const renderSecondPractitionerOtherSpecialtyInput = () => {
    if (values.firstPractitionerSpecialty?.includes('other')) {
      return (
        <BCInput
          width="75"
          label={formSchema.secondPractitionerOtherSpecialty.label}
          name={formSchema.secondPractitionerOtherSpecialty.name}
          value={values.secondPractitionerOtherSpecialty}
          errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerOtherSpecialty.name)}
        />
      );
    }
  };

  const renderSecondPractitionerInputs = () => {
    if (values.addSecondPractitioner?.includes('addSecondPractitioner')) {
      return (
        <Fieldset>
          <Legend>Second practitioner</Legend>
          <BCInput
            width="75"
            label={formSchema.secondPractitionerLastName.label}
            name={formSchema.secondPractitionerLastName.name}
            value={values.secondPractitionerLastName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerLastName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondPractitionerFirstName.label}
            name={formSchema.secondPractitionerFirstName.name}
            value={values.secondPractitionerFirstName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerFirstName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondPractitionerMiddleName.label}
            name={formSchema.secondPractitionerMiddleName.name}
            value={values.secondPractitionerMiddleName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerMiddleName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondPractitionerLicenseNumber.label}
            name={formSchema.secondPractitionerLicenseNumber.name}
            value={values.secondPractitionerLicenseNumber}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerLicenseNumber.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondPractitionerProviderPPN.label}
            name={formSchema.secondPractitionerProviderPPN.name}
            value={values.secondPractitionerProviderPPN}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerProviderPPN.name)}
            maxlength="5"
          />
          <BCInput
            width="75"
            label={formSchema.secondPractitionerYearOfLicensure.label}
            name={formSchema.secondPractitionerYearOfLicensure.name}
            value={values.secondPractitionerYearOfLicensure}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerYearOfLicensure.name)}
          />
          <Fieldset noMargin>
            <Legend>Specialty</Legend>
            <BCSelect
              width="75"
              values={values}
              options={primarySpecialtyOptions}
              placeholder={formSchema.secondPractitionerSpecialty.label}
              name={formSchema.secondPractitionerSpecialty.name}
              errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerSpecialty.name)}
            />
          </Fieldset>

          {renderSecondPractitionerOtherSpecialtyInput()}
          <BCInput
            width="75"
            label={formSchema.secondPractitionerNPI.label}
            name={formSchema.secondPractitionerNPI.name}
            maxlength={10}
            value={values.secondPractitionerNPI}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondPractitionerNPI.name)}
          />
        </Fieldset>
      );
    }
  };

  const renderSkipThisStep = (): ReactElement | null => {
    if (values.groupType === 'facilityOrAncillary') {
      return (
        <bc-typography>
          <i>
            You selected Facility or Ancillary as your Group type. With this selection no practitioner information is
            required. Please continue to the last step.
          </i>
        </bc-typography>
      );
    }
    return null;
  };

  const renderPractitionerInfo = (): ReactElement | null => {
    if (values.groupType !== 'facilityOrAncillary') {
      return (
        <>
          <bc-typography>
            A provider group has to include at least one practitioner at each of their locations. All practitioners also
            have to be enrolled with Blue Cross NC prior to being added to a group. That can done by completing the{' '}
            <a href={INDIVIDUAL_PRACTITIONER_APPLICATION_LINK} target="_blank" rel="noreferrer">
              Individual Practitioner Enrollment Application
            </a>
            . This form will allow you to add up to 2 practitioners to your new group. If you would like to add more
            than 2 practitioners to your new group, please do so using the{' '}
            <a href={PROVIDER_FORMS_DOCS_LINK} target="_blank" rel="noreferrer">
              Demographic and Contact Information Form
            </a>
            .
          </bc-typography>
          <BCRadioGroup
            label={formSchema.numberOfPractitioners.label}
            values={values}
            radios={numberOfPractitionersRadios}
            name={formSchema.numberOfPractitioners.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.numberOfPractitioners.name)}
            validation={formSchema.numberOfPractitioners.validation}
          />

          <Fieldset>
            <Legend required>First practitioner</Legend>
            <BCInput
              width="75"
              label={formSchema.firstPractitionerLastName.label}
              name={formSchema.firstPractitionerLastName.name}
              value={values.firstPractitionerLastName}
              errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerLastName.name)}
            />
            <BCInput
              width="75"
              label={formSchema.firstPractitionerFirstName.label}
              name={formSchema.firstPractitionerFirstName.name}
              value={values.firstPractitionerFirstName}
              errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerFirstName.name)}
            />
            <BCInput
              width="75"
              label={formSchema.firstPractitionerMiddleName.label}
              name={formSchema.firstPractitionerMiddleName.name}
              value={values.firstPractitionerMiddleName}
              errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerMiddleName.name)}
            />
            <BCInput
              width="75"
              label={formSchema.firstPractitionerLicenseNumber.label}
              name={formSchema.firstPractitionerLicenseNumber.name}
              value={values.firstPractitionerLicenseNumber}
              errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerLicenseNumber.name)}
            />
            <BCInput
              width="75"
              label={formSchema.firstPractitionerProviderPPN.label}
              name={formSchema.firstPractitionerProviderPPN.name}
              value={values.firstPractitionerProviderPPN}
              errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerProviderPPN.name)}
              maxlength="5"
            />
            <BCInput
              width="75"
              label={formSchema.firstPractitionerYearOfLicensure.label}
              name={formSchema.firstPractitionerYearOfLicensure.name}
              value={values.firstPractitionerYearOfLicensure}
              errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerYearOfLicensure.name)}
            />
            <Fieldset noMargin>
              <Legend>Specialty</Legend>
              <BCSelect
                width="75"
                values={values}
                options={primarySpecialtyOptions}
                placeholder={formSchema.firstPractitionerSpecialty.label}
                name={formSchema.firstPractitionerSpecialty.name}
                errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerSpecialty.name)}
              />
            </Fieldset>

            {renderFirstPractitionerOtherSpecialtyInput()}
            <BCInput
              width="75"
              label={formSchema.firstPractitionerNPI.label}
              name={formSchema.firstPractitionerNPI.name}
              value={values.firstPractitionerNPI}
              maxlength={10}
              errorMessage={generateErrorMessage(errors, touched, formSchema.firstPractitionerNPI.name)}
            />
          </Fieldset>

          <BCCheckboxGroup
            checkboxes={[
              {
                label: formSchema.addSecondPractitioner.label,
                value: 'addSecondPractitioner',
              },
            ]}
            values={values}
            name={formSchema.addSecondPractitioner.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.addSecondPractitioner.name)}
            validation={formSchema.addSecondPractitioner.validation}
          />
          {renderSecondPractitionerInputs()}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Practitioner Information
      </bc-typography>
      <bc-global-alert variant="danger" open rounded style={{ '--radius-sm': '20px' }}>
        An issue was found impacting the PPN and PTAN Fields of our Forms. Until resolved, enter all 11111 in PPN Field
        and include your PPN and PTAN when emailing your W-9 to ProviderUpdates@bcbsnc.com.
      </bc-global-alert>
      {renderSkipThisStep()}
      {renderPractitionerInfo()}
    </>
  );
}

export default Step4;
