import { ReactElement } from 'react';
import { useStore } from 'store';

const SuccessPage = ({ formName, children }: any) => {
  const {
    state: { formSubmittedSuccessfully, isLoading, formSubmittedFailure, referenceCode, submissionDate, previousUrl },
  } = useStore();

  const renderChildContent = () => children && <div className="blue-card">{children}</div>;

  // only renders reference code (confirmation number) IF provided - model care attestation form will NOT provide confirmation code (because its not used in Salesforce), but all other forms will email data long with confirmation code
  const renderReferenceCode = (): ReactElement | string =>
    referenceCode && (
      <bc-typography size="text-md">
        <strong>Reference Code: </strong>
        {referenceCode}
      </bc-typography>
    );

  const renderSuccessPage = (): ReactElement | null =>
    formSubmittedSuccessfully && !isLoading && !formSubmittedFailure ? (
      <>
        <bc-typography variant="h1" size="display-x-sm" color="primary-900">
          Success!
        </bc-typography>
        <bc-typography variant="h2" size="text-lg">
          Your {formName} has been submitted.
        </bc-typography>
        <bc-typography size="text-md">
          <strong>Submission Date: </strong>
          {submissionDate}
        </bc-typography>
        {renderReferenceCode()}
        {renderChildContent()}
        <div className="divider" />
        <div className="submit--button-container">
          <button
            type="button"
            className="button button--secondary button--regular"
            // eslint-disable-next-line
            onClick={() => (window.location.href = previousUrl)}
          >
            I'm done
          </button>
        </div>
      </>
    ) : null;

  return renderSuccessPage();
};

export default SuccessPage;
