import { PROVIDER_HOME_LINK } from 'constants/links';

function SuccessPageExtraInfo() {
  return (
    <>
      <bc-typography>Thank you for completing the Provider Group Enrollment Application</bc-typography>
      <button
        type="button"
        className="button button--secondary button--regular"
        onClick={() => window.open(PROVIDER_HOME_LINK, '_blank')}
      >
        Blue Cross NC Providers
      </button>
      <bc-typography>
        For additional information, please contact our Network Management Provider Services line at 1-800-777-1643 and
        select option 6.
      </bc-typography>
    </>
  );
}

export default SuccessPageExtraInfo;
