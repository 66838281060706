import { Formik } from 'formik';
import { ReactElement, useEffect } from 'react';
import FormWrapper from 'components/FormWrapper';
import 'styles/button.scss';
import { getModelCareAttestationData, handleFormSubmission } from 'utilities/formHelpers';
import { useStore } from 'store';
import { Action } from 'constants/store';
import FailAlert from 'components/validation/FailAlert';
import { sendDataToSalesforce } from 'utilities/apis';
import PageTitle from 'components/PageTitle';
import { FormTitles } from 'types/FormTypes';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { setLoader } from 'utilities/miscHelpers';
import SuccessPage from 'components/SuccessPage';
import { nanoid } from 'nanoid';
import FormContent from './formContent';
import { initialValues, validationSchema } from './schema';

function ModelCareAttestationForm(): ReactElement {
  const {
    dispatch,
    state: { formSubmittedSuccessfully, isLoading },
  } = useStore();

  // TODO - make this a custom hook
  useEffect(() => {
    // update form title
    dispatch({
      type: Action.UPDATE_FORM_TITLE,
      formTitle: FormTitles.ModelCare,
    });

    // set previous url
    dispatch({ type: Action.UPDATE_PREVIOUS_URL, previousUrl: document.referrer });
  }, [dispatch]);

  const handleSubmit = async (values: any): Promise<void> => {
    setLoader(dispatch, true);

    // get random unique string for referenceCode (confirmation number in Salesforce)
    const referenceCode = nanoid();

    // TODO - do we need to pass in referenceCode (confirmation number in salesforce)? its not being used
    const data = getModelCareAttestationData(values, referenceCode);

    const response = await sendDataToSalesforce(data);

    // handles form submission whether its success or fail
    handleFormSubmission(BcncAnalyticsEventFormNames.ModelCare, response, dispatch);

    setLoader(dispatch, false);
  };

  const renderForm = () => {
    if (!formSubmittedSuccessfully && !isLoading) {
      return (
        <div className="form-content-width">
          <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
            Model Care Attestation
          </bc-typography>
          <bc-typography>
            As the provider, I attest that my practice has reviewed the SNP and MOC presentation.
          </bc-typography>
          <bc-typography>
            I understand the goals of the program and the requirements of the MOC including:
          </bc-typography>
          <bc-list type="ul">
            <li>Plan of care feedback and consensus</li>
            <li>Clinical coordination for the member</li>
            <li>Participation in ICT</li>
            <li>Responsive and cooperative with the plan clinical representatives</li>
            <li>Referring member to medically necessary services in accordance with plan benefits</li>
            <li>Appropriate communication with the member’s family or legal representative</li>
            <li>Timely submission of documentation</li>
            <li>How to obtain additional information or resources</li>
          </bc-list>
          <FailAlert />
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ values, errors, touched }: any) => <FormContent values={values} errors={errors} touched={touched} />}
          </Formik>
        </div>
      );
    }
  };

  return (
    <FormWrapper>
      <PageTitle title="Model Care Attestation" />
      {renderForm()}
      <SuccessPage formName={FormTitles.ModelCare} />
    </FormWrapper>
  );
}

export default ModelCareAttestationForm;
