import { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import 'styles/button.scss';

// TODO - fix any for  handleNext/Previous step type

interface MultiStepButtonsProps {
  values: any;
  handleNextStep: any;
  handlePreviousStep: any;
  currentStep: number;
  totalSteps: number;
  stepValidation: any;
  canSkipStep4?: boolean;
}

function MultiStepButtons({
  values,
  handleNextStep,
  handlePreviousStep,
  currentStep,
  totalSteps,
  stepValidation,
  canSkipStep4,
}: MultiStepButtonsProps): ReactElement {
  const [formIsValid, setFormIsValid] = useState(false);

  const submitButton: MutableRefObject<null> = useRef(null);

  useEffect(() => {
    const validateStep = async () => {
      // one-off case for skipping step 4 on Provider Group Enrollment form IF user selected "facilityOrAncillary" as their group type from step 2
      if (canSkipStep4) {
        return setFormIsValid(true);
      }

      // don't try to validate first (intro) step
      if (currentStep !== 0) {
        try {
          await stepValidation.validate(values);
          setFormIsValid(true);
        } catch {
          setFormIsValid(false);
        }
      }
    };

    validateStep();
  }, [values, stepValidation, canSkipStep4, currentStep, totalSteps]);

  useEffect(() => {
    // if last step is valid, switch "Submit" button from type "button" to type "submit"
    // NOTE: we are doing this because with our current setup Formik somehow submits the last step of the form on render, causing all fields to be marked as "touched" and display error messages
    // TICKET: https://jira.bcbsnc.com/browse/ECMSD-794
    if (formIsValid && submitButton.current && currentStep === totalSteps) {
      // @ts-ignore
      submitButton.current.type = 'submit';
    }
  }, [formIsValid, currentStep, totalSteps]);

  const renderStartNavButton = (): ReactElement => (
    <div className="submit--button-container">
      <button type="button" className="button button--primary button--regular" onClick={handleNextStep}>
        Get Started
      </button>
    </div>
  );

  const renderEndNavButton = (): ReactElement => (
    <div className="nav--button-container">
      <button type="button" className="button button--tertiary button--regular" onClick={handlePreviousStep}>
        <bc-icon name="CaretLeft" color="primary-800" />
        Back
      </button>
      {formIsValid ? (
        <button type="button" className="button button--primary button--regular" ref={submitButton}>
          Submit
        </button>
      ) : (
        <button type="button" disabled className="button button--primary button--regular button--inactive">
          Submit
        </button>
      )}
    </div>
  );

  const renderBackContinueNavButtons = (): ReactElement => {
    let continueButton = (
      <button type="button" className="button button--primary button--regular" onClick={() => handleNextStep(values)}>
        Continue
      </button>
    );

    if (!formIsValid) {
      continueButton = (
        <button
          type="button"
          className="button button--primary button--regular button--inactive"
          disabled
          onClick={() => handleNextStep(values)}
        >
          Continue
        </button>
      );
    }

    return (
      <div className="nav--button-container">
        <button type="button" className="button button--tertiary button--regular" onClick={handlePreviousStep}>
          <bc-icon name="CaretLeft" color="primary-800" />
          Back
        </button>
        {continueButton}
      </div>
    );
  };

  const renderNavButtons = (): ReactElement => {
    if (currentStep === 0) {
      return renderStartNavButton();
    }
    if (currentStep === totalSteps) {
      return renderEndNavButton();
    }

    return renderBackContinueNavButtons();
  };

  return (
    <>
      <div className="divider" />
      {renderNavButtons()}
    </>
  );
}

export default MultiStepButtons;

MultiStepButtons.defaultProps = {
  canSkipStep4: false,
};
