import * as Yup from 'yup';
import { getInitialValues, getYupValidationSchema } from 'utilities/miscHelpers';
import { dateValidation, optionalPhoneValidation, optionalZipValidation } from 'utilities/customValidators';
import { HoneypotFieldSchema } from 'utilities/formHelpers';

export const formSchema = {
  // TODO - need to make sure data sent can distinguish between reporter, insured, and suspect (because right now they all have same/similar labels)
  HoneypotFieldSchema,
  step1Description: {
    label: 'Fraud and Abuse Report',
    emailLabel: 'Fraud and Abuse Report',
    initialValue: 'Fraud and Abuse Report',
    name: '',
    type: 'description',
  },
  reporterFirstName: {
    emailLabel: 'Reporter First Name',
    name: 'reporterFirstName',
    label: 'First name',
    initialValue: '',
    validation: null,
  },
  reporterLastName: {
    emailLabel: 'Reporter Last Name',
    name: 'reporterLastName',
    label: 'Last name',
    initialValue: '',
    validation: null,
  },
  reporterStreetAddress: {
    emailLabel: 'Reporter Street Address',
    name: 'reporterStreetAddress',
    label: 'Street address',
    initialValue: '',
    validation: null,
  },
  reporterCity: {
    emailLabel: 'Reporter City',
    name: 'reporterCity',
    label: 'City',
    initialValue: '',
    validation: null,
  },
  reporterState: {
    emailLabel: 'Reporter State',
    name: 'reporterState',
    label: 'State',
    initialValue: '',
    validation: null,
  },
  reporterZIP: {
    emailLabel: 'Reporter Zip',
    name: 'reporterZIP',
    label: 'Zip code',
    initialValue: '',
    validation: optionalZipValidation,
  },
  reporterEmail: {
    emailLabel: 'Reporter Email',
    name: 'reporterEmail',
    label: 'Email address',
    initialValue: '',
    validation: null,
  },
  reporterPhone: {
    emailLabel: 'Reporter Phone',
    name: 'reporterPhone',
    label: 'Phone number',
    initialValue: '',
    validation: optionalPhoneValidation,
  },
  reporterEveningPhone: {
    emailLabel: 'Reporter Evening Phone',
    name: 'reporterEveningPhone',
    label: 'Evening phone number',
    initialValue: '',
    validation: optionalPhoneValidation,
  },
  insuredFirstName: {
    emailLabel: 'Insured First Name',
    name: 'insuredFirstName',
    label: 'First name',
    initialValue: '',
    validation: null,
  },
  insuredLastName: {
    emailLabel: 'Insured Last Name',
    name: 'insuredLastName',
    label: 'Last name',
    initialValue: '',
    validation: null,
  },
  insuredMemberIDNumber: {
    emailLabel: 'Insured Member ID Number',
    name: 'insuredMemberIDNumber',
    label: 'Member ID number',
    initialValue: '',
    validation: null,
  },
  insuredStreetAddress: {
    emailLabel: 'Insured Street Address',
    name: 'insuredStreetAddress',
    label: 'Street address',
    initialValue: '',
    validation: null,
  },
  insuredCity: {
    emailLabel: 'Insured City',
    name: 'insuredCity',
    label: 'City',
    initialValue: '',
    validation: null,
  },
  insuredState: {
    emailLabel: 'Insured State',
    name: 'insuredState',
    label: 'State',
    initialValue: '',
    validation: null,
  },
  insuredZIP: {
    emailLabel: 'Insured Zip Code',
    name: 'insuredZIP',
    label: 'Zip code',
    initialValue: '',
    validation: optionalZipValidation,
  },
  insuredEmail: {
    emailLabel: 'Insured Email',
    name: 'insuredEmail',
    label: 'Email address',
    initialValue: '',
    validation: null,
  },
  insuredPhone: {
    emailLabel: 'Insured Phone',
    name: 'insuredPhone',
    label: 'Phone number',
    initialValue: '',
    validation: optionalPhoneValidation,
  },
  suspectName: {
    emailLabel: 'Suspect Name (person and/or company)',
    name: 'suspectName',
    label: 'Name (person and/or company)',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  suspectStreetAddress: {
    emailLabel: 'Suspect Street Address',
    name: 'suspectStreetAddress',
    label: 'Street address',
    initialValue: '',
    validation: null,
  },
  suspectCity: {
    emailLabel: 'Suspect City',
    name: 'suspectCity',
    label: 'City',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  suspectState: {
    emailLabel: 'Suspect State',
    name: 'suspectState',
    label: 'State',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  suspectPhone: {
    emailLabel: 'Suspect Phone',
    name: 'suspectPhone',
    label: 'Phone number',
    initialValue: '',
    validation: optionalPhoneValidation,
  },
  suspectZIP: {
    emailLabel: 'Suspect Zip Code',
    name: 'suspectZIP',
    label: 'Zip code',
    initialValue: '',
    validation: optionalZipValidation,
  },
  dateOfIncident: {
    emailLabel: 'Date of Incident',
    name: 'dateOfIncident',
    label: 'Date of incident',
    initialValue: '',
    validation: dateValidation,
  },
  description: {
    emailLabel: 'Description of suspected fraud or abuse',
    name: 'description',
    label: 'Description of suspected fraud or abuse',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  external: {
    emailLabel: 'External',
    name: 'external',
    label: 'External',
    initialValue: '77',
    validation: null,
  },
};

export const initialValues = getInitialValues(formSchema);

export const validationSchema = getYupValidationSchema(formSchema);
