// NOTE: Step0 = intro page (no form elements)

// TODO - make nested list items alphabetic rather than numeric

function Step0() {
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Provider Acquisition
      </bc-typography>
      <bc-typography>
        Completion of this form does not establish you or your practice as an in-network Blue Cross NC provider. Answers
        to these questions do not guarantee any particular contract outcome. Contract status is still subject to review.
      </bc-typography>
      <bc-typography>
        To join, please have the following information available for each practitioner to be enrolled.
      </bc-typography>
      <div className="blue-card">
        <bc-typography weight="bold">Please have the following information available:</bc-typography>
        <bc-list type="ol">
          <li>Roster to upload OR the name and NPI number of each provider at your practice.</li>
          <li>
            The following documents to email to us in the next step after completing your application. <br />
            <strong>Note:</strong> Failure to provide the required documentation will result in your request being
            returned as incomplete.
            <bc-list type="ul">
              <li>Provider W-9 - accepted formats: jpg jpeg png pdf</li>
              <li>Official Documentation of Acquisition - accepted formats: jpg jpeg png pdf</li>
              <li>Provider Roster (if you answered "yes" to the question above) - accepted formats: excel</li>
            </bc-list>
          </li>
          <li>Expedite practitioner enrollment by providing accurate and current information.</li>
        </bc-list>
      </div>
    </>
  );
}

export default Step0;
