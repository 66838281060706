import { useEffect, useState } from 'react';
import 'styles/button.scss';
import { BcncAnalyticsEventActions, BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { fireBcncAnalyticsEvent } from 'utilities/eventHelpers';

// This component used only in single-step forms

interface SubmitContainerProps {
  values: any;
  validationSchema: any;
  formName: BcncAnalyticsEventFormNames;
  // eslint-disable-next-line
  previousFieldIsInput?: boolean;
  // eslint-disable-next-line
  hasFooter?: boolean;
}

function SubmitContainer({
  values,
  validationSchema,
  previousFieldIsInput,
  hasFooter,
  formName,
}: SubmitContainerProps) {
  const [formIsValid, setFormIsValid] = useState(true);

  useEffect(() => {
    const validateStep = async () => {
      try {
        await validationSchema.validate(values);
        setFormIsValid(true);
      } catch {
        setFormIsValid(false);
      }
    };

    validateStep();
  }, [values, validationSchema]);

  return (
    <div
      style={{
        // if input field, need to account for extra space for error message (24px)
        marginTop: previousFieldIsInput ? '8px' : '32px',
        // if there is a footer below, put extra space between
        marginBottom: hasFooter ? '32px' : '0px',
      }}
    >
      <button
        type="submit"
        disabled={!formIsValid}
        className={`button button--primary button--regular ${!formIsValid && 'button--inactive'}`}
        onClick={() => fireBcncAnalyticsEvent(BcncAnalyticsEventActions.FormSubmitClick, formName)}
      >
        Submit
      </button>
    </div>
  );
}

export default SubmitContainer;
