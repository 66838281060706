export const PRIVACY_POLICY_LINK = 'https://www.bluecrossnc.com/about-us/policies-and-best-practices';

export const W9_LINK =
  'https://www.bluecrossnc.com/sites/default/files/document/attachment/providers/public/pdfs/V512.pdf';

export const CAQH_LINK = 'https://www.caqh.org/';

export const CAQH_FAQ_LINK =
  'https://www.bluecrossnc.com/sites/default/files/document/attachment/providers/public/pdfs/news-and-information/news/Digitization%20Communication_FAQ_0815_Sarah%20Aly.pdf';

export const CAQH_PROVIEW_APPLICATION_LINK = 'https://proview.caqh.org/Login?Type=PM';

export const CREDENTIALING_NETWORK_INFORMATION_LINK =
  'https://www.bluecrossnc.com/providers/network-participation/become-blue-cross-nc-provider-recredential';

export const CREDENTIALED_BCBSNC_FACILITY_LINK =
  'https://www.bluecrossnc.com/providers/network-participation/manually-apply-credentialing-offline';

export const INDIVIDUAL_PRACTITIONER_APPLICATION_LINK =
  'https://www.bluecrossnc.com/providers/network-participation/individual-practitioner-enrollment-application';

export const PROVIDER_FORMS_DOCS_LINK = 'https://www.bluecrossnc.com/providers/providers-forms-and-documentation';

export const MAIL_TO_PROV_REQUESTS_LINK = 'mailto:ProvRequests@bcbsnc.com';

export const MAIL_TO_PROV_REQUESTS_SUBJECT_LINK = `${MAIL_TO_PROV_REQUESTS_LINK}?subject=Provider%20Request`;

export const MAIL_TO_PROV_ACQUISITION_LINK = 'mailto:ProviderAcquisition@bcbsnc.com';

export const PROVIDER_HOME_LINK = 'https://www.bluecrossnc.com/provider-home';

export const PROVIDER_GROUP_ENROLLMENT_LINK =
  'https://www.bluecrossnc.com/providers/network-participation/provider-group-enrollment-application';
