import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import Header from 'components/Header';
import { useStore } from 'store';
import { FormTitles } from 'types/FormTypes';
import PageTitle from './PageTitle';

interface FormWrapperProps {
  // eslint-disable-next-line
  currentStep?: number;
  // eslint-disable-next-line
  totalSteps?: number;
  // eslint-disable-next-line
  pageTitle?: FormTitles;
}

function FormWrapper({ children, currentStep, totalSteps, pageTitle }: PropsWithChildren<FormWrapperProps>) {
  const [isMultiStep, setIsMultiStep] = useState(false);

  const {
    state: { formSubmittedSuccessfully, isLoading },
  } = useStore();

  useEffect(() => {
    if (totalSteps && totalSteps > 1 && !formSubmittedSuccessfully) setIsMultiStep(true);
  }, [formSubmittedSuccessfully, totalSteps]);

  const renderProgressTracker = (): ReactElement | null =>
    isMultiStep && !formSubmittedSuccessfully && !isLoading ? (
      <div className="center-flex">
        <bc-progress-tracker steps={totalSteps} active-step={currentStep} />
      </div>
    ) : null;

  const renderCurrentStepLabel = (): ReactElement | null =>
    isMultiStep && currentStep !== 0 ? (
      <bc-typography color="neutral-600" size="text-x-sm" letter-spacing="loose" weight="bold">
        STEP {currentStep} OF {totalSteps}
      </bc-typography>
    ) : null;

  const renderLoader = () => {
    if (isLoading) {
      return (
        <div className="center-flex">
          <bc-loader />
        </div>
      );
    }
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <div className="form-wrapper">
          {renderCurrentStepLabel()}
          {children}
        </div>
      );
    }
  };

  // update title in header if multistep form and not on first step (intro)
  const setPageTitle = (): ReactElement | null => {
    if (isMultiStep && pageTitle && currentStep && totalSteps && currentStep !== 0) {
      const updatedPageTitle = `${pageTitle.toString()} - Step ${currentStep.toString()} of ${totalSteps.toString()}`;

      return <PageTitle title={updatedPageTitle} />;
    }

    return null;
  };

  return (
    <>
      <Header noGutters={isMultiStep && !formSubmittedSuccessfully && !isLoading} />
      {setPageTitle()}
      <div style={{ margin: 'auto' }}>
        {renderProgressTracker()}
        {renderLoader()}
        {renderForm()}
      </div>
    </>
  );
}

export default FormWrapper;
