import BCCheckboxGroup from 'components/custom-fields/BCCheckboxGroup';
import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import {
  facilityTypeOptions,
  groupTypeRadios,
  medicareMembersRadios,
  primarySpecialtyOptions,
  servicesLocationOptions,
  servicesOfferedRadios,
  telehealthRadios,
} from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerGroupEnrollmentForm/schema';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';

function Step2({ values, errors, touched }: any) {
  const renderFacilityOrAncilaryInput = () => {
    if (values.groupType === 'facilityOrAncillary') {
      return (
        <Fieldset>
          <Legend required>Facility Type</Legend>
          <BCSelect
            width="75"
            values={values}
            options={facilityTypeOptions}
            placeholder={formSchema.facilityType.label}
            name={formSchema.facilityType.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.facilityType.name)}
          />
        </Fieldset>
      );
    }
  };

  const renderOtherSpecialtyInput = () => {
    if (values.specialty?.includes('other')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherSpecialty.label}
          name={formSchema.otherSpecialty.name}
          value={values.otherSpecialty}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherSpecialty.name)}
        />
      );
    }
  };

  const renderOtherServicesLocation = () => {
    if (values.servicesLocation?.includes('otherServicesLocation')) {
      return (
        <BCInput
          width="75"
          label={formSchema.otherServicesLocation.label}
          name={formSchema.otherServicesLocation.name}
          value={values.otherServicesLocation}
          errorMessage={generateErrorMessage(errors, touched, formSchema.otherServicesLocation.name)}
        />
      );
    }
  };

  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        {/* Group Information */}
        {formSchema.step2Description.label}
      </bc-typography>

      <bc-global-alert variant="danger" open rounded style={{ '--radius-sm': '20px' }}>
        An issue was found impacting the PPN and PTAN Fields of our Forms. Until resolved, enter all 11111 in PPN Field
        and include your PPN and PTAN when emailing your W-9 to ProviderUpdates@bcbsnc.com.
      </bc-global-alert>
      <BCRadioGroup
        label={formSchema.groupType.label}
        values={values}
        radios={groupTypeRadios}
        name={formSchema.groupType.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.groupType.name)}
        validation={formSchema.groupType.validation}
      />
      {renderFacilityOrAncilaryInput()}
      <div className="mt-32">
        <BCInput
          width="75"
          label={formSchema.groupName.label}
          name={formSchema.groupName.name}
          value={values.groupName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.groupName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.doingBusinessAs.label}
          name={formSchema.doingBusinessAs.name}
          value={values.doingBusinessAs}
          errorMessage={generateErrorMessage(errors, touched, formSchema.doingBusinessAs.name)}
        />
        <Fieldset noMargin>
          <Legend required>Specialty</Legend>
          <BCSelect
            width="75"
            values={values}
            options={primarySpecialtyOptions}
            placeholder={formSchema.specialty.label}
            name={formSchema.specialty.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.specialty.name)}
          />
        </Fieldset>
        {renderOtherSpecialtyInput()}
      </div>

      <BCRadioGroup
        label={formSchema.servicesOffered.label}
        values={values}
        radios={servicesOfferedRadios}
        name={formSchema.servicesOffered.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.servicesOffered.name)}
        validation={formSchema.servicesOffered.validation}
      />

      <BCRadioGroup
        label={formSchema.offerTelehealthServices.label}
        values={values}
        radios={telehealthRadios}
        name={formSchema.offerTelehealthServices.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.offerTelehealthServices.name)}
        validation={formSchema.offerTelehealthServices.validation}
      />
      <BCCheckboxGroup
        label={formSchema.servicesLocation.label}
        checkboxes={servicesLocationOptions}
        values={values}
        name={formSchema.servicesLocation.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.servicesLocation.name)}
        validation={formSchema.servicesLocation.validation}
      />
      {renderOtherServicesLocation()}

      <div className="mt-32">
        <BCInput
          width="75"
          maxlength="11"
          label={formSchema.taxIDNumber.label}
          name={formSchema.taxIDNumber.name}
          value={values.taxIDNumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.taxIDNumber.name)}
        />
        <BCInput
          width="75"
          label={formSchema.NPITypeII.label}
          name={formSchema.NPITypeII.name}
          maxlength={10}
          value={values.NPITypeII}
          errorMessage={generateErrorMessage(errors, touched, formSchema.NPITypeII.name)}
        />
        <BCInput
          width="75"
          maxlength="5"
          label={formSchema.providerGroupPreferredPPN.label}
          name={formSchema.providerGroupPreferredPPN.name}
          value={values.providerGroupPreferredPPN}
          errorMessage={generateErrorMessage(errors, touched, formSchema.providerGroupPreferredPPN.name)}
        />
        <BCInput
          width="75"
          maxlength="10"
          label={formSchema.groupMedicareProviderNumber.label}
          name={formSchema.groupMedicareProviderNumber.name}
          value={values.groupMedicareProviderNumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.groupMedicareProviderNumber.name)}
        />
        <BCInput
          width="75"
          maxlength="10"
          label={formSchema.CLIANumber.label}
          name={formSchema.CLIANumber.name}
          value={values.CLIANumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.CLIANumber.name)}
        />
        <BCRadioGroup
          label={formSchema.providingServicesToMedicareMembers.label}
          values={values}
          radios={medicareMembersRadios}
          name={formSchema.providingServicesToMedicareMembers.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.providingServicesToMedicareMembers.name)}
          validation={formSchema.providingServicesToMedicareMembers.validation}
        />
      </div>
    </>
  );
}

export default Step2;
