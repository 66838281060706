import { Form } from 'formik';
import BCInput from 'components/custom-fields/BCInput';
import { generateErrorMessage } from 'utilities/miscHelpers';
import SubmitContainer from 'components/SubmitContainer';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import { NPIRadios, haveAdditionalGroupNPIRadios } from 'constants/formData';
import BCTextArea from 'components/custom-fields/BCTextArea';
import Fieldset from 'components/Fieldset';
import { phoneFormatHoverTitle } from 'constants/hoverTitles';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { useEffect, useRef } from 'react';
import { registerBcAnalyticsEventListenerStartForm } from 'utilities/eventHelpers';
import Legend from 'components/Legend';
import { formSchema, validationSchema } from './schema';

function FormContent({ values, errors, touched }: any) {
  const ref = useRef();

  // NOTE - attaching eventListener, rather than using onFocus prop, because we need to ensure it is only fired once
  useEffect(() => {
    registerBcAnalyticsEventListenerStartForm(ref, BcncAnalyticsEventFormNames.ModelCare);
  }, []);

  const renderAdditionalGroupNPIsInput = () => {
    if (values.additional_group_npi_yn === 'yes') {
      return (
        <BCTextArea
          value={values.additional_group_npi}
          label={formSchema.additional_group_npi.label}
          name={formSchema.additional_group_npi.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.additional_group_npi.name)}
          rows="4"
          width="75"
        />
      );
    }
  };

  return (
    <Form>
      <Fieldset>
        <Legend required>Enter your information</Legend>
        <BCInput
          label={formSchema.HoneypotFieldSchema.label}
          name={formSchema.HoneypotFieldSchema.name}
          width="75"
          tabIndex="-500"
          value={values.HoneypotFieldSchema}
        />
        <input type="hidden" name={formSchema.oid.name} value={formSchema.oid.initialValue} />
        <BCInput
          label={formSchema.your_first_name.label}
          width="75"
          name={formSchema.your_first_name.name}
          value={values.your_first_name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.your_first_name.name)}
          // passing ref in order to attach Bcanalytics event listener to FIRST input on page
          ref={ref}
        />
        <BCInput
          label={formSchema.your_last_name.label}
          width="75"
          name={formSchema.your_last_name.name}
          value={values.your_last_name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.your_last_name.name)}
        />
        <BCInput
          label={formSchema.company.label}
          width="75"
          name={formSchema.company.name}
          value={values.company}
          errorMessage={generateErrorMessage(errors, touched, formSchema.company.name)}
        />
        <BCInput
          label={formSchema.tax_id.label}
          width="75"
          maxlength="9"
          name={formSchema.tax_id.name}
          value={values.tax_id}
          errorMessage={generateErrorMessage(errors, touched, formSchema.tax_id.name)}
        />
        <BCInput
          label={formSchema.provider_email_address.label}
          width="75"
          type="email"
          name={formSchema.provider_email_address.name}
          value={values.provider_email_address}
          errorMessage={generateErrorMessage(errors, touched, formSchema.provider_email_address.name)}
        />
        <BCInput
          label={formSchema.phone.label}
          width="75"
          type="tel"
          maxlength={12}
          hoverTitle={phoneFormatHoverTitle}
          name={formSchema.phone.name}
          value={values.phone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.phone.name)}
        />
      </Fieldset>
      <BCRadioGroup
        label={formSchema.NPI.label}
        values={values}
        radios={NPIRadios}
        name={formSchema.NPI.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.NPI.name)}
        validation={formSchema.NPI.validation}
      />
      <BCInput
        label={formSchema.NPINumber.label}
        width="75"
        name={formSchema.NPINumber.name}
        maxlength={10}
        value={values.NPINumber}
        errorMessage={generateErrorMessage(errors, touched, formSchema.NPINumber.name)}
      />

      <BCRadioGroup
        label={formSchema.additional_group_npi_yn.label}
        values={values}
        radios={haveAdditionalGroupNPIRadios}
        name={formSchema.additional_group_npi_yn.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.additional_group_npi_yn.name)}
        validation={formSchema.additional_group_npi_yn.validation}
      />
      {renderAdditionalGroupNPIsInput()}
      <input type="hidden" name={formSchema.recordtype.name} value={formSchema.recordtype.initialValue} />
      <input type="hidden" name={formSchema.lead_source.name} value={formSchema.lead_source.initialValue} />
      <input type="hidden" name={formSchema.source_system.name} value={formSchema.source_system.initialValue} />
      <input
        type="hidden"
        name={formSchema.confirmation_number.name}
        value={formSchema.confirmation_number.initialValue}
      />
      <SubmitContainer
        values={values}
        validationSchema={validationSchema}
        formName={BcncAnalyticsEventFormNames.ModelCare}
      />
    </Form>
  );
}

export default FormContent;
