import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import {
  eighthProviderAcceptingNewPatientsRadios,
  eighthProviderShouldShowInDirectoryRadios,
  fifthProviderAcceptingNewPatientsRadios,
  fifthProviderShouldShowInDirectoryRadios,
  firstProviderAcceptingNewPatientsRadios,
  firstProviderShouldShowInDirectoryRadios,
  fourthProviderAcceptingNewPatientsRadios,
  fourthProviderShouldShowInDirectoryRadios,
  haveRosterToUploadRadios,
  secondProviderAcceptingNewPatientsRadios,
  secondProviderShouldShowInDirectoryRadios,
  seventhProviderAcceptingNewPatientsRadios,
  seventhProviderShouldShowInDirectoryRadios,
  sixthProviderAcceptingNewPatientsRadios,
  sixthProviderShouldShowInDirectoryRadios,
  stateOptions,
  thirdProviderAcceptingNewPatientsRadios,
  thirdProviderShouldShowInDirectoryRadios,
} from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerAcquisitionForm/schema';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';
import { faxFormatHoverTitle, phoneFormatHoverTitle } from 'constants/hoverTitles';

function Step2({ values, errors, touched }: any) {
  // TODO - create some sort of Repeater component where you can add another (or delete) field
  const renderProviderInputs = () => {
    if (values.haveRosterToUpload === 'no') {
      return (
        <div className="mt-32">
          <BCInput
            width="75"
            label={formSchema.firstProviderName.label}
            name={formSchema.firstProviderName.name}
            value={values.firstProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.firstProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.firstProviderNPINumber.label}
            name={formSchema.firstProviderNPINumber.name}
            maxlength={10}
            value={values.firstProviderNPINumber}
            errorMessage={generateErrorMessage(errors, touched, formSchema.firstProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.firstProviderShouldShowInDirectory.label}
            values={values}
            radios={firstProviderShouldShowInDirectoryRadios}
            name={formSchema.firstProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.firstProviderShouldShowInDirectory.name)}
            validation={formSchema.firstProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.firstProviderIsAcceptingNewPatients.label}
            values={values}
            radios={firstProviderAcceptingNewPatientsRadios}
            name={formSchema.firstProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.firstProviderIsAcceptingNewPatients.name)}
            validation={formSchema.firstProviderIsAcceptingNewPatients.validation}
          />
          <BCInput
            width="75"
            label={formSchema.secondProviderName.label}
            name={formSchema.secondProviderName.name}
            value={values.secondProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondProviderNPINumber.label}
            name={formSchema.secondProviderNPINumber.name}
            value={values.secondProviderNPINumber}
            maxlength={10}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.secondProviderShouldShowInDirectory.label}
            values={values}
            radios={secondProviderShouldShowInDirectoryRadios}
            name={formSchema.secondProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondProviderShouldShowInDirectory.name)}
            validation={formSchema.secondProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.secondProviderIsAcceptingNewPatients.label}
            values={values}
            radios={secondProviderAcceptingNewPatientsRadios}
            name={formSchema.secondProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondProviderIsAcceptingNewPatients.name)}
            validation={formSchema.secondProviderIsAcceptingNewPatients.validation}
          />
          <BCInput
            width="75"
            label={formSchema.thirdProviderName.label}
            name={formSchema.thirdProviderName.name}
            value={values.thirdProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.thirdProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.thirdProviderNPINumber.label}
            name={formSchema.thirdProviderNPINumber.name}
            value={values.thirdProviderNPINumber}
            maxlength={10}
            errorMessage={generateErrorMessage(errors, touched, formSchema.thirdProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.thirdProviderShouldShowInDirectory.label}
            values={values}
            radios={thirdProviderShouldShowInDirectoryRadios}
            name={formSchema.thirdProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.thirdProviderShouldShowInDirectory.name)}
            validation={formSchema.thirdProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.thirdProviderIsAcceptingNewPatients.label}
            values={values}
            radios={thirdProviderAcceptingNewPatientsRadios}
            name={formSchema.thirdProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.thirdProviderIsAcceptingNewPatients.name)}
            validation={formSchema.thirdProviderIsAcceptingNewPatients.validation}
          />
          <BCInput
            width="75"
            label={formSchema.fourthProviderName.label}
            name={formSchema.fourthProviderName.name}
            value={values.fourthProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fourthProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.fourthProviderNPINumber.label}
            name={formSchema.fourthProviderNPINumber.name}
            value={values.fourthProviderNPINumber}
            maxlength={10}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fourthProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.fourthProviderShouldShowInDirectory.label}
            values={values}
            radios={fourthProviderShouldShowInDirectoryRadios}
            name={formSchema.fourthProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fourthProviderShouldShowInDirectory.name)}
            validation={formSchema.fourthProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.fourthProviderIsAcceptingNewPatients.label}
            values={values}
            radios={fourthProviderAcceptingNewPatientsRadios}
            name={formSchema.fourthProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fourthProviderIsAcceptingNewPatients.name)}
            validation={formSchema.fourthProviderIsAcceptingNewPatients.validation}
          />
          <BCInput
            width="75"
            label={formSchema.fifthProviderName.label}
            name={formSchema.fifthProviderName.name}
            value={values.fifthProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fifthProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.fifthProviderNPINumber.label}
            name={formSchema.fifthProviderNPINumber.name}
            value={values.fifthProviderNPINumber}
            maxlength={10}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fifthProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.fifthProviderShouldShowInDirectory.label}
            values={values}
            radios={fifthProviderShouldShowInDirectoryRadios}
            name={formSchema.fifthProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fifthProviderShouldShowInDirectory.name)}
            validation={formSchema.fifthProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.fifthProviderIsAcceptingNewPatients.label}
            values={values}
            radios={fifthProviderAcceptingNewPatientsRadios}
            name={formSchema.fifthProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.fifthProviderIsAcceptingNewPatients.name)}
            validation={formSchema.fifthProviderIsAcceptingNewPatients.validation}
          />
          <BCInput
            width="75"
            label={formSchema.sixthProviderName.label}
            name={formSchema.sixthProviderName.name}
            value={values.sixthProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.sixthProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.sixthProviderNPINumber.label}
            name={formSchema.sixthProviderNPINumber.name}
            value={values.sixthProviderNPINumber}
            maxlength={10}
            errorMessage={generateErrorMessage(errors, touched, formSchema.sixthProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.sixthProviderShouldShowInDirectory.label}
            values={values}
            radios={sixthProviderShouldShowInDirectoryRadios}
            name={formSchema.sixthProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.sixthProviderShouldShowInDirectory.name)}
            validation={formSchema.sixthProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.sixthProviderIsAcceptingNewPatients.label}
            values={values}
            radios={sixthProviderAcceptingNewPatientsRadios}
            name={formSchema.sixthProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.sixthProviderIsAcceptingNewPatients.name)}
            validation={formSchema.sixthProviderIsAcceptingNewPatients.validation}
          />
          <BCInput
            width="75"
            label={formSchema.seventhProviderName.label}
            name={formSchema.seventhProviderName.name}
            value={values.seventhProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.seventhProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.seventhProviderNPINumber.label}
            name={formSchema.seventhProviderNPINumber.name}
            value={values.seventhProviderNPINumber}
            maxlength={10}
            errorMessage={generateErrorMessage(errors, touched, formSchema.seventhProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.seventhProviderShouldShowInDirectory.label}
            values={values}
            radios={seventhProviderShouldShowInDirectoryRadios}
            name={formSchema.seventhProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.seventhProviderShouldShowInDirectory.name)}
            validation={formSchema.seventhProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.seventhProviderIsAcceptingNewPatients.label}
            values={values}
            radios={seventhProviderAcceptingNewPatientsRadios}
            name={formSchema.seventhProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.seventhProviderIsAcceptingNewPatients.name)}
            validation={formSchema.seventhProviderIsAcceptingNewPatients.validation}
          />
          <BCInput
            width="75"
            label={formSchema.eighthProviderName.label}
            name={formSchema.eighthProviderName.name}
            value={values.eighthProviderName}
            errorMessage={generateErrorMessage(errors, touched, formSchema.eighthProviderName.name)}
          />
          <BCInput
            width="75"
            label={formSchema.eighthProviderNPINumber.label}
            name={formSchema.eighthProviderNPINumber.name}
            value={values.eighthProviderNPINumber}
            maxlength={10}
            errorMessage={generateErrorMessage(errors, touched, formSchema.eighthProviderNPINumber.name)}
          />
          <BCRadioGroup
            label={formSchema.eighthProviderShouldShowInDirectory.label}
            values={values}
            radios={eighthProviderShouldShowInDirectoryRadios}
            name={formSchema.eighthProviderShouldShowInDirectory.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.eighthProviderShouldShowInDirectory.name)}
            validation={formSchema.eighthProviderShouldShowInDirectory.validation}
          />
          <BCRadioGroup
            label={formSchema.eighthProviderIsAcceptingNewPatients.label}
            values={values}
            radios={eighthProviderAcceptingNewPatientsRadios}
            name={formSchema.eighthProviderIsAcceptingNewPatients.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.eighthProviderIsAcceptingNewPatients.name)}
            validation={formSchema.eighthProviderIsAcceptingNewPatients.validation}
          />
        </div>
      );
    }
  };

  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Primary practice information
      </bc-typography>
      <Fieldset>
        <Legend required>Primary practice information:</Legend>
        <BCInput
          width="75"
          label={formSchema.streetAddress.label}
          name={formSchema.streetAddress.name}
          value={values.streetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.streetAddress.name)}
        />
        <BCInput
          width="75"
          label={formSchema.suiteAptUnitFloor.label}
          name={formSchema.suiteAptUnitFloor.name}
          value={values.suiteAptUnitFloor}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suiteAptUnitFloor.name)}
        />
        <BCInput
          width="75"
          label={formSchema.city.label}
          name={formSchema.city.name}
          value={values.city}
          errorMessage={generateErrorMessage(errors, touched, formSchema.city.name)}
        />
        <Fieldset noMargin>
          <Legend required>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.state.label}
            name={formSchema.state.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.state.name)}
          />
        </Fieldset>
        <BCInput
          width="75"
          label={formSchema.zip.label}
          name={formSchema.zip.name}
          maxlength={5}
          value={values.zip}
          errorMessage={generateErrorMessage(errors, touched, formSchema.zip.name)}
        />
        <BCInput
          width="75"
          label={formSchema.county.label}
          name={formSchema.county.name}
          value={values.county}
          errorMessage={generateErrorMessage(errors, touched, formSchema.county.name)}
        />
        <BCInput
          width="75"
          label={formSchema.phone.label}
          name={formSchema.phone.name}
          maxlength={12}
          hoverTitle={phoneFormatHoverTitle}
          value={values.phone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.phone.name)}
        />
        <BCInput
          width="75"
          label={formSchema.fax.label}
          name={formSchema.fax.name}
          hoverTitle={faxFormatHoverTitle}
          value={values.fax}
          errorMessage={generateErrorMessage(errors, touched, formSchema.fax.name)}
        />
        {/* TODO - add this as a description prop to BCRadioGroup ??? */}
        <bc-typography>
          If you do not have a provider roster available to upload, you will need to provide the name and NPI number of
          each provider at your practice. If your practice contains more than 8 practitioners, you must upload an Excel
          provider roster. You will email your provider roster to Blue Cross NC after submitting your application.
        </bc-typography>
        <BCRadioGroup
          label={formSchema.haveRosterToUpload.label}
          values={values}
          radios={haveRosterToUploadRadios}
          name={formSchema.haveRosterToUpload.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.haveRosterToUpload.name)}
          validation={formSchema.haveRosterToUpload.validation}
        />
        {renderProviderInputs()}
      </Fieldset>
    </>
  );
}

export default Step2;
