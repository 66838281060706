import { CREDENTIALING_NETWORK_INFORMATION_LINK, MAIL_TO_PROV_REQUESTS_LINK } from 'constants/links';

function SuccessPageExtraInfo() {
  return (
    <>
      <bc-typography>
        Please submit your W-9 form now for efficient processing of your application. Forms submitted within 10 minutes
        will have faster processing times.
      </bc-typography>
      <bc-typography>
        You can click the button below to open an email window, or email your W-9 with the following subject line to:
        <a href={MAIL_TO_PROV_REQUESTS_LINK} target="_blank" rel="noreferrer">
          ProvRequests@bcbsnc.com.
        </a>
      </bc-typography>
      <bc-typography>
        Please format the subject line of your email to read: Individual Practitioner W9 | Name | NPI
      </bc-typography>
      <bc-typography>
        <i> Ex: Individual Practitioner W9 | John Doe | 1234567891</i>
      </bc-typography>
      <button
        type="button"
        className="button button--secondary button--regular"
        onClick={() => window.open(MAIL_TO_PROV_REQUESTS_LINK, '_blank')}
      >
        Email W-9 Form
      </button>
      <bc-typography>
        <strong>
          Please Note: Enrollment does not establish you or your practice as an in-network Blue Cross NC provider.
        </strong>
      </bc-typography>
      <bc-typography>
        Separate processes are required for credentialing and contracting.
        <a href={CREDENTIALING_NETWORK_INFORMATION_LINK} target="_blank" rel="noreferrer">
          View credentialing and network participation information.
        </a>
      </bc-typography>
      <bc-typography>
        For additional information, please contact our Network Management Provider Services line at 1-800-777-1643 and
        select option 6.
      </bc-typography>
    </>
  );
}

export default SuccessPageExtraInfo;
