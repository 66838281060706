import * as Yup from 'yup';
import { getInitialValues, getYupValidationSchema } from 'utilities/miscHelpers';
import { NPIValidation, phoneValidation } from 'utilities/customValidators';
import { HoneypotFieldSchema } from 'utilities/formHelpers';

export const formSchema = {
  HoneypotFieldSchema,
  oid: {
    emailLabel: 'Organization ID',
    name: 'oid',
    label: 'oid',
    initialValue: process.env.REACT_APP_SALESFORCE_ORG_ID,
    validation: null,
  },
  your_first_name: {
    emailLabel: 'Your First Name',
    name: 'your_first_name',
    label: 'First Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  your_last_name: {
    emailLabel: 'Your Last Name',
    name: 'your_last_name',
    label: 'Last Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  company: {
    emailLabel: 'Company',
    name: 'company',
    label: 'Provider Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  tax_id: {
    emailLabel: 'Tax ID',
    name: 'tax_id',
    label: 'Tax ID',
    initialValue: '',
    validation: Yup.string()
      .required('This field is required')
      .min(9, 'Tax ID must be 9 digits')
      .matches(/^\d+$/, 'Tax ID must only contain numbers'),
  },
  provider_email_address: {
    emailLabel: 'Provider Email',
    name: 'provider_email_address',
    label: 'Provider Email Address',
    initialValue: '',
    validation: Yup.string().required('This field is required').email('Please enter a valid email address'),
  },
  phone: {
    emailLabel: 'Provider Phone',
    name: 'phone',
    label: 'Provider Phone Number',
    initialValue: '',
    validation: phoneValidation,
  },
  // NOTE: converting "NPI" and "NPINumber" fields to be "solo_npi" and "group_npi" fields to match the data field names in Salesforce
  NPI: {
    emailLabel: 'NPI',
    name: 'NPI',
    label: 'Enter your National Provider Identifier (NPI)',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  NPINumber: {
    emailLabel: 'NPI Number',
    name: 'NPINumber',
    label: 'NPI number',
    initialValue: '',
    validation: NPIValidation,
  },
  additional_group_npi_yn: {
    emailLabel: 'Has additional Group NPI(s)',
    name: 'additional_group_npi_yn',
    label: 'Do you have additional Group NPI(s)?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  additional_group_npi: {
    emailLabel: 'Additional Group NPI #(s)',
    name: 'additional_group_npi',
    label: 'Additional Group NPI #(s)',
    initialValue: '',
    validation: Yup.string().when('additional_group_npi_yn', {
      is: 'yes',
      then: () => Yup.string().required('Please list your additional NPI(s)'),
    }),
  },
  recordtype: {
    emailLabel: 'Record Type',
    name: 'recordtype',
    label: 'recordType',
    initialValue: '0126g000000OcFG',
    validation: null,
  },
  lead_source: {
    emailLabel: 'Lead Source',
    name: 'lead_source',
    label: 'lead_source',
    initialValue: 'D-SNP',
    validation: null,
  },
  source_system: {
    emailLabel: 'Source System',
    name: 'source_system',
    label: 'Source System',
    initialValue: '00N6g00000TMg5N',
    validation: null,
  },
  // TODO - does this need to be a randomly-generated number???
  confirmation_number: {
    emailLabel: 'Confirmation Number',
    name: 'confirmation_number',
    label: 'Confirmation Number',
    initialValue: '[random:number]',
    validation: null,
  },
};

export const initialValues = getInitialValues(formSchema);

export const validationSchema = getYupValidationSchema(formSchema);
