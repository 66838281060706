import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import { stateOptions } from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerAcquisitionForm/schema';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';
import { faxFormatHoverTitle, phoneFormatHoverTitle } from 'constants/hoverTitles';

function Step3({ values, errors, touched }: any) {
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Billing information
      </bc-typography>
      <Fieldset>
        <Legend required>Practice billing information:</Legend>
        <BCInput
          width="75"
          label={formSchema.billingStreetAddress.label}
          name={formSchema.billingStreetAddress.name}
          value={values.billingStreetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingStreetAddress.name)}
        />
        <BCInput
          width="75"
          label={formSchema.billingSuiteAptUnitFloor.label}
          name={formSchema.billingSuiteAptUnitFloor.name}
          value={values.billingSuiteAptUnitFloor}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingSuiteAptUnitFloor.name)}
        />
        <BCInput
          width="75"
          label={formSchema.billingCity.label}
          name={formSchema.billingCity.name}
          value={values.billingCity}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingCity.name)}
        />

        <Fieldset noMargin>
          <Legend required>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.billingState.label}
            name={formSchema.billingState.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.billingState.name)}
          />
        </Fieldset>

        <BCInput
          width="75"
          label={formSchema.billingZip.label}
          name={formSchema.billingZip.name}
          maxlength={5}
          value={values.billingZip}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingZip.name)}
        />
        <BCInput
          width="75"
          label={formSchema.billingPhone.label}
          name={formSchema.billingPhone.name}
          maxlength={12}
          hoverTitle={phoneFormatHoverTitle}
          value={values.billingPhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingPhone.name)}
        />
        <BCInput
          width="75"
          label={formSchema.billingFax.label}
          name={formSchema.billingFax.name}
          hoverTitle={faxFormatHoverTitle}
          value={values.billingFax}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingFax.name)}
        />
      </Fieldset>
      <Fieldset>
        <Legend required>Billing contact information:</Legend>
        <BCInput
          width="75"
          label={formSchema.billingContactName.label}
          name={formSchema.billingContactName.name}
          value={values.billingContactName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingContactName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.billingContactEmail.label}
          name={formSchema.billingContactEmail.name}
          value={values.billingContactEmail}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingContactEmail.name)}
        />
        <BCInput
          width="75"
          label={formSchema.billingContactPhone.label}
          name={formSchema.billingContactPhone.name}
          hoverTitle={phoneFormatHoverTitle}
          maxlength={12}
          value={values.billingContactPhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingContactPhone.name)}
        />
        <BCInput
          width="75"
          label={formSchema.billingContactFax.label}
          name={formSchema.billingContactFax.name}
          value={values.billingContactFax}
          errorMessage={generateErrorMessage(errors, touched, formSchema.billingContactFax.name)}
        />
      </Fieldset>
    </>
  );
}

export default Step3;
