import RequiredAsterisk from 'components/validation/RequiredAsterisk';
import { ReactElement } from 'react';

function Legend({ required, gutterBottom, children }: any) {
  const renderLegend = (): ReactElement => (
    <legend className={gutterBottom ? 'gutter-bottom' : ''}>
      {required && <RequiredAsterisk />} <strong>{children}</strong>
    </legend>
  );

  if (children) return renderLegend();

  return <div />;
}

export default Legend;
