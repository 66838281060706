import { useField } from 'formik';
import 'styles/checkbox.scss';
import InputError from 'components/validation/InputError';
import { useState } from 'react';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';

function BCCheckboxGroup({ label, name, values, checkboxes, errorMessage, validation, limit }: any) {
  const [totalChecked, setTotalChecked] = useState(0);

  const [field] = useField(name);

  const handleBlur = (e: any) => {
    field.onBlur(e);
  };

  const handleChange = (e: any) => {
    // if there is a limit
    if (limit) {
      // if checking box (adding to totalChecked)
      if (e.target.checked) {
        // if totalChecked is less than the limit, then allow checking to continue
        if (totalChecked < parseInt(limit, 10)) {
          setTotalChecked(totalChecked + 1);
          field.onChange(e);
        }
      }
      // if un-checking box (subtracting from totalChecked)
      else {
        setTotalChecked(totalChecked - 1);
        field.onChange(e);
      }
    } else {
      field.onChange(e);
    }
  };

  const renderCheckboxes = () =>
    checkboxes.map((checkbox: any) => (
      <div key={`checkbox-${checkbox.value}`}>
        <label className="checkbox__label" htmlFor={`checkbox-${checkbox.value}`}>
          <input
            type="checkbox"
            checked={values[name]?.includes(checkbox.value)}
            disabled={checkbox.disabled}
            className="checkbox__input"
            value={checkbox.value}
            id={`checkbox-${checkbox.value}`}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {checkbox.label}
        </label>
      </div>
    ));

  return (
    <Fieldset>
      <Legend required={validation} gutterBottom>
        {label}
      </Legend>
      <InputError errorMessage={errorMessage} />
      {renderCheckboxes()}
    </Fieldset>
  );
}

export default BCCheckboxGroup;
