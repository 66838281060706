import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { taxInfoRadios } from 'constants/formData';
import BCCheckboxGroup from 'components/custom-fields/BCCheckboxGroup';
import { formSchema } from 'forms/individualPractitionerForm/schema';
import { MAIL_TO_PROV_REQUESTS_SUBJECT_LINK, PROVIDER_GROUP_ENROLLMENT_LINK, W9_LINK } from 'constants/links';

function Step2({ values, errors, touched }: any) {
  const renderImportantTipsSection = () => {
    if (values.taxInfo === 'solo') {
      return (
        <>
          <bc-typography>
            To ensure compliance with Internal Revenue Service (IRS) regulations, please provide practitioner's tax ID
            so that we may process the application and complete enrollment. Enrollment applications without a completed
            W-9 will not be processed.
          </bc-typography>
          <div className="blue-card">
            <bc-typography weight="bold">Important Tips:</bc-typography>
            <bc-list type="ol">
              <li>
                Complete a{' '}
                <a className="link" href={W9_LINK} target="_blank" rel="noreferrer">
                  W-9
                </a>{' '}
                with relevant billing entity details.
              </li>
              <li>
                Email completed W-9 Form to{' '}
                <a className="link" href={MAIL_TO_PROV_REQUESTS_SUBJECT_LINK} target="_blank" rel="noreferrer">
                  ProvRequests@bcbsnc.com
                </a>
              </li>
              <li>Email subject line must include the practitioner's NPI number as well as First and Last name</li>
            </bc-list>
          </div>
        </>
      );
    }
  };
  const renderSoloPractitionerInputs = () => {
    if (values.taxInfo === 'solo') {
      return (
        <BCCheckboxGroup
          checkboxes={[
            {
              label: formSchema.hasCompletedW9.label,
              value: 'hasCompletedW9',
            },
          ]}
          values={values}
          name={formSchema.hasCompletedW9.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.hasCompletedW9.name)}
          validation={formSchema.hasCompletedW9.validation}
        />
      );
    }
  };

  const renderProviderGroupInputs = () => {
    if (values.groupAlreadyEnrolled?.includes('groupAlreadyEnrolled')) {
      return (
        <div className="mt-32">
          <BCInput
            width="75"
            label={formSchema.NPIProviderGroup.label}
            name={formSchema.NPIProviderGroup.name}
            maxlength={10}
            value={values.NPIProviderGroup}
            errorMessage={generateErrorMessage(errors, touched, formSchema.NPIProviderGroup.name)}
          />
          <BCInput
            width="75"
            maxlength="5"
            label={formSchema.providerGroupPreferredPPN.label}
            name={formSchema.providerGroupPreferredPPN.name}
            value={values.providerGroupPreferredPPN}
            errorMessage={generateErrorMessage(errors, touched, formSchema.providerGroupPreferredPPN.name)}
          />
          <BCInput
            width="75"
            label={formSchema.providerGroupTaxID.label}
            name={formSchema.providerGroupTaxID.name}
            maxlength="11"
            value={values.providerGroupTaxID}
            errorMessage={generateErrorMessage(errors, touched, formSchema.providerGroupTaxID.name)}
          />
          <BCInput
            width="75"
            label={formSchema.providerGroupEmail.label}
            name={formSchema.providerGroupEmail.name}
            value={values.providerGroupEmail}
            errorMessage={generateErrorMessage(errors, touched, formSchema.providerGroupEmail.name)}
          />
        </div>
      );
    }
  };

  const renderGroupPractitionerInputs = () => {
    if (values.taxInfo === 'withGroup') {
      return (
        <>
          <BCCheckboxGroup
            checkboxes={[
              {
                label: formSchema.groupAlreadyEnrolled.label,
                value: 'groupAlreadyEnrolled',
              },
            ]}
            values={values}
            name={formSchema.groupAlreadyEnrolled.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.groupAlreadyEnrolled.name)}
            validation={formSchema.groupAlreadyEnrolled.validation}
          />
          <bc-typography weight="bold">
            Before adding a practitioner to a group, a group must be enrolled using the following link:{' '}
            <a href={PROVIDER_GROUP_ENROLLMENT_LINK} target="_blank" rel="noreferrer">
              Provider Group Enrollment Application
            </a>
          </bc-typography>

          {renderProviderGroupInputs()}
        </>
      );
    }
  };

  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Tax Information
      </bc-typography>

      <BCRadioGroup
        label="Choose one"
        values={values}
        radios={taxInfoRadios}
        name={formSchema.taxInfo.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.taxInfo.name)}
        validation={formSchema.taxInfo.validation}
      />
      {renderImportantTipsSection()}
      <div className="mt-32">
        <BCInput
          width="75"
          label={formSchema.NPIPractitioner.label}
          name={formSchema.NPIPractitioner.name}
          value={values.NPIPractitioner}
          maxlength={10}
          errorMessage={generateErrorMessage(errors, touched, formSchema.NPIPractitioner.name)}
        />
      </div>
      {renderSoloPractitionerInputs()}
      {renderGroupPractitionerInputs()}
    </>
  );
}

export default Step2;
