export enum FormTitles {
  CreditableCoverage = 'Certificate of Creditable Coverage',
  FraudAndAbuse = 'Fraud and Abuse Report Form',
  IndividualPractitionerEnrollment = 'Individual Practitioner Enrollment Application',
  ModelCare = 'Model Care Attestation',
  NursingAdvocate = 'Nurse Advocate Contact Form',
  ProviderAcquisition = 'Provider Acquisition Form',
  ProviderGroupEnrollment = 'Provider Group Enrollment Form',
}

export type FieldStructureForEmailType = {
  label: string;
  value: any;
  fieldName: string;
};
