import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import { completedCredentialingRadios, credentialingRadios } from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/individualPractitionerForm/schema';
import { CAQH_FAQ_LINK, CAQH_PROVIEW_APPLICATION_LINK } from 'constants/links';

function Step1({ values, errors, touched }: any) {
  const renderCompletedCredentialing = () => {
    if (values.credentialing === 'participating') {
      return (
        <BCRadioGroup
          label={formSchema.completedCredentialing.label}
          values={values}
          radios={completedCredentialingRadios}
          name={formSchema.completedCredentialing.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.completedCredentialing.name)}
          validation={formSchema.completedCredentialing.validation}
        />
      );
    }
  };

  const renderCAQHIDRequired = () => {
    if (values.completedCredentialing === 'yes' && values.credentialing === 'participating') {
      return (
        <BCInput
          maxlength="8"
          width="75"
          label={formSchema.CAQHIDRequired.label}
          name={formSchema.CAQHIDRequired.name}
          value={values.CAQHIDRequired}
          errorMessage={generateErrorMessage(errors, touched, formSchema.CAQHIDRequired.name)}
        />
      );
    }
  };

  const renderCAQHIDOptional = () => {
    if (values.completedCredentialing === 'noNotSure' && values.credentialing === 'participating') {
      return (
        <>
          <bc-typography>
            Blue Cross NC has partnered with the Council for Affordable Quality Healthcare (CAQH) to make the
            application process easier for you. Instead of completing and sending different applications to various
            payers, you complete one credentialing application that is shared with multiple payers. The result is
            expedited credentialing processing time, less administrative burden, and more time with patients.
          </bc-typography>
          <bc-typography>
            Need more information? To learn more about CAQH and the CAQH Universal Credentialing Data source (UCD), you
            can read our{' '}
            <a className="link" href={CAQH_FAQ_LINK} target="_blank" rel="noreferrer">
              frequently asked questions
            </a>{' '}
            for more information.
          </bc-typography>
          <bc-button variant="link" onClick={() => window.open(CAQH_PROVIEW_APPLICATION_LINK, '_blank')}>
            Start the CAQH ProView Application Process
          </bc-button>
          <BCInput
            width="75"
            maxlength="8"
            label={formSchema.CAQHIDOptional.label}
            name={formSchema.CAQHIDOptional.name}
            value={values.CAQHIDOptional}
            errorMessage={generateErrorMessage(errors, touched, formSchema.CAQHIDOptional.name)}
          />
        </>
      );
    }
  };

  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Credentialing
      </bc-typography>
      <BCInput
        label={formSchema.HoneypotFieldSchema.label}
        name={formSchema.HoneypotFieldSchema.name}
        width="75"
        tabIndex="-500"
        value={values.HoneypotFieldSchema}
      />
      <>
        <BCRadioGroup
          label="Choose one"
          values={values}
          radios={credentialingRadios}
          name={formSchema.credentialing.name}
          errorMessage={generateErrorMessage(errors, touched, formSchema.credentialing.name)}
          validation={formSchema.credentialing.validation}
        />
        {renderCompletedCredentialing()}
        {renderCAQHIDRequired()}
        {renderCAQHIDOptional()}
      </>
    </>
  );
}

export default Step1;
