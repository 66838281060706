import { Formik, Form } from 'formik';
import Step0 from 'forms/providerAcquisitionForm/steps/Step0';
import Step1 from 'forms/providerAcquisitionForm/steps/Step1';
import Step2 from 'forms/providerAcquisitionForm/steps/Step2';
import Step3 from 'forms/providerAcquisitionForm/steps/Step3';
import Step4 from 'forms/providerAcquisitionForm/steps/Step4';
import Step5 from 'forms/providerAcquisitionForm/steps/Step5';
import { ReactElement, useEffect, useState } from 'react';
import FormWrapper from 'components/FormWrapper';
import MultiStepButtons from 'components/MultiStepButtons';
import { referenceCodeSchema, setLoader } from 'utilities/miscHelpers';
import { sendEmail } from 'utilities/apis';
import { useStore } from 'store';
import { Action } from 'constants/store';
import { handleFormSubmission } from 'utilities/formHelpers';
import { BcncAnalyticsEventActions, BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { FormTitles } from 'types/FormTypes';
import { EmailRecipients, EmailSubjects } from 'types/EmailTypes';
import { BCBSNC_NO_REPLY_EMAIL } from 'constants/EmailConstants';
import { fireBcncAnalyticsEvent } from 'utilities/eventHelpers';
import { getStepValidationSchema, handleNextStep, handlePreviousStep } from 'utilities/multiStepHelpers';
import PageTitle from 'components/PageTitle';
import { nanoid } from 'nanoid';
import SuccessPage from 'components/SuccessPage';
import SuccessPageExtraInfo from 'forms/providerAcquisitionForm/SuccessPageExtraInfo';
import { emailTemplateRender, formSchemasToEmailTemplates } from 'utilities/emailTemplateHelpers';
import { providerAcquisitionSchema, step1Schema, step2Schema, step3Schema, step4Schema, step5Schema } from './schema';

function ProviderAcquisitionForm(): ReactElement {
  const [currentStep, setCurrentStep] = useState(0);

  const totalSteps = 5;

  const {
    dispatch,
    state: { formSubmittedSuccessfully, isLoading },
  } = useStore();

  useEffect(() => {
    // update form title
    dispatch({
      type: Action.UPDATE_FORM_TITLE,
      formTitle: FormTitles.ProviderAcquisition,
    });

    // set previous url
    dispatch({ type: Action.UPDATE_PREVIOUS_URL, previousUrl: document.referrer });
  }, [dispatch]);

  const handleSubmit = async (values: any): Promise<void> => {
    // set loading to true
    setLoader(dispatch, true);

    // fire bcanalytics form submit
    fireBcncAnalyticsEvent(BcncAnalyticsEventActions.FormSubmitClick, BcncAnalyticsEventFormNames.ProviderAcquisition);

    // get random unique string for referenceCode
    const referenceCode = nanoid();

    const recipient = EmailRecipients.ProviderAcquisition;
    const sender = BCBSNC_NO_REPLY_EMAIL;
    const subject = EmailSubjects.ProviderAcquisition;
    const emailTemplates = formSchemasToEmailTemplates([
      step1Schema,
      step2Schema,
      step3Schema,
      step4Schema,
      step5Schema,
      referenceCodeSchema(referenceCode),
    ]);
    const tempValues = values;
    tempValues.referenceCode = referenceCode;
    const emailData = emailTemplateRender(emailTemplates.html, emailTemplates.text, tempValues);
    const response = await sendEmail(emailData, recipient, sender, subject);

    // handles form submission whether its success or fail
    handleFormSubmission(BcncAnalyticsEventFormNames.ProviderAcquisition, response, dispatch, referenceCode);

    setLoader(dispatch, false);
  };

  const renderCurrentStepForm = (values: any, errors: any, touched: any): ReactElement | null => {
    switch (currentStep) {
      case 0:
        return <Step0 />;
      case 1:
        return <Step1 values={values} errors={errors} touched={touched} />;
      case 2:
        return <Step2 values={values} errors={errors} touched={touched} />;
      case 3:
        return <Step3 values={values} errors={errors} touched={touched} />;
      case 4:
        return <Step4 values={values} errors={errors} touched={touched} />;
      case 5:
        return <Step5 values={values} errors={errors} touched={touched} />;
      default:
        return null;
    }
  };

  const renderForm = ({ values, errors, touched }: any): ReactElement | null =>
    !formSubmittedSuccessfully && !isLoading ? (
      <Form>
        <div className="form-content-width">{renderCurrentStepForm(values, errors, touched)}</div>
        <MultiStepButtons
          values={values}
          handleNextStep={() =>
            handleNextStep({
              formName: BcncAnalyticsEventFormNames.ProviderGroupEnrollment,
              steps: {
                currentStep,
                totalSteps,
                setCurrentStep,
              },
            })
          }
          handlePreviousStep={() =>
            handlePreviousStep(currentStep, setCurrentStep, BcncAnalyticsEventFormNames.ProviderGroupEnrollment)
          }
          currentStep={currentStep}
          totalSteps={totalSteps}
          stepValidation={getStepValidationSchema(currentStep, FormTitles.ProviderAcquisition)}
        />
      </Form>
    ) : null;

  const renderMultiStepForm = () => (
    <FormWrapper totalSteps={totalSteps} currentStep={currentStep} pageTitle={FormTitles.ProviderAcquisition}>
      <PageTitle title={FormTitles.ProviderAcquisition} />
      <Formik
        initialValues={providerAcquisitionSchema}
        validationSchema={getStepValidationSchema(currentStep, FormTitles.ProviderAcquisition)}
        onSubmit={handleSubmit}
      >
        {renderForm}
      </Formik>
      <SuccessPage formName={FormTitles.ProviderAcquisition}>
        <SuccessPageExtraInfo />
      </SuccessPage>
    </FormWrapper>
  );

  return <>{renderMultiStepForm()}</>;
}

export default ProviderAcquisitionForm;
