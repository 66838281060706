import IndexRouter from 'IndexRouter';
import CreditableCoverageForm from 'forms/creditableCoverageForm/creditableCoverageForm';
import FraudAndAbuseReportForm from 'forms/fraudAndAbuseReportForm/fraudAndAbuseReportForm';
import IndividualPractitionerForm from 'forms/individualPractitionerForm/individualPractitionerForm';
import ModelCareAttestationForm from 'forms/modelCareAttestationForm/modelCareAttestationForm';
import NurseAdvocateContactForm from 'forms/nurseAdvocateContactForm/nurseAdvocateContactForm';
import ProviderAcquisitionForm from 'forms/providerAcquisitionForm/providerAcquisitionForm';
import ProviderGroupEnrollmentForm from 'forms/providerGroupEnrollmentForm/providerGroupEnrollmentForm';
import React, { ReactElement, Reducer, useMemo, useReducer } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppContext, initialState, reducer } from 'store';
import { InitialStateType, ReducerType } from 'types/storeTypes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <IndexRouter />,
  },
  {
    path: '/individual-practitioner-form',
    element: <IndividualPractitionerForm />,
  },
  {
    path: '/creditable-coverage-form',
    element: <CreditableCoverageForm />,
  },
  {
    path: '/model-care-attestation-form',
    element: <ModelCareAttestationForm />,
  },
  {
    path: '/nurse-advocate-contact-form',
    element: <NurseAdvocateContactForm />,
  },
  {
    path: '/fraud-and-abuse-report-form',
    element: <FraudAndAbuseReportForm />,
  },
  {
    path: '/provider-acquisition-form',
    element: <ProviderAcquisitionForm />,
  },
  {
    path: '/provider-group-enrollment-form',
    element: <ProviderGroupEnrollmentForm />,
  },
]);

function AppBase(): ReactElement {
  const [state, dispatch] = useReducer<Reducer<InitialStateType, ReducerType>>(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <HelmetProvider>
      <AppContext.Provider value={value}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </AppContext.Provider>
    </HelmetProvider>
  );
}

export default AppBase;
