import React, { StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import AppBase from './components/AppBase';
import 'styles/honeypot.scss';

// TODO - fix "any" type
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'bc-button': any;
      'bc-grid': any;
      'bc-grid-item': any;
      'bc-header': any;
      'bc-image': any;
      'bc-icon': any;
      'bc-typography': any;
      'bc-progress-tracker': any;
      'bc-select': any;
      'bc-option': any;
      'bc-input': any;
      'bc-list': any;
      'bc-global-alert': any;
      'bc-loader': any;
    }
  }
}

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  hydrateRoot(rootElement, <AppBase />);
} else if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <AppBase />
    </StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
