import BCInput from 'components/custom-fields/BCInput';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerAcquisitionForm/schema';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';

function Step1({ values, errors, touched }: any) {
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Provider information
      </bc-typography>
      <Fieldset>
        <Legend required>Provider information:</Legend>
        <BCInput
          label={formSchema.HoneypotFieldSchema.label}
          name={formSchema.HoneypotFieldSchema.name}
          width="75"
          tabIndex="-500"
          value={values.HoneypotFieldSchema}
        />
        <BCInput
          width="75"
          label={formSchema.newLegalName.label}
          name={formSchema.newLegalName.name}
          value={values.newLegalName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.newLegalName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.priorLegalName.label}
          name={formSchema.priorLegalName.name}
          value={values.priorLegalName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.priorLegalName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.newTaxID.label}
          name={formSchema.newTaxID.name}
          value={values.newTaxID}
          errorMessage={generateErrorMessage(errors, touched, formSchema.newTaxID.name)}
        />
        <BCInput
          width="75"
          label={formSchema.priorTaxID.label}
          name={formSchema.priorTaxID.name}
          value={values.priorTaxID}
          errorMessage={generateErrorMessage(errors, touched, formSchema.priorTaxID.name)}
        />
        <BCInput
          width="75"
          label={formSchema.newNPI.label}
          name={formSchema.newNPI.name}
          maxlength={10}
          value={values.newNPI}
          errorMessage={generateErrorMessage(errors, touched, formSchema.newNPI.name)}
        />
        <BCInput
          width="75"
          label={formSchema.priorNPI.label}
          name={formSchema.priorNPI.name}
          maxlength={10}
          value={values.priorNPI}
          errorMessage={generateErrorMessage(errors, touched, formSchema.priorNPI.name)}
        />
        <BCInput
          width="75"
          label={formSchema.newDBAName.label}
          name={formSchema.newDBAName.name}
          value={values.newDBAName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.newDBAName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.priorDBAName.label}
          name={formSchema.priorDBAName.name}
          value={values.priorDBAName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.priorDBAName.name)}
        />
        <BCInput
          width="75"
          label={formSchema.newCLIANumber.label}
          name={formSchema.newCLIANumber.name}
          value={values.newCLIANumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.newCLIANumber.name)}
        />
        <BCInput
          width="75"
          label={formSchema.priorCLIANumber.label}
          name={formSchema.priorCLIANumber.name}
          value={values.priorCLIANumber}
          errorMessage={generateErrorMessage(errors, touched, formSchema.priorCLIANumber.name)}
        />
      </Fieldset>
    </>
  );
}

export default Step1;
