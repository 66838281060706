export type BcncAnalyticsEventDetailType = {
  action: string;
  form_name: string;
  form_source: string;
  error_message?: string;
};

export enum BcncAnalyticsEventActions {
  FormStart = 'form-form start',
  FormSubmitClick = 'form-form submit click',
  FormSubmitError = 'form-form submit error',
  FormSubmitSuccess = 'form-form submit success',
  FormContinueStep2 = 'form-form continue to step 2 click',
  FormContinueStep3 = 'form-form continue to step 3 click',
  FormContinueStep4 = 'form-form continue to step 4 click',
  FormContinueStep5 = 'form-form continue to step 5 click',
  FormBackGetStarted = 'form-form back to get started',
  FormBackStep1 = 'form-form back to step 1 click',
  FormBackStep2 = 'form-form back to step 2 click',
  FormBackStep3 = 'form-form back to step 3 click',
  FormBackStep4 = 'form-form back to step 4 click',
}

export enum BcncAnalyticsEventFormNames {
  FraudAndAbuse = 'fraud-and-abuse-report-form',
  ModelCare = 'model-care attestation',
  NurseAdvocateContact = 'nurse-advocate-contact',
  CreditableCoverage = 'certificate-of-creditable-coverage',
  IndividualPractitionerEnrollment = 'individual-practitioner-enrollment-application',
  ProviderGroupEnrollment = 'provider-group-enrollment-application',
  ProviderAcquisition = 'provider-acquisition-form',
}
