import { BcncAnalyticsEventActions, BcncAnalyticsEventDetailType } from 'types/BcncAnalyticsTypes';
import { BCNC_ANALYTICS_FORM_SOURCE } from 'constants/BcncAnalyticsConstants';

export const fireBcncAnalyticsEvent = (action: string, name: string, errorMessage?: string) => {
  const eventDetail: BcncAnalyticsEventDetailType = {
    action,
    form_name: name,
    form_source: BCNC_ANALYTICS_FORM_SOURCE,
  };

  if (errorMessage) {
    eventDetail.error_message = errorMessage;
  }

  dispatchEvent(
    new CustomEvent('bcncanalyticsevent', {
      detail: eventDetail,
    }),
  );
};

export const registerBcAnalyticsEventListenerStartForm = (ref: any, formName: string): void => {
  if (ref.current) {
    // @ts-ignore
    ref.current.addEventListener('focus', () => fireBcncAnalyticsEvent(BcncAnalyticsEventActions.FormStart, formName), {
      once: true,
    });
  }
};

export const getFormStepContinueAction = (currentStep: number): string => {
  switch (currentStep) {
    case 1:
      return BcncAnalyticsEventActions.FormContinueStep2;
    case 2:
      return BcncAnalyticsEventActions.FormContinueStep3;
    case 3:
      return BcncAnalyticsEventActions.FormContinueStep4;
    case 4:
      return BcncAnalyticsEventActions.FormContinueStep5;
    default:
      return '';
  }
};

export const getFormStepBackAction = (currentStep: number): string => {
  switch (currentStep) {
    case 1:
      return BcncAnalyticsEventActions.FormBackGetStarted;
    case 2:
      return BcncAnalyticsEventActions.FormBackStep1;
    case 3:
      return BcncAnalyticsEventActions.FormBackStep2;
    case 4:
      return BcncAnalyticsEventActions.FormBackStep3;
    case 5:
      return BcncAnalyticsEventActions.FormBackStep4;
    default:
      return '';
  }
};
