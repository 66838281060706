import FailAlert from 'components/validation/FailAlert';
import BCInput from 'components/custom-fields/BCInput';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/individualPractitionerForm/schema';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';
import { phoneFormatHoverTitle } from 'constants/hoverTitles';

function Step5({ values, errors, touched }: any) {
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Enroll Practitioner
      </bc-typography>
      <bc-typography>
        The undersigned hereby represents and warrants that they have appropriate authority to bind the practice and
        certifies that the item(s) indicated on this request accurately reflect the information provided. The
        undersigned further certifies that they have completed this form in its entirety, and understand that an
        incomplete form may delay processing.
      </bc-typography>
      <bc-typography>
        Final approval&nbsp;is the sole discretion of Blue Cross NC and is subject to Blue Cross NC&nbsp;verification of
        the application responses
      </bc-typography>

      <FailAlert />
      <Fieldset>
        <Legend required>Fill out the fields below</Legend>
        <BCInput
          width="75"
          label={formSchema.digitalSignature.label}
          name={formSchema.digitalSignature.name}
          value={values.digitalSignature}
          errorMessage={generateErrorMessage(errors, touched, formSchema.digitalSignature.name)}
        />
        <BCInput
          width="75"
          label={formSchema.contactPhone.label}
          name={formSchema.contactPhone.name}
          hoverTitle={phoneFormatHoverTitle}
          maxlength={12}
          type="tel"
          value={values.contactPhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.contactPhone.name)}
        />
      </Fieldset>
    </>
  );
}

export default Step5;
