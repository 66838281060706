import * as Yup from 'yup';
import { getInitialValues, getYupValidationSchema } from 'utilities/miscHelpers';
import { getDateFormattedForSchema, HoneypotFieldSchema } from 'utilities/formHelpers';
import {
  NPIValidation,
  checkboxValidation,
  dateValidationWithOffset,
  hasCompletedW9Validation,
  optionalCLIANumberValidation,
  optionalTextNumericValidation,
  phoneValidation,
  providerGroupPreferredPPNValidation,
  servicesLocationValidation,
  taxIDValidation,
  textNumericValidation,
  zipValidation,
} from 'utilities/customValidators';

// TODO - should we add the radio & checkbox array inputs to the schema as well?

export const step1Schema = {
  HoneypotFieldSchema,
  step1Description: {
    label: 'Credentialing',
    emailLabel: 'Credentialing',
    initialValue: 'Credentialing',
    name: '',
    type: 'description',
  },
  credentialing: {
    emailLabel: 'Credentialing',
    name: 'credentialing',
    label: 'Credentialing',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  completedCredentialing: {
    emailLabel: 'Has completed credentialing',
    name: 'completedCredentialing',
    label: 'Have you completed your credentialing with Blue Cross NC?',
    initialValue: '',
    validation: Yup.string().when('credentialing', {
      is: 'participating',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  CAQHIDRequired: {
    emailLabel: 'CAQH ID Number',
    name: 'CAQHIDRequired',
    label: 'CAQH ID Number',
    initialValue: '',
    validation: Yup.string().when(['credentialing', 'completedCredentialing'], {
      is: (credentialing: string, completedCredentialing: string) =>
        credentialing === 'participating' && completedCredentialing === 'yes',
      then: () => textNumericValidation.required('This field is required').min(8, 'Must be exactly 8 digits'),
    }),
  },
  CAQHIDOptional: {
    emailLabel: 'CAQH ID Number (if received)',
    name: 'CAQHIDOptional',
    label: 'CAQH ID Number (if received)',
    initialValue: '',
    validation: optionalTextNumericValidation(8),
  },
};

export const step2Schema = {
  step2Description: {
    label: 'Tax Information',
    emailLabel: 'Tax Information',
    initialValue: 'Tax Information',
    name: '',
    type: 'description',
  },
  taxInfo: {
    emailLabel: 'Tax Information',
    name: 'taxInfo',
    label: 'Tax Information',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  NPIPractitioner: {
    emailLabel: 'NPI Type I - Practitioner',
    name: 'NPIPractitioner',
    label: 'NPI Type I - Practitioner',
    initialValue: '',
    validation: NPIValidation,
  },
  groupAlreadyEnrolled: {
    emailLabel: 'Group already enrolled or group enrollment form already submitted',
    name: 'groupAlreadyEnrolled',
    label: 'Group already enrolled or group enrollment form has already been submitted.',
    initialValue: [],
    validation: Yup.array().when('taxInfo', {
      is: 'withGroup',
      then: () => checkboxValidation(1, 'Please check the field'),
    }),
  },
  NPIProviderGroup: {
    emailLabel: 'NPI Type II - Provider Group',
    name: 'NPIProviderGroup',
    label: 'NPI Type II - Provider Group',
    initialValue: '',
    validation: Yup.string().when('taxInfo', {
      is: 'withGroup',
      then: () => NPIValidation,
    }),
  },
  providerGroupPreferredPPN: {
    emailLabel: "Provider Group's Blue Cross Preferred PPN",
    name: 'providerGroupPreferredPPN',
    label: "Provider Group's Blue Cross Preferred PPN",
    initialValue: '',
    validation: Yup.string().when('taxInfo', {
      is: 'withGroup',
      then: () => providerGroupPreferredPPNValidation,
    }),
  },
  providerGroupTaxID: {
    emailLabel: "Provider Group's Tax ID",
    name: 'providerGroupTaxID',
    label: "Provider Group's Tax ID",
    initialValue: '',
    validation: Yup.string().when('taxInfo', {
      is: 'withGroup',
      then: () => taxIDValidation,
    }),
  },
  providerGroupEmail: {
    emailLabel: "Provider Group's Email Address",
    name: 'providerGroupEmail',
    label: "Provider Group's Email Address",
    initialValue: '',
    validation: Yup.string().when('taxInfo', {
      is: 'withGroup',
      then: () => Yup.string().required('This field is required').email('Please provide a valid email address'),
    }),
  },
  hasCompletedW9: {
    emailLabel: 'Has completed and emailed W-9 Form',
    name: 'hasCompletedW9',
    label: 'I have completed and emailed the W-9 Form to ProvRequests@bcbsnc.com.',
    initialValue: [],
    validation: Yup.array().when('taxInfo', {
      is: 'solo',
      then: () => hasCompletedW9Validation,
    }),
  },
};

export const step3Schema = {
  step3Description: {
    label: 'Practitioner Information',
    emailLabel: 'Practitioner Information',
    initialValue: 'Practitioner Information',
    name: '',
    type: 'description',
  },
  lastName: {
    emailLabel: 'Last Name',
    name: 'lastName',
    label: 'Last Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  firstName: {
    emailLabel: 'First Name',
    name: 'firstName',
    label: 'First Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  middleName: {
    emailLabel: 'Middle Name (optional)',
    name: 'middleName',
    label: 'Middle Name (Optional)',
    initialValue: '',
    validation: null,
  },
  degree: {
    emailLabel: 'Degree',
    name: 'degree',
    label: 'Degree',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  otherDegree: {
    emailLabel: 'Other Degree',
    name: 'otherDegree',
    label: 'Other Degree',
    initialValue: '',
    validation: Yup.string().when('degree', {
      is: 'other',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  providerType: {
    emailLabel: 'Provider Type',
    name: 'providerType',
    label: 'Provider Type',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  otherProviderType: {
    emailLabel: 'Other Provider Type',
    name: 'otherProviderType',
    label: 'Other Provider Type',
    initialValue: '',
    validation: Yup.string().when('providerType', {
      is: 'other',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  primarySpecialty: {
    emailLabel: 'Primary Specialty',
    name: 'primarySpecialty',
    label: 'Primary Specialty',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  otherPrimarySpecialty: {
    emailLabel: 'Other Primary Specialty',
    name: 'otherPrimarySpecialty',
    label: 'Other Primary Specialty',
    initialValue: '',
    validation: Yup.string().when('primarySpecialty', {
      is: 'other',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondarySpecialty: {
    emailLabel: 'Secondary Specialty',
    name: 'secondarySpecialty',
    label: 'Secondary Specialty',
    initialValue: '',
    validation: null,
  },
  otherSecondarySpecialty: {
    emailLabel: 'Other Secondary Specialty',
    name: 'otherSecondarySpecialty',
    label: 'Other Secondary Specialty',
    initialValue: '',
    validation: Yup.string().when('secondarySpecialty', {
      is: 'other',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  nonEnglishLanguagesSpoken: {
    emailLabel: 'Non-English Languages Spoken (up to 4)',
    name: 'nonEnglishLanguagesSpoken',
    label: 'Non-English Languages Spoken (select up to 4)',
    initialValue: [],
    validation: checkboxValidation(1, 'Please select at least 1 language'),
  },
  otherNonEnglishLanguagesSpoken: {
    emailLabel: 'Other Non-English Languages Spoken',
    name: 'otherNonEnglishLanguagesSpoken',
    label: 'Other Non-English Languages Spoken',
    initialValue: '',
    validation: Yup.string().when('nonEnglishLanguagesSpoken', {
      is: (nonEnglishLanguagesSpoken: string[]) => nonEnglishLanguagesSpoken.includes('otherLanguage'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  raceEthnicity: {
    emailLabel: 'Race / Ethnicity',
    name: 'raceEthnicity',
    label: 'Race / Ethnicity (select all that apply)',
    initialValue: [],
    validation: checkboxValidation(1, 'Please select at least 1 race / ethnicity'),
  },
  otherRaceEthnicity: {
    emailLabel: 'Other Race / Ethnicity',
    name: 'otherRaceEthnicity',
    label: 'Other Race / Ethnicity',
    initialValue: '',
    validation: Yup.string().when('raceEthnicity', {
      is: (raceEthnicity: string[]) => raceEthnicity.includes('otherRaceEthnicity'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  offerTelehealthServices: {
    emailLabel: 'Offers Telehealth Services',
    name: 'offerTelehealthServices',
    label: 'Do you offer Telehealth services?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  servicesLocation: {
    emailLabel: 'Location of rendered services',
    name: 'servicesLocation',
    label: 'Where will services be rendered?',
    initialValue: [],
    validation: servicesLocationValidation,
  },
  otherServicesLocation: {
    emailLabel: 'Other location of rendered services',
    name: 'otherServicesLocation',
    label: 'Other Services Location',
    initialValue: '',
    validation: Yup.string().when('servicesLocation', {
      is: (servicesLocation: string[]) => servicesLocation.includes('otherServicesLocation'),
      then: () => Yup.string().required('This field is required'),
    }),
  },
  licenseNumber: {
    emailLabel: 'License Number',
    name: 'licenseNumber',
    label: 'License Number',
    initialValue: '',
    validation: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, 'Must contain only letters or numbers')
      .required('This field is required'),
  },
  CLIANumber: {
    emailLabel: 'CLIA Number (if applicable)',
    name: 'CLIANumber',
    label: 'CLIA Number (if applicable)',
    initialValue: '',
    validation: optionalCLIANumberValidation,
  },
  medicaidProvider: {
    emailLabel: 'Medicaid Provider Number',
    name: 'medicaidProvider',
    label: 'Medicaid Provider Number',
    initialValue: '',
    validation: null,
  },
  providingServicesToMedicareMembers: {
    emailLabel: 'Will you be providing services to Commercial Members, Blue Medicare Members, or both?',
    name: 'providingServicesToMedicareMembers',
    label: 'Will you be providing services to Commercial Members, Blue Medicare Members, or both?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  medicareProviderNumber: {
    emailLabel: 'Medicare Provider Number',
    name: 'medicareProviderNumber',
    label: 'Medicare Provider Number',
    initialValue: '',
    validation: Yup.string().when('providingServicesToMedicareMembers', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  medicareGroupNumber: {
    emailLabel: 'Medicare Group Number',
    name: 'medicareGroupNumber',
    label: 'Medicare Group Number',
    initialValue: '',
    validation: Yup.string().when('providingServicesToMedicareMembers', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
};

export const step4Schema = {
  step4Description: {
    label: 'Primary Office Location Information',
    emailLabel: 'Primary Office Location Information',
    initialValue: 'Primary Office Location Information',
    name: '',
    type: 'description',
  },
  streetAddress: {
    emailLabel: 'Street Address',
    name: 'streetAddress',
    label: 'Street Address',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  suiteAptUnitFloor: {
    emailLabel: 'Suite, Apt., Unit, Floor, etc.',
    name: 'suiteAptUnitFloor',
    label: 'Suite, Apt., Unit, Floor, etc.',
    initialValue: '',
    validation: null,
  },
  city: {
    emailLabel: 'City',
    name: 'city',
    label: 'City',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  state: {
    emailLabel: 'State',
    name: 'state',
    label: 'State',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  zip: {
    emailLabel: 'Zip Code',
    name: 'zip',
    label: 'ZIP Code',
    initialValue: '',
    validation: zipValidation,
  },
  county: {
    emailLabel: 'County',
    name: 'county',
    label: 'County',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  primaryPhone: {
    emailLabel: 'Primary Phone',
    name: 'primaryPhone',
    label: 'Primary Location Phone Number',
    initialValue: '',
    validation: phoneValidation,
  },
  primaryEmail: {
    emailLabel: 'Primary Email',
    name: 'primaryEmail',
    label: 'Primary Location Email Address',
    initialValue: '',
    validation: Yup.string().required('This field is required').email('Please enter a valid email address'),
  },
  dateJoinedProviderGroup: {
    emailLabel: 'Date joined the Provider Group',
    name: 'dateJoinedProviderGroup',
    label: 'Date you joined the Provider Group',
    initialValue: '',
    min: getDateFormattedForSchema(),
    max: getDateFormattedForSchema(60),
    validation: dateValidationWithOffset(60),
  },
  haveHighTechImagingEquipment: {
    emailLabel: 'Location has high-tech imaging equipment',
    name: 'haveHighTechImagingEquipment',
    label: 'Does your location have high-tech imaging equipment? (PET, MRI, CT, Nuclear Medicine or Echocardiography)',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  secondaryLocation: {
    emailLabel: 'Has secondary location',
    name: 'secondaryLocation',
    label: 'Do you have a secondary location where you take patient appointments at least 2 days a week?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  secondaryLocationAddress: {
    emailLabel: 'Secondary Location Street Address',
    name: 'secondaryLocationAddress',
    label: 'Secondary Office Location Address',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationSuiteAptUnitFloor: {
    emailLabel: 'Secondary Location Suite, Apt., Unit, Floor, etc.',
    name: 'secondaryLocationSuiteAptUnitFloor',
    label: 'Secondary Office Location Suite, Apt., Unit, Floor, etc.',
    initialValue: '',
    validation: null,
  },
  secondaryLocationCity: {
    emailLabel: 'Secondary Location City',
    name: 'secondaryLocationCity',
    label: 'Secondary Office Location City',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationState: {
    emailLabel: 'Secondary Location State',
    name: 'secondaryLocationState',
    label: 'Secondary Office Location State',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationZip: {
    emailLabel: 'Secondary Location Zip',
    name: 'secondaryLocationZip',
    label: 'Secondary Office Location ZIP Code',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => zipValidation,
    }),
  },
  secondaryLocationCounty: {
    emailLabel: 'Secondary Location County',
    name: 'secondaryLocationCounty',
    label: 'Secondary Office Location County',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondaryLocationPhone: {
    emailLabel: 'Secondary Location Phone',
    name: 'secondaryLocationPhone',
    label: 'Secondary Office Location Phone Number',
    initialValue: '',
    validation: Yup.string().when('secondaryLocation', {
      is: 'yes',
      then: () => phoneValidation,
    }),
  },
  billingAddressSameAsPrimaryAddress: {
    emailLabel: 'Billing address is same as primary address above',
    name: 'billingAddressSameAsPrimaryAddress',
    label: 'Is your billing address the same as your primary address above?',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  differentBillingAddress: {
    emailLabel: 'Different Billing Address',
    name: 'differentBillingAddress',
    label: 'Billing Address',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  differentBillingSuiteAptUnitFloor: {
    emailLabel: 'Different Billing Suite, Apt., Unit, Floor, etc.',
    name: 'differentBillingSuiteAptUnitFloor',
    label: 'Billing Suite, Apt., Unit, Floor, etc.',
    initialValue: '',
    validation: null,
  },
  differentBillingCity: {
    emailLabel: 'Different Billing City',
    name: 'differentBillingCity',
    label: 'Billing City',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  differentBillingState: {
    emailLabel: 'Different Billing State',
    name: 'differentBillingState',
    label: 'Billing State',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  differentBillingZip: {
    emailLabel: 'Different Billing Zip',
    name: 'differentBillingZip',
    label: 'Billing ZIP Code',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => zipValidation,
    }),
  },
  differentBillingCounty: {
    emailLabel: 'Different Billing County',
    name: 'differentBillingCounty',
    label: 'Billing County',
    initialValue: '',
    validation: Yup.string().when('billingAddressSameAsPrimaryAddress', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
};

export const step5Schema = {
  step5Description: {
    label: 'Enroll Practitioner',
    emailLabel: 'Enroll Practitioner',
    initialValue: 'Enroll Practitioner',
    name: '',
    type: 'description',
  },
  digitalSignature: {
    emailLabel: 'Digital Signature',
    name: 'digitalSignature',
    label: 'Digital Signature of Authorized Practice Representative',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  contactPhone: {
    emailLabel: 'Contact Phone',
    name: 'contactPhone',
    label: 'Contact Phone Number',
    initialValue: '',
    validation: phoneValidation,
  },
};

export const formSchema = {
  ...step1Schema,
  ...step2Schema,
  ...step3Schema,
  ...step4Schema,
  ...step5Schema,
};

const step1Values = getInitialValues(step1Schema);

const step2Values = getInitialValues(step2Schema);

const step3Values = getInitialValues(step3Schema);

const step4Values = getInitialValues(step4Schema);

const step5Values = getInitialValues(step5Schema);

export const individualPractitionerSchema = {
  ...step1Values,
  ...step2Values,
  ...step3Values,
  ...step4Values,
  ...step5Values,
};

export const IndividualPractitionerStep1Validation = getYupValidationSchema(step1Schema);

export const IndividualPractitionerStep2Validation = getYupValidationSchema(step2Schema);

export const IndividualPractitionerStep3Validation = getYupValidationSchema(step3Schema);

export const IndividualPractitionerStep4Validation = getYupValidationSchema(step4Schema);

export const IndividualPractitionerStep5Validation = getYupValidationSchema(step5Schema);
