import FailAlert from 'components/validation/FailAlert';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import {
  currentContractUnderNewLegalNameRadios,
  newPracticeStartingNewContractRadios,
  newPracticeTakingOverPriorPracticeRadios,
} from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/providerAcquisitionForm/schema';

// TODO - implement some sort of validation to prevent double "Yes" in the renderNoContract radios??

function Step5({ values, errors, touched }: any) {
  const renderNoContractRadios = () => {
    if (values.currentContractUnderNewLegalName === 'no') {
      return (
        <>
          <bc-typography>
            "Yes" can only be selected for one of the options below, but you can choose "no" for both if desired.
          </bc-typography>
          <BCRadioGroup
            label={formSchema.newPracticeTakingOverPriorPractice.label}
            values={values}
            radios={newPracticeTakingOverPriorPracticeRadios}
            name={formSchema.newPracticeTakingOverPriorPractice.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.newPracticeTakingOverPriorPractice.name)}
            validation={formSchema.newPracticeTakingOverPriorPractice.validation}
          />
          <BCRadioGroup
            label={formSchema.newPracticeStartingNewContract.label}
            values={values}
            radios={newPracticeStartingNewContractRadios}
            name={formSchema.newPracticeStartingNewContract.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.newPracticeStartingNewContract.name)}
            validation={formSchema.newPracticeStartingNewContract.validation}
          />
        </>
      );
    }
  };
  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Contract information
      </bc-typography>
      <bc-typography>
        Completion of this form does not establish you or your practice as an in-network Blue Cross NC provider. Answers
        to these questions do not guarantee any particular contract outcome. Contract status is still subject to review.
      </bc-typography>
      <FailAlert />
      <BCRadioGroup
        label={formSchema.currentContractUnderNewLegalName.label}
        values={values}
        radios={currentContractUnderNewLegalNameRadios}
        name={formSchema.currentContractUnderNewLegalName.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.currentContractUnderNewLegalName.name)}
        validation={formSchema.currentContractUnderNewLegalName.validation}
      />
      {renderNoContractRadios()}
    </>
  );
}

export default Step5;
