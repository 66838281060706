import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import BCRadioGroup from 'components/custom-fields/BCRadioGroup';
import { billingAddressRadios, highTechRadios, secondaryLocationRadios, stateOptions } from 'constants/formData';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { formSchema } from 'forms/individualPractitionerForm/schema';
import Fieldset from 'components/Fieldset';
import Legend from 'components/Legend';
import { phoneFormatHoverTitle } from 'constants/hoverTitles';

function Step4({ values, errors, touched }: any) {
  const renderSecondaryLocationInputs = () => {
    if (values.secondaryLocation === 'yes') {
      return (
        <Fieldset>
          <Legend required>Enter your current information</Legend>
          <bc-typography>
            Please note: Only locations where the practitioner works 2 or more days a week will be displayed in the Blue
            Cross NC directory. Please indicate for each location listed below.
          </bc-typography>
          <BCInput
            width="75"
            label={formSchema.secondaryLocationAddress.label}
            name={formSchema.secondaryLocationAddress.name}
            value={values.secondaryLocationAddress}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationAddress.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationSuiteAptUnitFloor.label}
            name={formSchema.secondaryLocationSuiteAptUnitFloor.name}
            value={values.secondaryLocationSuiteAptUnitFloor}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationSuiteAptUnitFloor.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationCity.label}
            name={formSchema.secondaryLocationCity.name}
            value={values.secondaryLocationCity}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationCity.name)}
          />
          <Fieldset>
            <Legend>Secondary Office Location State</Legend>
            <BCSelect
              width="75"
              values={values}
              options={stateOptions}
              placeholder={formSchema.secondaryLocationState.label}
              name={formSchema.secondaryLocationState.name}
              errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationState.name)}
            />
          </Fieldset>

          <BCInput
            width="75"
            label={formSchema.secondaryLocationZip.label}
            name={formSchema.secondaryLocationZip.name}
            value={values.secondaryLocationZip}
            maxlength={5}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationZip.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationCounty.label}
            name={formSchema.secondaryLocationCounty.name}
            value={values.secondaryLocationCounty}
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationCounty.name)}
          />
          <BCInput
            width="75"
            label={formSchema.secondaryLocationPhone.label}
            name={formSchema.secondaryLocationPhone.name}
            value={values.secondaryLocationPhone}
            hoverTitle={phoneFormatHoverTitle}
            maxlength={12}
            type="tel"
            errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocationPhone.name)}
          />
        </Fieldset>
      );
    }
  };

  const renderDifferentBillingAddressInputs = () => {
    if (values.billingAddressSameAsPrimaryAddress === 'no') {
      return (
        <Fieldset>
          <Legend required>Enter your current information</Legend>
          <BCInput
            width="75"
            label={formSchema.differentBillingAddress.label}
            name={formSchema.differentBillingAddress.name}
            value={values.differentBillingAddress}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingAddress.name)}
          />
          <BCInput
            width="75"
            label={formSchema.differentBillingSuiteAptUnitFloor.label}
            name={formSchema.differentBillingSuiteAptUnitFloor.name}
            value={values.differentBillingSuiteAptUnitFloor}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingSuiteAptUnitFloor.name)}
          />
          <BCInput
            width="75"
            label={formSchema.differentBillingCity.label}
            name={formSchema.differentBillingCity.name}
            value={values.differentBillingCity}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingCity.name)}
          />
          <Fieldset>
            <Legend>Billing State</Legend>
            <BCSelect
              width="75"
              values={values}
              options={stateOptions}
              placeholder={formSchema.differentBillingState.label}
              name={formSchema.differentBillingState.name}
              errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingState.name)}
            />
          </Fieldset>

          <BCInput
            width="75"
            label={formSchema.differentBillingZip.label}
            name={formSchema.differentBillingZip.name}
            maxlength={5}
            value={values.differentBillingZip}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingZip.name)}
          />
          <BCInput
            width="75"
            label={formSchema.differentBillingCounty.label}
            name={formSchema.differentBillingCounty.name}
            value={values.differentBillingCounty}
            errorMessage={generateErrorMessage(errors, touched, formSchema.differentBillingCounty.name)}
          />
        </Fieldset>
      );
    }
  };

  return (
    <>
      <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
        Primary Office Location Information (Service Location)
      </bc-typography>
      <bc-typography>
        This is the primary group and address where the practitioner will be practicing. Before enrolling a practitioner
        to a group, the group has to be enrolled with Blue Cross NC. That can be done by completing the Provider Group
        Enrollment Application.
      </bc-typography>
      <bc-typography>
        If there are more than two locations where the practitioner should be practicing, please complete a Demographic
        and Contact Information form. Please note that Blue Cross NC only needs to have one location for each
        practitioner at each Group NPI Type II. Additional locations should only be added if the practitioner is seeing
        patients at that location at least 2 days per week.
      </bc-typography>
      <Fieldset>
        <Legend required>Enter your current information</Legend>
        <BCInput
          width="75"
          label={formSchema.streetAddress.label}
          name={formSchema.streetAddress.name}
          value={values.streetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.streetAddress.name)}
        />
        <BCInput
          width="75"
          label={formSchema.suiteAptUnitFloor.label}
          name={formSchema.suiteAptUnitFloor.name}
          value={values.suiteAptUnitFloor}
          errorMessage={generateErrorMessage(errors, touched, formSchema.suiteAptUnitFloor.name)}
        />
        <BCInput
          width="75"
          label={formSchema.city.label}
          name={formSchema.city.name}
          value={values.city}
          errorMessage={generateErrorMessage(errors, touched, formSchema.city.name)}
        />
        <Fieldset>
          <Legend>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.state.label}
            name={formSchema.state.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.state.name)}
          />
        </Fieldset>
        <BCInput
          width="75"
          label={formSchema.zip.label}
          name={formSchema.zip.name}
          maxlength={5}
          value={values.zip}
          errorMessage={generateErrorMessage(errors, touched, formSchema.zip.name)}
        />
        <BCInput
          width="75"
          label={formSchema.county.label}
          name={formSchema.county.name}
          value={values.county}
          errorMessage={generateErrorMessage(errors, touched, formSchema.county.name)}
        />
        <BCInput
          width="75"
          label={formSchema.primaryPhone.label}
          name={formSchema.primaryPhone.name}
          maxlength={12}
          type="tel"
          hoverTitle={phoneFormatHoverTitle}
          value={values.primaryPhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.primaryPhone.name)}
        />
        <BCInput
          width="75"
          label={formSchema.primaryEmail.label}
          name={formSchema.primaryEmail.name}
          type="email"
          value={values.primaryEmail}
          errorMessage={generateErrorMessage(errors, touched, formSchema.primaryEmail.name)}
        />

        <BCInput
          width="75"
          label={formSchema.dateJoinedProviderGroup.label}
          name={formSchema.dateJoinedProviderGroup.name}
          type="date"
          min={formSchema.dateJoinedProviderGroup.min}
          max={formSchema.dateJoinedProviderGroup.max}
          value={values.dateJoinedProviderGroup}
          errorMessage={generateErrorMessage(errors, touched, formSchema.dateJoinedProviderGroup.name)}
        />
      </Fieldset>

      <BCRadioGroup
        label={formSchema.haveHighTechImagingEquipment.label}
        values={values}
        radios={highTechRadios}
        name={formSchema.haveHighTechImagingEquipment.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.haveHighTechImagingEquipment.name)}
        validation={formSchema.haveHighTechImagingEquipment.validation}
      />

      <BCRadioGroup
        label={formSchema.secondaryLocation.label}
        values={values}
        radios={secondaryLocationRadios}
        name={formSchema.secondaryLocation.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.secondaryLocation.name)}
        validation={formSchema.secondaryLocation.validation}
      />
      {renderSecondaryLocationInputs()}

      <BCRadioGroup
        label={formSchema.billingAddressSameAsPrimaryAddress.label}
        values={values}
        radios={billingAddressRadios}
        name={formSchema.billingAddressSameAsPrimaryAddress.name}
        errorMessage={generateErrorMessage(errors, touched, formSchema.billingAddressSameAsPrimaryAddress.name)}
        validation={formSchema.billingAddressSameAsPrimaryAddress.validation}
      />
      {renderDifferentBillingAddressInputs()}
    </>
  );
}

export default Step4;
