import * as Yup from 'yup';
import { getInitialValues, getYupValidationSchema } from 'utilities/miscHelpers';
import {
  optionalNPIValidation,
  optionalFaxValidation,
  phoneValidation,
  zipValidation,
  NPIValidation,
} from 'utilities/customValidators';
import { HoneypotFieldSchema } from 'utilities/formHelpers';

// TODO - should we add the radio & checkbox array inputs to the schema as well?

export const step1Schema = {
  HoneypotFieldSchema,
  step1Description: {
    label: 'Provider Information',
    emailLabel: 'Provider Information',
    initialValue: 'Provider Information',
    name: '',
    type: 'description',
  },
  newLegalName: {
    emailLabel: 'New Legal Name',
    name: 'newLegalName',
    label: 'New legal name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  priorLegalName: {
    emailLabel: 'Prior Legal Name',
    name: 'priorLegalName',
    label: 'Prior legal name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  newTaxID: {
    emailLabel: 'New Tax ID',
    name: 'newTaxID',
    label: 'New tax ID',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  priorTaxID: {
    emailLabel: 'Prior Tax ID',
    name: 'priorTaxID',
    label: 'Prior tax ID',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  newNPI: {
    emailLabel: 'New NPI',
    name: 'newNPI',
    label: 'New NPI',
    initialValue: '',
    validation: NPIValidation,
  },
  priorNPI: {
    emailLabel: 'Prior NPI',
    name: 'priorNPI',
    label: 'Prior NPI',
    initialValue: '',
    validation: NPIValidation,
  },
  newDBAName: {
    emailLabel: 'New DBA Name',
    name: 'newDBAName',
    label: 'New DBA Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  priorDBAName: {
    emailLabel: 'Prior DBA Name',
    name: 'priorDBAName',
    label: 'Prior DBA Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  newCLIANumber: {
    emailLabel: 'New CLIA Number',
    name: 'newCLIANumber',
    label: 'New CLIA number',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  priorCLIANumber: {
    emailLabel: 'Prior CLIA Number',
    name: 'priorCLIANumber',
    label: 'Prior CLIA number',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
};

export const step2Schema = {
  step2Description: {
    label: 'Primary practice information',
    emailLabel: 'Primary practice information',
    initialValue: 'Primary practice information',
    name: '',
    type: 'description',
  },
  streetAddress: {
    emailLabel: 'Street Address',
    name: 'streetAddress',
    label: 'Street Address',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  suiteAptUnitFloor: {
    emailLabel: 'Suite, apt, unit, floor, etc.',
    name: 'suiteAptUnitFloor',
    label: 'Suite, apt, unit, floor, etc.',
    initialValue: '',
    validation: null,
  },
  city: {
    emailLabel: 'City',
    name: 'city',
    label: 'City',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  state: {
    emailLabel: 'State',
    name: 'state',
    label: 'State',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  zip: {
    emailLabel: 'Zip Code',
    name: 'zip',
    label: 'Zip code',
    initialValue: '',
    validation: zipValidation,
  },
  county: {
    emailLabel: 'County',
    name: 'county',
    label: 'County',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  phone: {
    emailLabel: 'Phone',
    name: 'phone',
    label: 'Phone number',
    initialValue: '',
    validation: phoneValidation,
  },
  fax: {
    emailLabel: 'Fax',
    name: 'fax',
    label: 'Fax number',
    initialValue: '',
    validation: optionalFaxValidation,
  },
  haveRosterToUpload: {
    emailLabel: 'Has roster to upload',
    name: 'haveRosterToUpload',
    label: 'Do you have a roster to upload?:',
    // TODO - add description ????
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  firstProviderName: {
    emailLabel: 'First Provider Name',
    name: 'firstProviderName',
    label: 'Provider Name',
    initialValue: '',
    // TODO - should only FIRST provider be required? since that is the minimum a user can have?
    validation: Yup.string().when('haveRosterToUpload', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  firstProviderNPINumber: {
    emailLabel: 'First Provider NPI Number',
    name: 'firstProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: Yup.string().when('haveRosterToUpload', {
      is: 'no',
      then: () => NPIValidation,
    }),
  },
  firstProviderShouldShowInDirectory: {
    emailLabel: 'First Provider should show in directory',
    name: 'firstProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: Yup.string().when('haveRosterToUpload', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  firstProviderIsAcceptingNewPatients: {
    emailLabel: 'First Provider is accepting new patients',
    name: 'firstProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: Yup.string().when('haveRosterToUpload', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  secondProviderName: {
    emailLabel: 'Second Provider Name',
    name: 'secondProviderName',
    label: 'Provider Name',
    initialValue: '',
    validation: null,
  },
  secondProviderNPINumber: {
    emailLabel: 'Second Provider NPI Number',
    name: 'secondProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: optionalNPIValidation,
  },
  secondProviderShouldShowInDirectory: {
    emailLabel: 'Second Provider should show in directory',
    name: 'secondProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: null,
  },
  secondProviderIsAcceptingNewPatients: {
    emailLabel: 'Second Provider is accepting new patients',
    name: 'secondProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: null,
  },
  thirdProviderName: {
    emailLabel: 'Third Provider Name',
    name: 'thirdProviderName',
    label: 'Provider Name',
    initialValue: '',
    validation: null,
  },
  thirdProviderNPINumber: {
    emailLabel: 'Third Provider NPI Number',
    name: 'thirdProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: optionalNPIValidation,
  },
  thirdProviderShouldShowInDirectory: {
    emailLabel: 'Third Provider should show in directory',
    name: 'thirdProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: null,
  },
  thirdProviderIsAcceptingNewPatients: {
    emailLabel: 'Third Provider is accepting new patients',
    name: 'thirdProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: null,
  },
  fourthProviderName: {
    emailLabel: 'Fourth Provider Name',
    name: 'fourthProviderName',
    label: 'Provider Name',
    initialValue: '',
    validation: null,
  },
  fourthProviderNPINumber: {
    emailLabel: 'Fourth Provider NPI Number',
    name: 'fourthProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: optionalNPIValidation,
  },
  fourthProviderShouldShowInDirectory: {
    emailLabel: 'Fourth Provider should show in directory',
    name: 'fourthProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: null,
  },
  fourthProviderIsAcceptingNewPatients: {
    emailLabel: 'Fourth Provider is accepting new patients',
    name: 'fourthProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: null,
  },
  fifthProviderName: {
    emailLabel: 'Fifth Provider Name',
    name: 'fifthProviderName',
    label: 'Provider Name',
    initialValue: '',
    validation: null,
  },
  fifthProviderNPINumber: {
    emailLabel: 'Fifth Provider NPI Number',
    name: 'fifthProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: optionalNPIValidation,
  },
  fifthProviderShouldShowInDirectory: {
    emailLabel: 'Fifth Provider should show in directory',
    name: 'fifthProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: null,
  },
  fifthProviderIsAcceptingNewPatients: {
    emailLabel: 'Fifth Provider is accepting new patients',
    name: 'fifthProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: null,
  },
  sixthProviderName: {
    emailLabel: 'Sixth Provider Name',
    name: 'sixthProviderName',
    label: 'Provider Name',
    initialValue: '',
    validation: null,
  },
  sixthProviderNPINumber: {
    emailLabel: 'Sixth Provider NPI Number',
    name: 'sixthProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: optionalNPIValidation,
  },
  sixthProviderShouldShowInDirectory: {
    emailLabel: 'Sixth Provider should show in directory',
    name: 'sixthProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: null,
  },
  sixthProviderIsAcceptingNewPatients: {
    emailLabel: 'Sixth Provider is accepting new patients',
    name: 'sixthProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: null,
  },
  seventhProviderName: {
    emailLabel: 'Seventh Provider Name',
    name: 'seventhProviderName',
    label: 'Provider Name',
    initialValue: '',
    validation: null,
  },
  seventhProviderNPINumber: {
    emailLabel: 'Seventh Provider NPI Number',
    name: 'seventhProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: optionalNPIValidation,
  },
  seventhProviderShouldShowInDirectory: {
    emailLabel: 'Seventh Provider should show in directory',
    name: 'seventhProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: null,
  },
  seventhProviderIsAcceptingNewPatients: {
    emailLabel: 'Seventh Provider is accepting new patients',
    name: 'seventhProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: null,
  },
  eighthProviderName: {
    emailLabel: 'Eighth Provider Name',
    name: 'eighthProviderName',
    label: 'Provider Name',
    initialValue: '',
    validation: null,
  },
  eighthProviderNPINumber: {
    emailLabel: 'Eighth Provider NPI Number',
    name: 'eighthProviderNPINumber',
    label: 'Provider NPI Number',
    initialValue: '',
    validation: optionalNPIValidation,
  },
  eighthProviderShouldShowInDirectory: {
    emailLabel: 'Eighth Provider should show in directory',
    name: 'eighthProviderShouldShowInDirectory',
    label: 'Should this provider show in the Blue Cross NC directory? ',
    initialValue: '',
    validation: null,
  },
  eighthProviderIsAcceptingNewPatients: {
    emailLabel: 'Eighth Provider is accepting new patients',
    name: 'eighthProviderIsAcceptingNewPatients',
    label: 'Is this provider accepting new patients?',
    initialValue: '',
    validation: null,
  },
};

export const step3Schema = {
  // TODO - differentiate this street address label from previous street address label (and others) when emailing data...
  step3Description: {
    label: 'Billing information',
    emailLabel: 'Billing information',
    initialValue: 'Billing information',
    name: '',
    type: 'description',
  },
  billingStreetAddress: {
    emailLabel: 'Billing Street Address',
    name: 'billingStreetAddress',
    label: 'Street Address',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  billingSuiteAptUnitFloor: {
    emailLabel: 'Billing Suite, apt, unit, floor, etc.',
    name: 'billingSuiteAptUnitFloor',
    label: 'Suite, apt, unit, floor, etc.',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  billingCity: {
    emailLabel: 'Billing City',
    name: 'billingCity',
    label: 'City',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  billingState: {
    emailLabel: 'Billing State',
    name: 'billingState',
    label: 'State',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  billingZip: {
    emailLabel: 'Billing Zip Code',
    name: 'billingZip',
    label: 'Zip code',
    initialValue: '',
    validation: zipValidation,
  },

  billingPhone: {
    emailLabel: 'Billing Phone',
    name: 'billingPhone',
    label: 'Phone number',
    initialValue: '',
    validation: phoneValidation,
  },
  billingFax: {
    emailLabel: 'Billing Fax',
    name: 'billingFax',
    label: 'Fax number',
    initialValue: '',
    validation: Yup.string().test(
      'conditional-validation',
      'Fax number must follow this format: 213-483-2811',
      (value) => {
        if (value) {
          if (!value.match(/[0-9]{3}-[0-9]{3}-[0-9]{4}/)) {
            return false;
          }
        }
        return true;
      },
    ),
  },
  billingContactName: {
    emailLabel: 'Billing Contact Name',
    name: 'billingContactName',
    label: 'Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  billingContactEmail: {
    emailLabel: 'Billing Contact Email',
    name: 'billingContactEmail',
    label: 'Email',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },

  billingContactPhone: {
    emailLabel: 'Billing Contact Phone',
    name: 'billingContactPhone',
    label: 'Phone number',
    initialValue: '',
    validation: phoneValidation,
  },
  billingContactFax: {
    emailLabel: 'Billing Contact Fax',
    name: 'billingContactFax',
    label: 'Fax number',
    initialValue: '',
    validation: optionalFaxValidation,
  },
};

export const step4Schema = {
  step4Description: {
    label: 'Required Documentation',
    emailLabel: 'Required Documentation',
    initialValue: 'Required Documentation',
    name: '',
    type: 'description',
  },
  agreeToSubmitDocumentation: {
    emailLabel: 'Agrees to submit required documentation',
    name: 'agreeToSubmitDocumentation',
    label: 'Do you agree to submit the required documentation?:',
    // TODO - add description ???
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  practiceAgreeToTakeOwnership: {
    emailLabel:
      'Practice agrees to take ownership or any funds, recruitment, debt, or accounts receivable owed to Blue Cross NC',
    name: 'practiceAgreeToTakeOwnership',
    label:
      'Does the practice agree to take ownership or any funds, recruitment, debt, or accounts receivable owed to Blue Cross NC?:',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
};

export const step5Schema = {
  step5Description: {
    label: 'Contract information',
    emailLabel: 'Contract information',
    initialValue: 'Contract information',
    name: '',
    type: 'description',
  },
  currentContractUnderNewLegalName: {
    emailLabel: 'Current Blue Cross NC contract in place under the new legal name',
    name: 'currentContractUnderNewLegalName',
    label: 'Is there a current Blue Cross NC contract in place under the new legal name?:',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  newPracticeTakingOverPriorPractice: {
    emailLabel:
      "New practice interested in taking over prior practice's Blue Cross NC participation contract if applicable",
    name: 'newPracticeTakingOverPriorPractice',
    label:
      "Is the new practice interested in taking over the prior practice's Blue Cross NC participation contract if available?",
    initialValue: '',
    validation: Yup.string().when('currentContractUnderNewLegalName', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
  newPracticeStartingNewContract: {
    emailLabel: 'New practice interested in started a new Blue Cross NC participation contract',
    name: 'newPracticeStartingNewContract',
    label: 'Is the new practice interested in starting a new Blue Cross NC participation contract?',
    initialValue: '',
    validation: Yup.string().when('currentContractUnderNewLegalName', {
      is: 'no',
      then: () => Yup.string().required('This field is required'),
    }),
  },
};

export const formSchema = {
  ...step1Schema,
  ...step2Schema,
  ...step3Schema,
  ...step4Schema,
  ...step5Schema,
};

const step1Values = getInitialValues(step1Schema);

const step2Values = getInitialValues(step2Schema);

const step3Values = getInitialValues(step3Schema);

const step4Values = getInitialValues(step4Schema);

const step5Values = getInitialValues(step5Schema);

export const providerAcquisitionSchema = {
  ...step1Values,
  ...step2Values,
  ...step3Values,
  ...step4Values,
  ...step5Values,
};

export const ProviderAcquisitionStep1Validation = getYupValidationSchema(step1Schema);

export const ProviderAcquisitionStep2Validation = getYupValidationSchema(step2Schema);

export const ProviderAcquisitionStep3Validation = getYupValidationSchema(step3Schema);

export const ProviderAcquisitionStep4Validation = getYupValidationSchema(step4Schema);

export const ProviderAcquisitionStep5Validation = getYupValidationSchema(step5Schema);
