import { Formik } from 'formik';
import { ReactElement, useEffect } from 'react';
import FormWrapper from 'components/FormWrapper';
import 'styles/button.scss';
import { formSchema, initialValues, validationSchema } from 'forms/fraudAndAbuseReportForm/schema';
import FormContent from 'forms/fraudAndAbuseReportForm/formContent';
import { referenceCodeSchema, setLoader } from 'utilities/miscHelpers';
import { useStore } from 'store';
import { Action } from 'constants/store';
import FailAlert from 'components/validation/FailAlert';
import { sendEmail } from 'utilities/apis';
import { handleFormSubmission } from 'utilities/formHelpers';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { EmailRecipients, EmailSubjects } from 'types/EmailTypes';
import { BCBSNC_NO_REPLY_EMAIL } from 'constants/EmailConstants';
import { FormTitles } from 'types/FormTypes';
import { nanoid } from 'nanoid';
import PageTitle from 'components/PageTitle';
import SuccessPage from 'components/SuccessPage';
import { emailTemplateRender, formSchemasToEmailTemplates } from 'utilities/emailTemplateHelpers';

function FraudAndAbuseReportForm(): ReactElement {
  const {
    dispatch,
    state: { formSubmittedSuccessfully, isLoading },
  } = useStore();

  useEffect(() => {
    // update form title
    dispatch({
      type: Action.UPDATE_FORM_TITLE,
      formTitle: FormTitles.FraudAndAbuse,
    });

    // set previous url
    dispatch({ type: Action.UPDATE_PREVIOUS_URL, previousUrl: document.referrer });
  }, [dispatch]);

  const handleSubmit = async (values: any): Promise<void> => {
    setLoader(dispatch, true);

    // get random unique string for referenceCode
    const referenceCode = nanoid();

    const recipient = EmailRecipients.FraudAndAbuse;
    const sender = BCBSNC_NO_REPLY_EMAIL;
    const subject = EmailSubjects.FraudAndAbuse;
    const emailTemplates = formSchemasToEmailTemplates([formSchema, referenceCodeSchema(referenceCode)]);
    const tempValues = values;
    tempValues.referenceCode = referenceCode;
    const emailData = emailTemplateRender(emailTemplates.html, emailTemplates.text, tempValues);
    const response = await sendEmail(emailData, recipient, sender, subject);

    // handles form submission whether its success or fail
    handleFormSubmission(BcncAnalyticsEventFormNames.FraudAndAbuse, response, dispatch, referenceCode);

    setLoader(dispatch, false);
  };

  const renderForm = () => {
    if (!formSubmittedSuccessfully && !isLoading) {
      return (
        <div className="form-content-width">
          <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
            Fraud and Abuse Report Form
          </bc-typography>
          <bc-typography>
            To report suspected fraud or abuse, please complete the information requested below. Please be as detailed
            as possible. Incomplete or lack of information may prevent Blue Cross NC from investigating this matter
            fully.
          </bc-typography>
          <FailAlert />
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ values, errors, touched }: any) => <FormContent values={values} errors={errors} touched={touched} />}
          </Formik>
        </div>
      );
    }
  };

  return (
    <FormWrapper>
      <PageTitle title="Fraud and Abuse Report Form" />
      {renderForm()}
      <SuccessPage formName={FormTitles.FraudAndAbuse} />
    </FormWrapper>
  );
}

export default FraudAndAbuseReportForm;
