// @ts-ignore
import '@bcbsnc/litehouse/dist/css/litehouse.min.css';

// @ts-ignore
import { litehouseIconLibrary } from '@bcbsnc/litehouse';
import './styles/index.scss';

litehouseIconLibrary(`/static/images/icons`);

function App() {
  return (
    <div className="body">
      <bc-button
        onClick={() => {
          window.location.href = `${window.location.origin}/individual-practitioner-form/`;
        }}
      >
        Individual Practitioner Enrollment Form
      </bc-button>
      <bc-button
        onClick={() => {
          window.location.href = `${window.location.origin}/creditable-coverage-form/`;
        }}
      >
        Creditable Coverage Form
      </bc-button>
      <bc-button
        onClick={() => {
          window.location.href = `${window.location.origin}/model-care-attestation-form/`;
        }}
      >
        Model Care Attestation Form
      </bc-button>
      <bc-button
        onClick={() => {
          window.location.href = `${window.location.origin}/nurse-advocate-contact-form/`;
        }}
      >
        Nurse Advocate Contact Form (Move to AEM)
      </bc-button>
      <bc-button
        onClick={() => {
          window.location.href = `${window.location.origin}/fraud-and-abuse-report-form/`;
        }}
      >
        Fraud and Abuse Report Form (Move to AEM)
      </bc-button>
      <bc-button
        onClick={() => {
          window.location.href = `${window.location.origin}/provider-acquisition-form/`;
        }}
      >
        Provider Acquisition Form
      </bc-button>
      <bc-button
        onClick={() => {
          window.location.href = `${window.location.origin}/provider-group-enrollment-form/`;
        }}
      >
        Provider Group Enrollment Form
      </bc-button>
    </div>
  );
}

export default App;
