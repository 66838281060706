import { Formik } from 'formik';
import { ReactElement, useEffect } from 'react';
import FormWrapper from 'components/FormWrapper';
import 'styles/button.scss';
import FormContent from 'forms/creditableCoverageForm/formContent';
import { referenceCodeSchema, setLoader } from 'utilities/miscHelpers';
import { useStore } from 'store';
import { Action } from 'constants/store';
import { sendEmail } from 'utilities/apis';
import FailAlert from 'components/validation/FailAlert';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { handleFormSubmission } from 'utilities/formHelpers';
import { FormTitles } from 'types/FormTypes';
import { EmailRecipients, EmailSubjects } from 'types/EmailTypes';
import { BCBSNC_NO_REPLY_EMAIL } from 'constants/EmailConstants';
import PageTitle from 'components/PageTitle';
import SuccessPage from 'components/SuccessPage';
import { nanoid } from 'nanoid';
import { emailTemplateRender, formSchemasToEmailTemplates } from 'utilities/emailTemplateHelpers';
import { formSchema, initialValues, validationSchema } from './schema';

function CreditableCoverageForm(): ReactElement {
  const {
    dispatch,
    state: { formSubmittedSuccessfully, isLoading },
  } = useStore();

  useEffect(() => {
    // update form title
    dispatch({
      type: Action.UPDATE_FORM_TITLE,
      formTitle: FormTitles.CreditableCoverage,
    });

    // set previous url
    dispatch({ type: Action.UPDATE_PREVIOUS_URL, previousUrl: document.referrer });
  }, [dispatch]);

  const handleSubmit = async (values: any): Promise<void> => {
    setLoader(dispatch, true);

    // get random unique string for reference code
    const referenceCode = nanoid();

    const recipient = EmailRecipients.CreditableCoverage;
    const sender = BCBSNC_NO_REPLY_EMAIL;
    const subject = EmailSubjects.CreditableCoverage;
    const emailTemplates = formSchemasToEmailTemplates([formSchema, referenceCodeSchema(referenceCode)]);
    const tempValues = values;
    tempValues.referenceCode = referenceCode;
    const emailData = emailTemplateRender(emailTemplates.html, emailTemplates.text, tempValues);
    const response = await sendEmail(emailData, recipient, sender, subject);

    // handles form submission whether its success or fail
    handleFormSubmission(BcncAnalyticsEventFormNames.CreditableCoverage, response, dispatch, referenceCode);

    setLoader(dispatch, false);
  };

  const renderForm = () => {
    if (!formSubmittedSuccessfully && !isLoading) {
      return (
        <div className="form-content-width">
          <bc-typography variant="h1" size="display-xx-sm" color="primary-800">
            Certificate of Creditable Coverage
          </bc-typography>
          <bc-typography>
            <strong>If your coverage has ended</strong> and you need a Certificate of Creditable Coverage to verify the
            length of coverage you had with Blue Cross NC, use this form to order a certificate for terminated policies
            for up to 24 months following termination.
          </bc-typography>
          <bc-typography>
            <strong>If your policy is still active</strong>, and you need a Certificate of Creditable Coverage document,
            please call the Customer Service number on your Blue Cross NC ID card.
          </bc-typography>
          <FailAlert />
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values, errors, touched }: any) => <FormContent values={values} errors={errors} touched={touched} />}
          </Formik>
        </div>
      );
    }
  };

  return (
    <FormWrapper>
      <PageTitle title="Certificate of Creditable Coverage" />
      {renderForm()}
      <SuccessPage formName={FormTitles.CreditableCoverage} />
    </FormWrapper>
  );
}

export default CreditableCoverageForm;
