import { useEffect, useState } from 'react';
import { useStore } from 'store';

interface HeaderProps {
  noGutters: boolean;
}

function Header({ noGutters }: HeaderProps) {
  const [isMobileWidth, setIsMobileWidth] = useState(false);

  const {
    state: { formTitle, previousUrl },
  } = useStore();

  useEffect(() => {
    // function to hide/show logo on window resize
    const hideOrShowLogo = () => {
      if (window.innerWidth < 768) {
        setIsMobileWidth(true);
      } else {
        setIsMobileWidth(false);
      }
    };

    // hide display logo if window screen size starts on mobile size
    if (window.innerWidth < 768) {
      setIsMobileWidth(true);
    }

    // attach function to hide/show logo to resize event
    window.addEventListener('resize', hideOrShowLogo);

    // remove on unmount
    return (): void => {
      window.removeEventListener('resize', hideOrShowLogo);
    };
  }, []);

  const renderTitle = () =>
    formTitle ? (
      <bc-typography weight="bold" slot="center" alignment="left">
        {formTitle}
      </bc-typography>
    ) : null;

  const renderLogo = () =>
    !isMobileWidth && <bc-image src="/static/images/logos/BCBSNC.svg" slot="left" alt="BCBSNC Logo" width="115" />;

  // TODO - noGutters should be true/false on no-gutters property in bc-header

  return (
    // if screen width is mobile size, add bottom border to header and remove gutters
    <div className={isMobileWidth ? 'header--bottom-border' : ''}>
      <bc-header no-gutters={noGutters || isMobileWidth ? true : null}>
        {renderLogo()}
        {renderTitle()}
        <a href={previousUrl} slot="right" aria-label="Exit form">
          <bc-icon name="X" color="neutral-400" size="display-sm" />
        </a>
      </bc-header>
    </div>
  );
}

export default Header;
