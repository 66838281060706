import { Form } from 'formik';
import BCSelect from 'components/custom-fields/BCSelect';
import BCInput from 'components/custom-fields/BCInput';
import { generateErrorMessage } from 'utilities/miscHelpers';
import { stateOptions } from 'constants/formData';
import SubmitContainer from 'components/SubmitContainer';
import { PRIVACY_POLICY_LINK } from 'constants/links';
import Legend from 'components/Legend';
import Fieldset from 'components/Fieldset';
import { phoneFormatHoverTitle } from 'constants/hoverTitles';
import { useEffect, useRef } from 'react';
import { registerBcAnalyticsEventListenerStartForm } from 'utilities/eventHelpers';
import { BcncAnalyticsEventFormNames } from 'types/BcncAnalyticsTypes';
import { formSchema, validationSchema } from './schema';

function FormContent({ values, errors, touched }: any) {
  const ref = useRef();

  // NOTE - attaching eventListener, rather than using onFocus prop, because we need to ensure it is only fired once
  useEffect(() => {
    registerBcAnalyticsEventListenerStartForm(ref, BcncAnalyticsEventFormNames.CreditableCoverage);
  }, []);

  return (
    <Form>
      <Fieldset>
        <Legend required>Enter your current information</Legend>
        <BCInput
          label={formSchema.HoneypotFieldSchema.label}
          name={formSchema.HoneypotFieldSchema.name}
          tabIndex="-500"
          width="75"
          value={values.HoneypotFieldSchema}
        />
        <BCInput
          label={formSchema.firstName.label}
          width="75"
          name={formSchema.firstName.name}
          value={values.firstName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.firstName.name)}
          // passing ref in order to attach Bcanalytics event listener to FIRST input on page
          ref={ref}
        />
        <BCInput
          label={formSchema.lastName.label}
          width="75"
          name={formSchema.lastName.name}
          value={values.lastName}
          errorMessage={generateErrorMessage(errors, touched, formSchema.lastName.name)}
        />
        <BCInput
          label={formSchema.dateOfBirth.label}
          width="75"
          type="date"
          name={formSchema.dateOfBirth.name}
          value={values.dateOfBirth}
          errorMessage={generateErrorMessage(errors, touched, formSchema.dateOfBirth.name)}
        />
        <BCInput
          label={formSchema.subscriberID.label}
          width="75"
          name={formSchema.subscriberID.name}
          value={values.subscriberID}
          errorMessage={generateErrorMessage(errors, touched, formSchema.subscriberID.name)}
        />
        <BCInput
          label={formSchema.streetAddress.label}
          width="75"
          name={formSchema.streetAddress.name}
          value={values.streetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.streetAddress.name)}
        />
        <BCInput
          label="City"
          width="75"
          name="city"
          value={values.city}
          errorMessage={generateErrorMessage(errors, touched, formSchema.city.name)}
        />
        <Fieldset noMargin>
          <Legend required>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.state.label}
            name={formSchema.state.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.state.name)}
          />
        </Fieldset>

        <BCInput
          label={formSchema.zip.label}
          width="75"
          maxlength={5}
          name={formSchema.zip.name}
          value={values.zip}
          errorMessage={generateErrorMessage(errors, touched, formSchema.zip.name)}
        />
        <BCInput
          label={formSchema.daytimePhone.label}
          width="75"
          maxlength={12}
          name={formSchema.daytimePhone.name}
          hoverTitle={phoneFormatHoverTitle}
          value={values.daytimePhone}
          errorMessage={generateErrorMessage(errors, touched, formSchema.daytimePhone.name)}
        />
      </Fieldset>
      <Fieldset>
        <Legend>
          Has your address changed since your coverage with BCBSNC ended? If so, enter your previous address information
          for verification purposes.
        </Legend>
        <BCInput
          label={formSchema.oldStreetAddress.label}
          width="75"
          name={formSchema.oldStreetAddress.name}
          value={values.oldStreetAddress}
          errorMessage={generateErrorMessage(errors, touched, formSchema.oldStreetAddress.name)}
        />
        <BCInput
          label={formSchema.oldCity.label}
          width="75"
          name={formSchema.oldCity.name}
          value={values.oldCity}
          errorMessage={generateErrorMessage(errors, touched, formSchema.oldCity.name)}
        />
        <Fieldset noMargin>
          <Legend>State</Legend>
          <BCSelect
            width="75"
            values={values}
            options={stateOptions}
            placeholder={formSchema.oldState.label}
            name={formSchema.oldState.name}
            errorMessage={generateErrorMessage(errors, touched, formSchema.oldState.name)}
          />
        </Fieldset>
        <BCInput
          label={formSchema.oldZip.label}
          width="75"
          maxlength={5}
          name={formSchema.oldZip.name}
          value={values.oldZip}
          errorMessage={generateErrorMessage(errors, touched, formSchema.oldZip.name)}
        />
        <input type="hidden" name={formSchema.external.name} value={formSchema.external.initialValue} />
      </Fieldset>
      <SubmitContainer
        values={values}
        validationSchema={validationSchema}
        previousFieldIsInput
        hasFooter
        formName={BcncAnalyticsEventFormNames.CreditableCoverage}
      />

      <bc-typography>
        <strong>Note:</strong> To submit an online request or change, you must be a member of the reference Blue Cross
        and Blue Shield of North Carolina health plan, or a group benefits administrator authorized to make requests or
        changes on the member's behalf.
      </bc-typography>
      <bc-typography>
        Following receipt of your request, you will receive your proof of coverage certificate through regular mail{' '}
        <strong>within 10 days</strong>.
      </bc-typography>
      <bc-typography>
        Review our{' '}
        <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>{' '}
        so that you know how your information will be used and protected.
      </bc-typography>
    </Form>
  );
}

export default FormContent;
