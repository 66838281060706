import { useField } from 'formik';
import { useEffect } from 'react';

function BCSelect({ values, placeholder, name, options, errorMessage, width }: any) {
  const [field] = useField(name);

  const handleBlur = (e: any) => {
    field.onBlur(e);
  };

  const handleChange = (e: any) => {
    field.onChange(e);
  };

  // instead of using "onChange" prop, we are listening for Shoelace change event
  const addHandlersToEventListeners = () => {
    const selectComp = document.getElementById(`${name}-select`);
    (selectComp as unknown as HTMLInputElement).addEventListener('sl-change', handleChange);
    (selectComp as unknown as HTMLInputElement).addEventListener('sl-hide', handleBlur);
  };

  useEffect(() => {
    addHandlersToEventListeners();
    // we only need to add thse event listeners on first render and thus does not depend on any other values
    // eslint-disable-next-line
  }, []);

  // TODO - pass this as props?
  const renderOptions = () => {
    return options.map((option: any) => (
      <bc-option key={option.value} value={option.value}>
        {option.label}
      </bc-option>
    ));
  };

  return (
    <div className="mt-14 mb-38">
      <bc-select
        width={width}
        id={`${name}-select`}
        placeholder={placeholder}
        name={name}
        value={values[name]}
        errorMessage={errorMessage}
      >
        {renderOptions()}
      </bc-select>
    </div>
  );
}

export default BCSelect;
