import Handlebars from 'handlebars';
import { EmailDataType, EmailTemplateType } from 'types/EmailTypes';

export function emailTemplateRender(HtmlTemplate: string, TextTemplate: string, values: object): EmailDataType {
  const htmlTemplateCompiled = Handlebars.compile(HtmlTemplate);
  const textTemplateCompiled = Handlebars.compile(TextTemplate);

  return {
    html: htmlTemplateCompiled(values),
    text: textTemplateCompiled(values),
    values,
  };
}

export function formSchemasToEmailTemplates(Schemas: Array<any>): EmailTemplateType {
  let htmlTemplate = '';
  let textTemplate = '';
  Schemas.forEach((schema: object) => {
    Object.entries(schema).forEach((key) => {
      if (Object.hasOwn(key[1], 'type') && key[1].type === 'description') {
        htmlTemplate += `<h2>${key[1]?.emailLabel}</h2><p>`;
        textTemplate += `\n\n ${key[1]?.emailLabel} \n\n`;
      } else {
        const flattenedValue = typeof key[1]?.name === 'object' ? Object.values(key[1]?.name).join(',') : key[1]?.name;
        if (flattenedValue) {
          htmlTemplate += `{{#if ${flattenedValue} }}${key[1]?.emailLabel}: {{ ${flattenedValue} }} <br />{{/if}}`;
          textTemplate += `{{#if ${flattenedValue} }}${key[1]?.emailLabel}: {{ ${flattenedValue} }} \n{{/if}}`;
        }
      }
    });

    htmlTemplate += '</p>';
  });
  return {
    html: htmlTemplate,
    text: textTemplate,
  };
}
