import * as Yup from 'yup';
import { getInitialValues, getYupValidationSchema } from 'utilities/miscHelpers';
import { dateValidation, optionalZipValidation, phoneValidation, zipValidation } from 'utilities/customValidators';
import { HoneypotFieldSchema } from 'utilities/formHelpers';

export const formSchema = {
  HoneypotFieldSchema,
  step1Description: {
    label: 'Certificate of Creditable Coverage',
    emailLabel: 'Certificate of Creditable Coverage',
    initialValue: 'Certificate of Creditable Coverage',
    name: '',
    type: 'description',
  },
  firstName: {
    emailLabel: 'First Name',
    name: 'firstName',
    label: 'First Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  lastName: {
    emailLabel: 'Last Name',
    name: 'lastName',
    label: 'Last Name',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  dateOfBirth: {
    emailLabel: 'Date of Birth',
    name: 'dateOfBirth',
    label: 'Date of Birth',
    initialValue: '',
    validation: dateValidation,
  },
  subscriberID: {
    emailLabel: 'Subscriber ID',
    name: 'subscriberID',
    label: 'Subscriber ID',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  streetAddress: {
    emailLabel: 'Current Street Address',
    name: 'streetAddress',
    label: 'Current Street Address',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  city: {
    emailLabel: 'Current City',
    name: 'city',
    label: 'City',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  state: {
    emailLabel: 'Current State',
    name: 'state',
    label: 'State',
    initialValue: '',
    validation: Yup.string().required('This field is required'),
  },
  zip: {
    emailLabel: 'Current Zip',
    name: 'zip',
    label: 'Zip',
    initialValue: '',
    validation: zipValidation,
  },
  daytimePhone: {
    emailLabel: 'Daytime Phone',
    name: 'daytimePhone',
    label: 'Daytime Phone',
    initialValue: '',
    validation: phoneValidation,
  },
  oldStreetAddress: {
    emailLabel: 'Old Street Address',
    name: 'oldStreetAddress',
    label: 'Old Street Address',
    initialValue: '',
    validation: null,
  },
  oldCity: {
    emailLabel: 'Old City',
    name: 'oldCity',
    label: 'City',
    initialValue: '',
    validation: null,
  },
  oldState: {
    emailLabel: 'Old State',
    name: 'oldState',
    label: 'State',
    initialValue: '',
    validation: null,
  },
  oldZip: {
    emailLabel: 'Old Zip',
    name: 'oldZip',
    label: 'Zip',
    initialValue: '',
    validation: optionalZipValidation,
  },
  external: {
    emailLabel: 'External',
    name: 'external',
    label: 'External',
    initialValue: '15',
    validation: null,
  },
};

export const initialValues = getInitialValues(formSchema);

export const validationSchema = getYupValidationSchema(formSchema);
